<template>
    <div>
        <section class="hero">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <div class="form-container">
                        <h3 class="title">Account Activation</h3>

                        <div v-if="!gotResponse" style="margin-top: 50px;">
                            <div>
                                <i class="fa fa-spinner fa-spin fa-5x fa-fw"></i>
                            </div>

                            <div style="margin-top: 50px;">
                                Activating your account...
                            </div>
                        </div>
                        <div v-else>
                            <p>
                                Thank you for activating your account. Why don't you <a @click="goLogin">log in</a>
                                and start organizing your online life? ;-)
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';
import PageTitle from '@/components/PageTitle';
import notifier from '@/assets/js/notifier';
import constants from '@/assets/js/constants';

export default {
    name: 'SignUpActivationPage',

    components: {
        PageTitle
    },

    data() {
        return {
            gotResponse: false,
            token: ''
        };
    },

    mounted() {
        console.log('Token: ' + this.$route.params.token);
        this.token = this.$route.params.token;
        this.activateToken();
    },

    methods: {
        activateToken() {
            const that = this;

            const payload = {
                token: that.token
            };

            axios.post(window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.SIGN_UP_ACTIVATION, payload)
                .then(() => {
                    that.gotResponse = true;
                })
                .catch((err) => {
                    that.gotResponse = true;
                    console.log('err.response.data: %j', err.response.data);
                    notifier.showErrorMessage(null, err);
                });
        },

        goLogin() {
            this.$router.push(constants.ROUTES.LOGIN);
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .form-container {
        max-width: 600px;
        margin: auto;
    }

    p {
        margin-bottom: 15px;
        /*text-align: justify;*/
    }

    @media (max-width: 700px) {
        .form-container {
            min-width: 250px;
            max-width: 375px;
            margin: auto;
        }

        p {
            text-align: left;
        }
    }

</style>

<template>
    <section class="hero">
        <div class="hero-body">
            <div class="container has-text-centered">
                <div class="form-container">
                    <h3 class="title">Resend Account Activation Link</h3>
                    <p class="subtitle has-text-grey">Enter the email address that you used to sign up and we'll send you another activation link</p>
                    <div class="box">
                        <figure class="avatar">
                            <img :src="logo" alt="">
                        </figure>
                        <form @submit.prevent @keydown="form.errorWrapper.clear($event.target.name)">
                            <div class="field">
                                <label class="label has-text-left">Enter your email address:</label>
                                <div class="control">
                                    <input ref="email" v-model="form.email" name="email" class="input is-large" type="text" placeholder="email">
                                </div>
                                <form-error :field="'email'" :form="form" class="has-text-left"/>
                            </div>

                            <button :class="isLoading ? 'is-loading' : ''" class="button is-block is-info is-large" style="width: 100%;" @click="resendLink">Resend Link</button>
                        </form>
                    </div>

                    <p>
                        <a href="#/sign-up">Sign Up</a>
                        &nbsp;·&nbsp;
                        <a href="#/login">Log In</a>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import FormError from '@/components/FormError';
import PageTitle from '@/components/PageTitle';
import notifier from '@/assets/js/notifier';
import Form from '@/assets/js/form';
import constants from '@/assets/js/constants';

const profileImage = require('@/assets/images/profile-01.png');

export default {

    name: 'ForgotPasswordPage',

    components: {
        FormError,
        PageTitle
    },

    data: () => ({
        someError: false,
        loading: false,
        form: new Form({
            email: ''
        })
    }),

    computed: {
        logo() {
            return profileImage;
        },

        isLoading() {
            return this.loading;
        }
    },

    mounted() {
        if (this.$route.query.p) {
            this.form.email = this.$route.query.p;
        }

        // Give focus to this element
        this.$refs.email.focus();
    },

    methods: {
        resendLink() {
            const that = this;
            this.loading = true;

            axios({
                method: 'post',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.SIGN_UP_RESEND,
                data: { email: this.form.email }
            })
                .then(() => {
                    that.loading = false;
                    that.someError = false;
                    notifier.showSuccessMessage('Email Sent', `We've sent another activation email to "${this.form.email}"`);
                })
                .catch((exc) => {
                    that.loading = false;
                    that.someError = true;
                    that.form.recordErrors(exc.response.data);
                    notifier.showSimpleErrorMessage(exc);
                });
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    html, body {
        font-family: 'Open Sans', serif;
        font-size: 14px;
        font-weight: 300;
    }

    .hero .nav, .hero.is-success .nav {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .box {
        margin-top: 5rem;
    }

    .avatar {
        margin-top: -70px;
        padding-bottom: 20px;
    }

    .avatar img {
        padding: 5px;
        background: #fff;
        border-radius: 50%;
        -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
        box-shadow: 0 2px 3px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
        width: 128px;
    }

    .form-container {
        min-width: 250px;
        max-width: 375px;
        margin: auto;
    }

    input {
        font-weight: 300;
    }

    p {
        font-weight: 700;
    }

    p.subtitle {
        padding-top: 1rem;
    }

    a {
        color: #7d7d7d;
    }

    a:hover {
        color: #7d7d7d;
    }

</style>

<template>
    <div>
        <page-title>Save to Bookmark Pivot</page-title>

        <form @submit.prevent="saveLink" @keydown="form.errorWrapper.clear($event.target.name)">
            <div class="field">
                <label class="label">Address</label>
                <div class="control">
                    <input ref="address" v-model="form.address" name="address" class="input" type="text">
                </div>
                <form-error :field="'address'" :form="form"/>
            </div>
            <div class="field">
                <label class="label">Title</label>
                <div class="control">
                    <input v-model="form.title" class="input" type="text">
                </div>
            </div>
            <div class="field">
                <label class="label">Description</label>
                <div class="control">
                    <textarea v-model="form.notes" class="textarea"></textarea>
                </div>
            </div>

            <div class="field is-grouped">
                <div class="control">
                    <button id="save-button" class="button is-primary" type="submit">Save</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import FormError from '@/components/FormError';
import PageTitle from '@/components/PageTitle';
import notifier from '@/assets/js/notifier';
import Form from '@/assets/js/form';
import constants from '@/assets/js/constants';

export default {
    name: 'NewBasicPage',

    components: {
        FormError,
        PageTitle
    },

    data() {
        return {
            form: new Form({})
        };
    },

    created() {
        this.form.userId = this.$store.getters.user.id;
        this.form.title = this.$route.query.title || '';
        this.form.address = this.$route.query.url || '';
        this.form.notes = this.$route.query.description || '';
    },

    mounted() {
        this.$refs.address.focus();
    },

    methods: {
        saveLink() {
            const that = this;
            const payload = this.form.getData();

            axios({
                method: 'post',
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.BOOKMARKS,
                headers: { token: this.$store.getters.token },
                data: payload
            })
                .then(() => {
                    notifier.showSuccessToaster('Saved', 'Link saved to Bookmark Pivot');

                    setTimeout(() => {
                        window.close();
                    }, 1500);
                })
                .catch((err) => {
                    that.form.recordErrors(err.response.data);
                    notifier.showErrorMessage(null, err);
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
    <div>
        <div class="title-container">
            <div>
                <page-title>Recently Visited Bookmarks</page-title>
            </div>
            <div class="button-bar">
                <a class="button is-default" @click="refresh">
                    <span class="icon is-right">
                        <i class="fas fa-sync-alt"></i>
                    </span>
                    &nbsp; Refresh
                </a>

                <a class="button is-default" @click="showSettings">
                    <span class="icon is-right">
                        <i class="fas fa-cog"></i>
                    </span>
                    &nbsp; Settings
                </a>

                <span class="field has-text-left">
                    <span class="select">
                        <select v-model="sortOrder" @change="sortChanged">
                            <option :value="sortConstants.ALPHABETIC.code">{{ sortConstants.ALPHABETIC.title }}</option>
                            <option :value="sortConstants.POPULARITY.code">{{ sortConstants.POPULARITY.title }}</option>
                            <option :value="sortConstants.DATE_CREATED.code">{{ sortConstants.DATE_CREATED.title }}</option>
                        </select>
                    </span>
                </span>
            </div>
        </div>

        <bookmark-bag :links="sortedLinks" :pref-name="previewWidthSettingName" @refresh="refresh"></bookmark-bag>

        <div id="settings-modal" class="modal">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Settings</p>
                    <button class="delete" aria-label="close" @click="hideSettings"></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label" for="setting-days">Analysis period (in days)</label>
                        <div class="control has-icons-right">
                            <input id="setting-days" ref="numDays" v-model="settingDays" type="number" min="1" max="60" class="input" @keyup.enter="saveSettings" @keyup.esc="hideSettings"/>
                        </div>
                        <p class="help">Show only bookmarks clicked in the last {{ settingDays }} days</p>
                    </div>

                    <div class="field">
                        <label class="label" for="setting-limits">Number of bookmarks to show</label>
                        <div class="control has-icons-right">
                            <input id="setting-limits" v-model="settingLimit" type="number" min="1" max="60" class="input" @keyup.enter="saveSettings" @keyup.esc="hideSettings"/>
                        </div>
                        <p class="help">Show up to {{ settingLimit }} bookmark cards</p>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-info" @click="saveSettings">Save changes</button>
                    <button class="button" @click="hideSettings">Cancel</button>
                </footer>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import BookmarkBag from '@/components/BookmarkBag';
import PageTitle from '@/components/PageTitle';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';

export default {
    name: 'RecentlyVisitedPage',

    components: {
        BookmarkBag,
        PageTitle
    },

    data() {
        return {
            previewWidthSettingName: constants.USER_SETTINGS.DEFAULT_PREVIEW_WIDTH,
            links: [],
            sortedLinks: [],
            sortConstants: constants.SORT_ORDER,
            sortOrder: this.$store.getters.userSettings[constants.USER_SETTINGS.RECENTLY_VISITED_PAGE_SORT_ORDER] || constants.SORT_ORDER.POPULARITY.code,
            settingDays: 0,
            settingLimit: 0
        };
    },

    created: function () {
        this.loadLinks();
        this.loadSettings();
    },

    mounted() {
        window.EventBus.$emit('pathChanged', constants.ROUTES.RECENTLY_VISITED);
    },

    methods: {
        loadLinks() {
            const that = this;
            const url = process.env.VUE_APP_API_BASE + constants.ENDPOINTS.RECENTLY_VISITED_BOOKMARKS_FOR_USER.replace('{userId}', this.$store.getters.user.id);

            axios({
                method: 'get',
                url: url,
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    that.links = response.data;
                    that.sortChanged();
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        loadSettings() {
            const that = this;

            axios({
                method: 'get',
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.SETTING_FOR_USER.replace('{userId}', this.$store.getters.user.id).replace('{settingName}', constants.USER_SERVER_SETTINGS.RECENTLY_VISITED_DAYS),
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    that.settingDays = parseInt(response.data.value, 10);
                })
                .catch((e) => {
                    console.log(e);
                });

            axios({
                method: 'get',
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.SETTING_FOR_USER.replace('{userId}', this.$store.getters.user.id).replace('{settingName}', constants.USER_SERVER_SETTINGS.RECENTLY_VISITED_LIMIT),
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    that.settingLimit = parseInt(response.data.value, 10);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        refresh() {
            this.loadLinks();
        },

        showSettings() {
            const element = document.getElementById('settings-modal');
            element.classList.add('is-active');
            this.$refs.numDays.focus();
        },

        hideSettings() {
            const element = document.getElementById('settings-modal');
            element.classList.remove('is-active');
        },

        saveSettings() {
            const self = this;
            let successCount = 0;

            axios({
                method: constants.HTTP_ACTION.PUT,
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.SETTING_FOR_USER.replace('{userId}', this.$store.getters.user.id).replace('{settingName}', constants.USER_SERVER_SETTINGS.RECENTLY_VISITED_DAYS),
                headers: { token: this.$store.getters.token },
                data: { value: self.settingDays }
            })
                .then(() => {
                    if (++successCount === 2) {
                        self.settingsUpdateComplete();
                    }
                })
                .catch((e) => {
                    console.log(e);
                    notifier.showErrorMessage(null, e);
                });

            axios({
                method: constants.HTTP_ACTION.PUT,
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.SETTING_FOR_USER.replace('{userId}', this.$store.getters.user.id).replace('{settingName}', constants.USER_SERVER_SETTINGS.RECENTLY_VISITED_LIMIT),
                headers: { token: this.$store.getters.token },
                data: { value: self.settingLimit }
            })
                .then(() => {
                    if (++successCount === 2) {
                        self.settingsUpdateComplete();
                    }
                })
                .catch((e) => {
                    console.log(e);
                    notifier.showErrorMessage(null, e);
                });
        },

        settingsUpdateComplete() {
            notifier.showSuccessToaster('Saved', 'Settings updated');
            this.hideSettings();
            this.refresh();
        },

        sortChanged() {
            if (this.links && this.links.length > 0) {
                this.sortedLinks = this.links.sort((a, b) => {
                    if (this.sortOrder === constants.SORT_ORDER.ALPHABETIC.code) {
                        return (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1);
                    } else if (this.sortOrder === constants.SORT_ORDER.POPULARITY.code) {
                        if (a.numClicks > b.numClicks) {
                            return -1;
                        } else if (a.numClicks === b.numClicks) {
                            return 0;
                        }
                        return 1;
                    } else if (this.sortOrder === constants.SORT_ORDER.DATE_CREATED.code) {
                        return (a.dateCreated < b.dateCreated ? -1 : 1);
                    }

                    return 0;
                });

                if (this.$store.getters.userSettings[constants.USER_SETTINGS.RECENTLY_VISITED_PAGE_SORT_ORDER] !== this.sortOrder) {
                    const userSettings = this.$store.getters.userSettings;
                    userSettings[constants.USER_SETTINGS.RECENTLY_VISITED_PAGE_SORT_ORDER] = this.sortOrder;
                    this.$store.commit('userSettings', userSettings);
                }
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .title-container {
        display: flex;
        justify-content: space-between;
    }

    .button-bar {
        padding-top: 22px;
    }

</style>

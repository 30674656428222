const ENDPOINTS = {

    // -------------------------------------------------------------------------
    // Bookmark endpoints
    // -------------------------------------------------------------------------

    BOOKMARKS: '/bookmark-service/bookmarks/bookmarks',
    BOOKMARKS_FOR_TAG: '/bookmark-service/bookmarks/tag/{tagId}',
    BOOKMARKS_FOR_COLLECTION: '/bookmark-service/bookmarks/collection/{collectionId}',
    BOOKMARK_TEMPLATES: '/bookmark-service/templates/templates',
    REFRESH_PREVIEW: '/bookmark-service/bookmarks/refresh-preview',
    SEARCH: '/bookmark-service/bookmarks/search',
    SHARE_FOR_USER: '/bookmark-service/share/bookmark',
    RECENT: '/bookmark-service/bookmarks/recent',

    // Friend
    FRIENDS_FOR_USER: '/bookmark-service/friends/friends',
    FRIENDS: '/bookmark-service/friends/friends',

    BULK_BOOKMARKS: '/bookmark-service/bookmarks/bulk',
    BULK_BOOKMARKS_REFRESH_PREVIEWS: '/bookmark-service/bookmarks/bulk/refresh-preview',
    BULK_BOOKMARKS_UPDATE: '/bookmark-service/bookmarks/bulk',

    // -------------------------------------------------------------------------
    // Tag endpoints
    // -------------------------------------------------------------------------

    TAGS: '/bookmark-service/tags/tags',
    BULK_TAGS: '/bookmark-service/tags/bulk',
    BULK_TAGS_MERGE: '/bookmark-service/tags/bulk/merge',

    // -------------------------------------------------------------------------
    // Collection endpoints
    // -------------------------------------------------------------------------

    COLLECTIONS: '/bookmark-service/collections/collections',
    BULK_COLLECTIONS: '/bookmark-service/collections/bulk',
    BULK_COLLECTIONS_MERGE: '/bookmark-service/collections/bulk/merge',

    // -------------------------------------------------------------------------
    // Analytics endpoints
    // -------------------------------------------------------------------------
    BOOKMARK_CLICKED: '/analytics-service/click/click',

    // -------------------------------------------------------------------------
    // Others
    // -------------------------------------------------------------------------

    AUTHENTICATE: '/security-service/authentication/authenticate',
    FAVOURITE_COLLECTIONS_FOR_USER: '/bookmark-service/collections/favourites',

    CURRENT_USER_SUBSCRIPTION: '/v1/current-user-subscription/{userId}',
    FEEDBACK: '/notification-service/feedback/feedback',
    GROUPS_FOR_USER: '/v1/groups-for-user/{userId}',
    GROUPS_TREE_FOR_USER: '/v1/groups-tree-for-user/{userId}',
    HAS_INVITES: '/v1/has-invites/{userId}',
    IMPORT_BOOKMARKS: '/v1/import/bookmarks',
    IMPORT_FIREFOX: '/v1/import/firefox',
    PERMISSIONS_FOR_USER: '/v1/permissions/{userId}',
    RECENTLY_ADDED_BOOKMARKS_FOR_USER: '/v1/recently-added-bookmarks/{userId}',
    RECENTLY_VISITED_BOOKMARKS_FOR_USER: '/v1/recently-visited-bookmarks/{userId}',

    FORGOT_PASSWORD: '/security-service/accounts/forgot-password',
    VALIDATE_PASSWORD_TOKEN: '/security-service/accounts/validate-password-token',
    RECOVER_PASSWORD: '/security-service/accounts/recover',

    RESET_PASSWORD: '/v1/reset-password',
    SEND_INVITATIONS_FOR_USER: '/v1/send-invitations/{userId}',
    SETTING_FOR_USER: '/v1/setting-for-user/{userId}/{settingName}',
    SIGN_UP: '/security-service/signup/signup',
    SIGN_UP_RESEND: '/security-service/signup/resend',
    SIGN_UP_ACTIVATION: '/security-service/signup/activate',
    SUBSCRIBE: '/v1/subscribe',
    SUBSCRIPTION_PLAN_OPTIONS: '/v1/subscription-plan-options?subPlanId={subPlanId}',
    // TAGS_FOR_USER: '/v1/tags-for-user/{userId}',
    VERSION: '/version',
    VOUCHERS_FOR_TYPE_AND_CODE: '/v1/voucher-by-type-code/{type}/{code}',
    VOUCHERS_FOR_USER: '/v1/vouchers-for-user/{userId}'
};

const ENV = {
    DEV: 'DEV',
    TEST: 'TEST',
    PROD: 'PROD'
};

const EVENTS = {
    BOOKMARK_SELECTED: 'bookmarkSelected',
    BOOKMARK_UNSELECTED: 'bookmarkUnselected',
    CLEAR_BOOKMARK_SELECTION: 'clearBookmarkSelection',
    DONE_MANAGING_BOOKMARKS: 'doneManagingBookmarks',
    SELECT_ALL_BOOKMARKS: 'selectAllBookmarks',
    UNSELECT_ALL_BOOKMARKS: 'unselectAllBookmarks',

    TAG_SELECTED: 'tagSelected',
    TAG_UNSELECTED: 'tagUnselected',
    CLEAR_TAG_SELECTION: 'clearTagSelection',
    DONE_MANAGING_TAGS: 'doneManagingTags',
    SELECT_ALL_TAGS: 'selectAllTags',
    UNSELECT_ALL_TAGS: 'unselectAllTags',

    COLLECTION_SELECTED: 'collectionSelected',
    COLLECTION_UNSELECTED: 'collectionUnselected',
    CLEAR_COLLECTION_SELECTION: 'clearCollectionSelection',
    DONE_MANAGING_COLLECTIONS: 'doneManagingCollections',
    SELECT_ALL_COLLECTIONS: 'selectAllCollections',
    UNSELECT_ALL_COLLECTIONS: 'unselectAllCollections',

    REFRESH: 'refresh',
    REFRESH_FAVOURITE_COLLECTIONS: 'refreshFavCollections'
};

const FEATURE_CODES = {
    CREATE_LINK: 'create-link',
    CREATE_PREVIEW: 'create-preview',
    FETCH_TITLE: 'fetch-title',
    REFRESH_PREVIEW: 'refresh-preview'
};

const HTTP_ACTION = {
    DELETE: 'DELETE',
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT'
};

const TRANS_SOURCES = {
    PAYPAL: 'palpal',
    VOUCHER: 'voucher'
};

const ROUTES = {
    ABOUT: '/about',
    BOOKMARK_TEMPLATES: '/bookmark-templates',
    COLLECTIONS: '/collections',
    DEFAULT: '/',
    HOME: '/',
    // GROUPS: '/groups',
    EXPRESS_BOOKMARK: '/express-bookmark',
    IMPORT: '/import',
    INVITE_FRIEND: '/invite-friend',
    BOOKMARKS: '/bookmarks',
    RECENTLY_ADDED: '/bookmarks?recent=1',
    SHARE: '/share',
    // BOOKMARKS_FOR_GROUP: '/bookmarks-for-group',
    LOGIN: '/login',
    LOGOUT: '/logout',
    FORGOT_PASSWORD: '/forgot-password',
    RECOVER_PASSWORD: '/recover-password',
    NONE: '/xxx',
    // RECENTLY_VISITED: '/recently-visited',
    // RECENTLY_VISITED_TODAY: '/visited-today',
    SEARCH: '/bookmarks?search=1',
    SIGN_UP: '/sign-up',
    SIGN_UP_CONFIRMATION: '/sign-up-confirmation',
    SIGN_UP_SUCCESS: '/sign-up-success',
    SIGN_UP_RESEND: '/sign-up-resend',
    SIGN_UP_ACTIVATION: '/sign-up-activation',
    TAGS: '/tags',
    UPGRADE: '/upgrade',

    // Friends
    FRIENDS: '/friends'
};

const SORT_ORDER = {
    ALPHABETIC: { code: 'alphabetic', title: 'Sort by title' },
    DATE_CREATED: { code: 'date-created', title: 'Sort by creation date' },
    POPULARITY: { code: 'popularity', title: 'Sort by popularity' }
};

const USER_SETTINGS = {
    DEFAULT_PREVIEW_WIDTH: 'defaultPreviewWidth',
    GROUPS_PAGE_SORT_ORDER: 'groupsPageSortOrder',
    LINKS_PAGE_SORT_ORDER: 'linksPageSortOrder',
    RECENTLY_ADDED_PAGE_SORT_ORDER: 'recentlyAddedPageSortOrder',
    RECENTLY_VISITED_PAGE_SORT_ORDER: 'recentlyVisitedPageSortOrder',
    TAGS_PAGE_SORT_ORDER: 'tagsPageSortOrder',
    COLLECTIONS_PAGE_SORT_ORDER: 'collectionsPageSortOrder'
};

const USER_SERVER_SETTINGS = {
    RECENTLY_ADDED_LIMIT: 'activity.recently.added.limit',
    RECENTLY_VISITED_DAYS: 'activity.recently.visited.days',
    RECENTLY_VISITED_LIMIT: 'activity.recently.visited.limit'
};

const VIEW_MODE = {
    CARD: { code: 'card', title: 'Card View' },
    LIST: { code: 'list', title: 'List (with image)' },
    LIST_NO_PREVIEW: { code: 'list-no-preview', title: 'List (no image)' }
}

const VOUCHER_TYPES = {
    REFERRAL: 'referral',
    VOUCHER: 'voucher'
};

export default {
    COPYRIGHT_NOTICE: 'Copyright ©️ 2017 - 2021 HJU Knox. All rights reserved.',
    DATE_TIME_MILLIS_FORMAT: 'YYYY-MM-DD HH:mm:ss.sss',
    DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    DATE_FORMAT: 'YYYY-MM-DD',
    ENDPOINTS,
    ENV,
    EVENTS,
    FEATURE_CODES,
    HTTP_ACTION,
    PAID_SUBSCRIPTION_PLAN_ID: 2,
    ROUTES,
    SORT_ORDER,
    TRANS_SOURCES,
    USER_SETTINGS,
    USER_SERVER_SETTINGS,
    VIEW_MODE,
    VOUCHER_TYPES,

    // Release details
    // Remember to update Death Star to the same
    VERSION: '2021.12.16.1'
};

<template>
    <div>
        <!--
        <page-title>Home</page-title>
        <page-sub-title>Your details at a glance</page-sub-title>
        -->

        <page-title>Stuff at a Glance</page-title>

        <div class="tile is-ancestor">
            <!--
            <div class="tile is-parent">

                <div class="tile is-child content box card">
                    <div class="title is-5"><i class="fas fa-credit-card"></i> Subscription</div>

                    <p v-if="planCode === 'paid'">
                        You are currently on the <b>{{ planName }}</b> plan and your subscription ends on {{ endDate }}.
                    </p>

                    <p v-if="planCode === 'free'">
                        You are on the <b>{{ planName }}</b> plan and have {{ remainingLinks }} links and {{ remainingPreviews }} previews remaining.
                    </p>

                    <p>
                        <button type="button" class="button" style="color: white; background-color: #129803;" @click="upgrade">
                            {{ planCode === 'free' ? 'Upgrade Now' : 'Extend Your Subscription' }}
                        </button>
                    </p>
                </div>
            </div>
            -->

            <!--
            <div class="tile is-parent">
                <div class="tile is-child content box card">
                    <div class="title is-5"><i class="fa fa-area-chart"></i> Recently Added Bookmarks</div>

                    <p><router-link :to="recentlyAddedLink">Check out the bookmarks you recently added</router-link></p>
                </div>
            </div>
            -->

            <!--
            <div class="tile is-parent">
                <div class="tile is-child content box thing">
                    <div class="title is-5"><i class="fa fa-area-chart"></i> Interesting Facts</div>

                    <p>During the last week you</p>

                    <ul>
                        <li>Added x links</li>
                        <li>Click x links</li>
                    </ul>
                </div>
            </div>
            -->
        </div>

        <!--
        <div style="margin-bottom: 20px;">
            <h1>Invitations</h1>

            <ul>
                <li>You have 2 of 10 pending invitations left</li>
                <li>You have 8 invitations left</li>
            </ul>
        </div>

        <div style="margin-bottom: 20px;">
            <h1>Limits</h1>

            <ul>
                <li>You used 456 of your 1000 available links</li>
                <li>You used 99 of your available 100 link previews</li>
            </ul>
        </div>
-->
    </div>
</template>

<script>

import moment from 'moment';
import PageTitle from '@/components/PageTitle';
import PageSubTitle from '@/components/PageSubTitle';
import constants from '@/assets/js/constants';

export default {
    name: 'HomePage',

    components: {
        PageTitle,
        PageSubTitle
    },

    data() {
        return {
            data: {}
        };
    },

    computed: {
        planCode() {
            return (this.$store.getters.subscription && this.$store.getters.subscription.code ? this.$store.getters.subscription.code : null);
        },
        planName() {
            return (this.$store.getters.subscription && this.$store.getters.subscription.name ? this.$store.getters.subscription.name : null);
        },
        endDate() {
            return (this.$store.getters.subscription && this.$store.getters.subscription.endDate
                ? moment(this.$store.getters.subscription.endDate).format(constants.DATE_FORMAT)
                : null);
        },
        remainingLinks() {
            const perm = this.$store.getters.permissions.find((item) => {
                return item.code === constants.FEATURE_CODES.CREATE_LINK;
            });

            let remaining = '?';

            if (perm && perm.limitation) {
                remaining = perm.limitation - perm.used;

                if (remaining < 0) {
                    remaining = 0;
                }
            }

            return remaining;
        },
        remainingPreviews() {
            const perm = this.$store.getters.permissions.find((item) => {
                return item.code === constants.FEATURE_CODES.CREATE_PREVIEW;
            });

            let remaining = '?';

            if (perm && perm.limitation) {
                remaining = perm.limitation - perm.used;

                if (remaining < 0) {
                    remaining = 0;
                }
            }

            return remaining;
        },
        recentlyAddedLink() {
            return `${constants.ROUTES.BOOKMARKS}?recent=1`;
        }
    },

    mounted() {
        // window.EventBus.$emit('pathChanged', constants.ROUTES.HOME);

        this.$router.push(constants.ROUTES.RECENTLY_ADDED);
    },

    methods: {
        upgrade() {
            this.$router.push(constants.ROUTES.UPGRADE);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .card {
        box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
        border-radius: 5px 5px 5px 5px;
        margin: 20px 10px 0 10px;
        background-color: whitesmoke;
    }

</style>

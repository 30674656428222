<template>
    <div>
        <page-title>{{ form.name }}</page-title>

        <form @submit.prevent @keydown="form.errorWrapper.clear($event.target.name)">

            <div class="field">
                <label for="collectionName" class="label">Name</label>
                <div class="control">
                    <input id="collectionName" ref="name" v-model="form.name" name="name" class="input" type="text">
                </div>
                <form-error :field="'name'" :form="form"/>
            </div>

            <div class="field">
                <label class="label">Parent Collection</label>
                <div class="control" @keydown.enter.prevent.self="">
                    <v-select
                        v-model="selectedCollection"
                        :options="allCollections"
                        class="thing-selector"
                        label="name"
                        @option:selected="parentChanged"
                        @search="asyncFindCollections">
                    </v-select>
                </div>
            </div>

            <div class="field">
                <input id="favourite" ref="favourite" v-model="form.favourite" name="favourite" type="checkbox"/>
                <label for="favourite" class="label">Favourite?</label>
            </div>

            <div class="field">
                <label for="favOrder" class="label">Appearance order</label>
                <div class="control">
                    <input id="favOrder" ref="favOrder" v-model="form.favOrder" name="favOrder" class="input" type="text">
                </div>
                <form-error :field="'favOrder'" :form="form"/>
            </div>

            <!-- TODO: Maybe create a component for this -->
            <div class="control-buttons">
                <div class="field is-grouped">
                    <div class="control">
                        <button id="save-button" class="button is-info" type="submit" @click="saveCollection">Save</button>
                    </div>

                    <div class="control back-link">
                        <a @click="cancel">Go back</a>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button id="delete-button" class="button is-danger" @click="deleteCollection">Delete</button>
                    </div>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import PageTitle from '@/components/PageTitle';
import FormError from '@/components/FormError';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';
import logger from '@/assets/js/logger';
import Form from '@/assets/js/form';

export default {
    name: 'CollectionPage',

    components: {
        FormError,
        PageTitle,
        'v-select': vSelect
    },

    data() {
        return {
            form: new Form({
                id: null,
                name: null,
                userId: null,
                parentId: null
            }),
            allCollections: [],
            selectedCollection: null
        };
    },

    watch: {
        $route: 'loadData'
    },

    created: function () {
        this.loadData();
    },

    mounted() {
        window.EventBus.$emit('pathChanged', constants.ROUTES.COLLECTIONS);
        this.$refs.name.focus();
    },

    methods: {
        loadData() {
            const that = this;

            if (this.$route.params.id && this.$route.params.id !== 'new') {
                axios({
                    method: constants.HTTP_ACTION.GET,
                    url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.COLLECTIONS + `/${this.$route.params.id}`,
                    headers: { token: this.$store.getters.token }
                })
                    .then((response) => {
                        that.form = new Form(response.data.payload);

                        if (response.data.payload.parentId) {
                            this.showParent(response.data.payload.parentId);
                        }
                    })
                    .catch((err) => {
                        notifier.showErrorMessage(null, err);
                    });
            } else {
                this.form.id = null;
                this.form.userId = this.$store.getters.user.id;
            }

            // Get collections for user
            // -------------------
            this.asyncFindCollections('', () => {});
        },

        async saveCollection() {
            const that = this;
            const collectionToSave = this.form.getData();
            collectionToSave.userId = this.$store.getters.user.id;
            collectionToSave.parentId = (this.selectedCollection ? this.selectedCollection.id : null);

            // collectionToSave.parentId = null;
            // collectionToSave.favourite = false;
            // collectionToSave.favOrder = 0;
            const url = window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.COLLECTIONS;

            if (!await this.validateForSave(collectionToSave)) {
                return;
            }

            axios({
                method: (this.form.id ? constants.HTTP_ACTION.PUT : constants.HTTP_ACTION.POST),
                url: url,
                data: collectionToSave
            })
                .then(() => {
                    window.EventBus.$emit(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS); // Set the collections in the top part
                    that.$router.push(constants.ROUTES.COLLECTIONS);
                    return false;
                })
                .catch((err) => {
                    // that.form.recordErrors(err.response.data);
                    notifier.showSimpleErrorMessage(err);
                });
        },

        validateForSave(collectionToSave) {
            return new Promise((resolve) => {
                let ok = true;

                if (collectionToSave.id && collectionToSave.parentId && collectionToSave.id === collectionToSave.parentId) {
                    ok = false;
                    notifier.showErrorMessage(null, 'This collection cannot be its own parent');
                }

                resolve(ok);
            });
        },

        cancel() {
            this.$router.go(-1);
            return false;
        },

        deleteCollection() {
            notifier.showDeleteConfirmation(null, `Delete collection <b>${this.form.name}</b>?`)
                .then((confirmed) => {
                    if (confirmed) {
                        logger.debug(this.$options.name, 'Deletion confirmed');
                        this.deleteCollectionForReal();
                    } else {
                        logger.debug(this.$options.name, 'Deletion cancelled');
                    }
                })
                .catch(() => {
                    logger.warn(this.$options.name, `Exception on delete confirmation: ${JSON.stringify(err)}`);
                });
        },

        deleteCollectionForReal() {
            const that = this;

            axios({
                method: constants.HTTP_ACTION.DELETE,
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.COLLECTIONS + '/' + this.form.id
                // data: { id: this.form.id }
                // headers: { token: this.$store.getters.token }
            })
                .then(() => {
                    that.$router.push(constants.ROUTES.COLLECTIONS);
                    window.EventBus.$emit(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS); // Set the collections in the top part
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });

            return false;
        },

        showParent(parentId) {
            const that = this;

            axios({
                method: constants.HTTP_ACTION.GET,
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.COLLECTIONS + `/${parentId}`,
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    that.selectedCollection = response.data.payload;
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });
        },

        parentChanged(selectedCollection) {
            if (this.form.id && this.form.id === selectedCollection.id) {
                notifier.showErrorMessage(null, 'This collection cannot be its own parent');
            }
        },

        asyncFindCollections(query, loading) {
            const that = this;
            loading(true);

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.COLLECTIONS + `?search=${query}`
            })
                .then((response) => {
                    that.allCollections = [];
                    loading(false);

                    if (response.data && response.data.payload.length > 0) {
                        that.allCollections = response.data.payload.sort((a, b) => {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        });
                    }
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                    loading(false);
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .control-buttons {
        display: flex;
        justify-content: space-between;
    }

    .back-link {
        margin-top: 5px;
    }

    .label {
        display: inline-block;
        margin-left: 10px;
    }

    /* The >>> thing helps this work in a scoped environment */
    .thing-selector >>> .vs__selected {
        background: lightskyblue;
    }

</style>

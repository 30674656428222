<template>
    <div>
        <div class="friends-section">
            <page-title>My Friends</page-title>

            <div class="button-bar">
                <router-link :to="addLink" class="button is-info">
                    <span class="icon is-right">
                        <i class="fas fa-plus"></i>
                    </span>
                    &nbsp; Add Friend
                </router-link>

                <a class="button is-default" @click="loadData">
                    <span class="icon is-right">
                        <i class="fas fa-sync-alt"></i>
                    </span>
                    &nbsp; Refresh
                </a>
            </div>
        </div>

        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="friend in friends" :key="friend.id">
                    <td><router-link :to="getEditLink(friend.id)">{{ friend.name }}</router-link></td>
                    <td>{{ friend.email }}</td>
                </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
import axios from 'axios';
import PageTitle from '@/components/PageTitle';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';

export default {
    name: 'FriendsPage',

    components: {
        PageTitle
    },

    data() {
        return {
            friends: []
        };
    },

    computed: {
        addLink() {
            return `${constants.ROUTES.FRIENDS}/new`;
        }
    },

    created: function () {
        this.loadData();
    },

    mounted() {
        window.EventBus.$emit('pathChanged', constants.ROUTES.FRIENDS);
    },

    methods: {
        loadData() {
            const that = this;

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.FRIENDS
            })
                .then((response) => {
                    that.friends = [];

                    if (response && response.data && response.data.payload.length > 0) {
                        that.friends = response.data.payload.sort((a, b) => {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        });
                    }
                })
                .catch((err) => {
                    notifier.showErrorMessage('', err);
                });
        },

        getEditLink(id) {
            return `${constants.ROUTES.FRIENDS}/${id}`;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .friends-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .button-bar {
        display: flex;
        flex-wrap: wrap;
        padding-top: 22px;
    }

    @media (max-width: 700px) {
        .button-bar {
            padding-top: 0;
        }
    }

</style>

<template>
    <div>
        <page-title>{{ form.title || (!form.id ? 'Add New Bookmark' : '') }}</page-title>

        <form @submit.prevent @keydown="form.errorWrapper.clear($event.target.name)">

            <div class="field">
                <label class="label">Address</label>
                <div class="control has-icons-right">
                    <input id="bookmarkAddress" ref="addressRef" v-model="form.address" type="text" name="address" class="input"/>
                    <span class="icon is-right">
                        <i class="fas fa-link"></i>
                    </span>
                </div>
                <form-error :field="'address'" :form="form"></form-error>
            </div>

            <div class="field">
                <label class="label">Title</label>
                <div class="control has-icons-right">
                    <input id="bookmarkTitle" v-model="form.title" class="input" type="text">
                    <span class="icon is-right">
                        <i class="fas fa-bookmark"></i>
                    </span>
                </div>
                <p class="help">We'll retrieve the title if you leave it blank</p>
            </div>

            <div class="field">
                <label class="label">Tags</label>
                <div class="control" @keydown.enter.prevent.self="">
                    <v-select v-model="selectedTags" :options="allTags" class="thing-selector" label="name" multiple taggable @search="asyncFindTags"></v-select>
                </div>
            </div>

            <div class="field">
                <label class="label">Collections</label>
                <div class="control" @keydown.enter.prevent.self="">
                    <v-select v-model="selectedCollections" :options="allCollections" class="thing-selector" label="name" multiple taggable @search="asyncFindCollections"></v-select>
                </div>
            </div>

            <div class="field">
                <label class="label">Notes</label>
                <div class="control">
                    <textarea id="bookmarkNotes" v-model="form.notes" class="textarea"></textarea>
                </div>
            </div>

            <!-- TODO: Maybe create a component for this -->
            <div class="control-buttons">
                <div class="field is-grouped">
                    <div class="control">
                        <button id="save-button" class="button is-info" type="submit" @click="saveBookmark">Save</button>
                    </div>

                    <div class="control back-link">
                        <a @click="cancel">Go back</a>
                    </div>

                    <div class="control">
                        <button id="preview-refresh-button" class="button is-default" type="button" @click.stop.prevent="refreshPreview">Refresh Preview</button>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button id="delete-button" class="button is-danger" type="button" @click="deleteBookmark">Delete</button>
                    </div>
                </div>
            </div>

        </form>

        <img v-if="form.id" :src="previewPath" class="preview-image" alt="">
    </div>
</template>

<script>

import axios from 'axios';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import PageTitle from '@/components/PageTitle';
import FormError from '@/components/FormError';
import notifier from '@/assets/js/notifier';
import Form from '@/assets/js/form';
import constants from '@/assets/js/constants';
import logger from '@/assets/js/logger';

const unavailImg = require('@/assets/images/unavailable.png');

export default {
    name: 'BookmarkPage',

    components: {
        FormError,
        PageTitle,
        'v-select': vSelect
    },

    data() {
        return {
            allTags: [],
            allCollections: [],

            selectedTags: [],
            selectedCollections: [],

            form: new Form({
                id: null,
                address: null,
                title: null,
                notes: null,
                tags: [],
                collections: [],
                userId: null
            })
        };
    },

    computed: {
        previewPath() {
            if (this.form.preview) {
                return window.bookmarkPivotConfig.previewsUrl + '/' + this.form.preview;
            }

            return unavailImg;
        }
    },

    watch: {
        $route: 'loadData'
    },

    created() {
        this.loadData();
    },

    mounted() {
        this.$refs.addressRef.focus();
    },

    methods: {
        loadData() {
            const that = this;

            if (this.$route.params.id && this.$route.params.id !== 'new') {
                axios({
                    method: 'GET',
                    url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARKS + `/${this.$route.params.id}`
                })
                    .then((response) => {
                        if (response && response.data) {
                            that.form = new Form(response.data.payload);
                            that.selectedTags = that.form.tags;
                            that.selectedCollections = that.form.collections;
                        } else {
                            notifier.showErrorMessage(null, 'Unable to find bookmark');
                        }
                    })
                    .catch((err) => {
                        notifier.showErrorMessage(null, err);
                    });
            } else {
                this.form.id = null;
                this.form.userId = this.$store.getters.user.id;

                // TODO: This needs changing. Emit the Vue.js router
                // full path (that.$router.fullPath) once this issue
                // has been resolved: https://gitlab.com/8lnx/meta/issues/102
                window.EventBus.$emit('pathChanged', 'bookmarks/new');
            }

            // Get tags for user
            // -----------------
            this.asyncFindTags('', () => {});

            // Get collections for user
            // -------------------
            this.asyncFindCollections('', () => {});
        },

        saveBookmark() {
            const bookmarkToSave = this.form.getData();

            bookmarkToSave.tags = [];
            bookmarkToSave.collections = [];

            if (this.selectedTags && this.selectedTags.length > 0) {
                this.selectedTags.forEach((tag) => {
                    if (tag.name) {
                        bookmarkToSave.tags.push(tag);
                    } else {
                        bookmarkToSave.tags.push({ id: null, name: tag });
                    }
                });
            }

            if (this.selectedCollections && this.selectedCollections.length > 0) {
                this.selectedCollections.forEach((collection) => {
                    if (collection.name) {
                        bookmarkToSave.collections.push(collection);
                    } else {
                        bookmarkToSave.collections.push({ id: null, name: collection });
                    }
                });
            }

            axios({
                method: (bookmarkToSave.id ? 'PUT' : 'POST'),
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARKS,
                data: bookmarkToSave
            })
                .then(() => {
                    window.EventBus.$emit(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS); // Set the collections in the top part
                    this.$router.go(-1);
                    return false;
                })
                .catch((err) => {
                    // that.form.recordErrors(err.response.data);
                    notifier.showErrorMessage(null, err);
                });
        },

        refreshPreview() {
            axios({
                method: 'post',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.REFRESH_PREVIEW,
                data: { id: this.form.id }
            })
                .then(() => {
                    notifier.showSuccessToaster('Success', 'The preview image is being updated');
                    return false;
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });
        },

        cancel() {
            this.$router.go(-1);
            return false;
        },

        deleteBookmark() {
            notifier.showDeleteConfirmation(null, this.form.title)
                .then((confirmed) => {
                    if (confirmed) {
                        logger.debug(this.$options.name, 'Deletion confirmed');
                        this.deleteBookmarkForReal();
                    } else {
                        logger.debug(this.$options.name, 'Deletion cancelled');
                    }
                })
                .catch(() => {
                    logger.warn(this.$options.name, `Exception on delete confirmation: ${JSON.stringify(err)}`);
                });
        },

        deleteBookmarkForReal() {
            axios({
                method: 'DELETE',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARKS + `/${this.form.id}`
            })
                .then(() => {
                    window.EventBus.$emit(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS); // Set the collections in the top part
                    this.$router.go(-1);
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });
        },

        asyncFindTags(query, loading) {
            const that = this;
            loading(true);

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.TAGS + `?search=${query}`
            })
                .then((response) => {
                    that.allTags = [];
                    loading(false);

                    if (response.data && response.data.payload.length > 0) {
                        that.allTags = response.data.payload.sort((a, b) => {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        });
                    }
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                    loading(false);
                });
        },

        asyncFindCollections(query, loading) {
            const that = this;
            loading(true);

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.COLLECTIONS + `?search=${query}`
            })
                .then((response) => {
                    that.allCollections = [];
                    loading(false);

                    if (response.data && response.data.payload.length > 0) {
                        that.allCollections = response.data.payload.sort((a, b) => {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        });
                    }
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                    loading(false);
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .control-buttons {
        display: flex;
        justify-content: space-between;
    }

    .back-link {
        margin-top: 5px;
    }

    .preview-image {
        box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
        border-radius: 5px 5px 5px 5px;
        margin: 20px 0 10px 0;
        width: 100%;
    }

    /* The >>> thing helps this work in a scoped environment */
    .thing-selector >>> .vs__selected {
        background: lightskyblue;
    }

</style>

<template>
    <div>
        <div class="about-section">
            <page-title>About Bookmark Pivot</page-title>
            <page-sub-title>Reimagining the humble bookmark</page-sub-title>

            <div>
                Version: <b>{{ version }}</b>
            </div>

            <div style="margin-top: 30px;">
                Check out the <a :href="updatesAddress">release details</a>
            </div>
        </div>
    </div>
</template>

<script>

import PageTitle from '@/components/PageTitle';
import PageSubTitle from '@/components/PageSubTitle';
import constants from '@/assets/js/constants';

export default {
    name: 'AboutPage',

    components: {
        PageTitle,
        PageSubTitle
    },

    data() {
        return {
            version: constants.VERSION,
            updatesAddress: window.bookmarkPivotConfig.updatesUrl
        };
    },

    mounted() {
        window.EventBus.$emit('pathChanged', constants.ROUTES.ABOUT);
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
    <section class="hero">
        <div class="hero-body">
            <div class="container has-text-centered">
                <div class="form-container">
                    <h3 class="title">Log In</h3>
                    <p class="subtitle has-text-grey">It's good to see you again</p>
                    <div class="box">
                        <figure class="avatar">
                            <img :src="logo" alt="">
                        </figure>
                        <form @submit.prevent>
                            <div class="field">
                                <div class="control">
                                    <input ref="emailRef" v-model="email" class="input is-large" type="text" placeholder="email address">
                                </div>
                            </div>

                            <div class="field">
                                <div class="control">
                                    <input v-model="password" class="input is-large" type="password" placeholder="password">
                                </div>
                            </div>

                            <button v-bind:class="{ 'is-loading': loading }" type="submit" class="button is-block is-info is-large" style="width: 100%;" @click="signIn">Log In</button>
                        </form>
                    </div>
                    <p>
                        <a @click="signUp">Sign Up</a>
                        &nbsp;·&nbsp;
                        <a @click="forgotPassword">Forgot Password</a>
                    </p>
                    <p>
                        <a @click="resend">Resend activation link</a>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import JSSHA from 'jssha';
import PageTitle from '@/components/PageTitle';
import notifier from '@/assets/js/notifier';
import constants from '@/assets/js/constants';

const profileImage = require('@/assets/images/profile-01.png');

function hashPassword(pwd) {
    const shaObj = new JSSHA('SHA-512', 'TEXT');
    shaObj.update(pwd);
    return shaObj.getHash('HEX');
}

export default {
    name: 'LoginPage',

    components: {
        PageTitle
    },

    data: () => ({
        email: '',
        password: '',
        loading: false
    }),

    computed: {
        logo() {
            return profileImage;
        }
    },

    created() {
        if (this.$store.getters.authenticated) {
            this.$router.push(constants.ROUTES.DEFAULT);
        }
    },

    mounted() {
        // Give focus to this element
        this.$refs.emailRef.focus();
        window.EventBus.$emit('pathChanged', constants.ROUTES.NONE);
    },

    methods: {
        signIn() {
            const that = this;

            const payload = {
                email: this.email,
                password: hashPassword(this.password)
            };

            this.loading = true;

            axios({
                method: 'post',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.AUTHENTICATE,
                data: payload
            })
                .then((response) => {
                    this.loading = false;

                    const usr = response.data.payload.user;
                    const token = response.data.payload.token;

                    that.$store.commit('user', usr);
                    that.$store.commit('token', token);

                    window.localStorage.setItem('user', JSON.stringify(usr));
                    window.localStorage.setItem('token', token);

                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

                    // Set the groups in the top part
                    window.EventBus.$emit(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS);
                    // window.EventBus.$emit('getGroupGraph');
                    // window.EventBus.$emit('getUserSubscription');
                    // window.EventBus.$emit('getUserPermissions');
                    window.EventBus.$emit('getUserBookmarkTemplates');
                    // window.EventBus.$emit('checkForInvites');

                    // This timeout is necessary in order for the menu to be constructed
                    // and shown. Calling "pathChanged" before that is complete results
                    // in the active path not reflecting on the menu.
                    setTimeout(() => {
                        console.log('Changing path to ' + that.$route.query.redirect || constants.ROUTES.DEFAULT);
                        window.EventBus.$emit('pathChanged', that.$route.query.redirect || constants.ROUTES.DEFAULT);
                    }, 250);

                    if (that.$route.query.redirect) {
                        console.log('redirect: ' + that.$route.query.redirect);
                        that.$router.push(that.$route.query.redirect);
                    } else {
                        that.$router.push(constants.ROUTES.DEFAULT);
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    notifier.showErrorMessage(null, err);
                });
        },

        signUp() {
            this.$router.push(constants.ROUTES.SIGN_UP);
        },

        forgotPassword() {
            this.$router.push(constants.ROUTES.FORGOT_PASSWORD + '?p=' + this.email);
        },

        resend() {
            this.$router.push(constants.ROUTES.SIGN_UP_RESEND + '?p=' + this.email);
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    html, body {
        font-family: 'Open Sans', serif;
        font-size: 14px;
        font-weight: 300;
    }

    .hero .nav, .hero.is-success .nav {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .box {
        margin-top: 5rem;
    }

    .avatar {
        margin-top: -70px;
        padding-bottom: 20px;
    }

    .avatar img {
        padding: 5px;
        background: #fff;
        border-radius: 50%;
        -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
        box-shadow: 0 2px 3px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
        width: 128px;
    }

    .form-container {
        min-width: 250px;
        max-width: 375px;
        margin: auto;
    }

    input {
        font-weight: 300;
    }

    p {
        font-weight: 700;
    }

    p.subtitle {
        padding-top: 1rem;
    }

    a {
        color: #7d7d7d;
    }

    a:hover {
        color: #7d7d7d;
    }

</style>

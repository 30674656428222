<template>
    <div v-if="hasError">
        <div v-for="(message, index) in messages" v-bind:key="index" class="help is-danger" v-text="message"></div>
    </div>
</template>

<script>
export default {
    name: 'FormError',

    props: ['field', 'form'],

    computed: {
        messages() {
            return this.form.errorWrapper.get(this.field);
        },
        hasError() {
            // return this.errors[this.field];
            return this.form.errorWrapper.get(this.field);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

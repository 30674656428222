<template>
    <div>
        <div class="friends-section">
            <page-title>Bookmark Templates</page-title>

            <div class="button-bar">
                <router-link :to="addLink" class="button is-info">
                    <span class="icon is-right">
                        <i class="fas fa-plus"></i>
                    </span>
                    &nbsp; Add Bookmark Template
                </router-link>

                <a class="button is-default" @click="loadData">
                    <span class="icon is-right">
                        <i class="fas fa-sync-alt"></i>
                    </span>
                    &nbsp; Refresh
                </a>
            </div>
        </div>

        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Tags</th>
                    <th>Collections</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="template in bookmarkTemplates" :key="template.id">
                    <td><router-link :to="getEditLink(template.id)">{{ template.name }}</router-link></td>
                    <td>{{ (template.tags && template.tags.length > 0 ? template.tags.map(item => item.name).join(', ') : '') }}</td>
                    <td>{{ (template.collections && template.collections.length > 0 ? template.collections.map(item => item.name).join(', ') : '') }}</td>
                </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
import axios from 'axios';
import PageTitle from '@/components/PageTitle';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';

export default {
    name: 'BookmarkTemplatesPage',

    components: {
        PageTitle
    },

    data() {
        return {
            bookmarkTemplates: []
        };
    },

    computed: {
        addLink() {
            return `${constants.ROUTES.BOOKMARK_TEMPLATES}/new`;
        }
    },

    created: function () {
        this.loadData();
    },

    mounted() {
        window.EventBus.$emit('pathChanged', constants.ROUTES.BOOKMARK_TEMPLATES);
    },

    methods: {
        loadData() {
            const that = this;

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARK_TEMPLATES
            })
                .then((response) => {
                    that.bookmarkTemplates = [];

                    if (response && response.data && response.data.payload.length > 0) {
                        that.bookmarkTemplates = response.data.payload.sort((a, b) => {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        });
                    }

                    that.refreshStore();
                })
                .catch((err) => {
                    // console.log('err: ' + JSON.stringify(err, undefined, 4));
                    // notifier.showSimpleErrorMessage(err);
                    notifier.showErrorMessage('', err);
                });
        },

        refreshStore() {
            this.$store.commit('bookmarkTemplates', this.bookmarkTemplates);
        },

        getEditLink(id) {
            return `${constants.ROUTES.BOOKMARK_TEMPLATES}/${id}`;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .friends-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .button-bar {
        display: flex;
        flex-wrap: wrap;
        padding-top: 22px;
    }

    @media (max-width: 700px) {
        .button-bar {
            padding-top: 0;
        }
    }

</style>

<template>
    <div>
        <div class="tags-section">
            <page-title>Tags</page-title>

            <div class="button-bar">
                <router-link :to="newTagLink" class="button is-info">
                    <span class="icon is-right">
                        <i class="fas fa-plus"></i>
                    </span>
                    &nbsp; Add Tag
                </router-link>

                <a class="button is-default" @click="loadData">
                    <span class="icon is-right">
                        <i class="fas fa-sync-alt"></i>
                    </span>
                    &nbsp; Refresh
                </a>

                <a class="button is-default" :class="managing ? 'manage-mode' : ''" @click="manage">
                    <span class="icon is-right">
                        <i class="fas fa-wrench"></i>
                    </span>
                    &nbsp; Manage
                </a>

                <span class="field has-text-left">
                    <span class="select">
                        <select v-model="sortOrder" @change="sortChanged">
                            <option :value="sortConstants.ALPHABETIC.code">{{ sortConstants.ALPHABETIC.title }}</option>
                            <option :value="sortConstants.DATE_CREATED.code">{{ sortConstants.DATE_CREATED.title }}</option>
                        </select>
                    </span>
                </span>

            </div>
        </div>

        <div v-if="managing === true" class="management-button-group-wrapper">

            <div class="management-description">
                Manage your tags with bulk merge and delete functions
            </div>

            <div class="button-group">
                <a class="button is-primary" @click="doneManagingButtonClick">
                    <span class="icon is-right">
                        <i class="fas fa-external-link-alt"></i>
                    </span>
                    &nbsp; Done
                </a>

                <a class="button is-default" @click="selectAllButtonClick">
                    <span class="icon is-right">
                        <i class="far fa-check-square"></i>
                    </span>
                    &nbsp; Select All
                </a>

                <a class="button is-default" @click="unselectAllButtonClick">
                    <span class="icon is-right">
                        <i class="far fa-square"></i>
                    </span>
                    &nbsp; Unselect All
                </a>

                <a class="button is-default" @click="mergeButtonClick">
                    <span class="icon is-right">
                        <i class="far fa-object-group"></i>
                    </span>
                    &nbsp; Merge
                </a>

                <a class="button is-danger" @click="deleteTagsButtonClick">
                    <span class="icon is-right">
                        <i class="fas fa-sync-alt"></i>
                    </span>
                    &nbsp; Delete
                </a>
            </div>

            <div class="management-status">
                {{ selectedTagIds.length }} of {{ sortedTags.length }} selected
            </div>

        </div>

        <div class="tags-column-view">
            <tag-card v-for="tag in sortedTags"
                      :key="tag.id"
                      :tag="tag"
                      :managing="managing"
                      @tagSelected="tagSelected"
                      @tagUnselected="tagUnselected">
            </tag-card>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import PageTitle from '@/components/PageTitle';
import TagCard from '@/components/TagCard';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';
import logger from '@/assets/js/logger';

export default {
    name: 'TagsPage',

    components: {
        PageTitle,
        TagCard
    },

    data() {
        return {
            selectedTagIds: [],
            tagSource: [],
            sortedTags: [],
            sortConstants: constants.SORT_ORDER,
            sortOrder: this.$store.getters.userSettings[constants.USER_SETTINGS.TAGS_PAGE_SORT_ORDER] || constants.SORT_ORDER.ALPHABETIC.code,
            managing: false
        };
    },

    computed: {
        newTagLink() {
            return `${constants.ROUTES.TAGS}/new`;
        }
    },

    created: function () {
        this.loadData();

        window.EventBus.$on(constants.EVENTS.DONE_MANAGING_TAGS, () => {
            logger.debug(this.$options.name, 'Caught event ' + constants.EVENTS.DONE_MANAGING_TAGS);
            this.managing = false;
            this.selectedTagIds = [];
            window.EventBus.$emit(constants.EVENTS.CLEAR_TAG_SELECTION);
        });
    },

    beforeDestroy() {
        logger.debug(this.$options.name, 'Unsubscribing from event ' + constants.EVENTS.DONE_MANAGING_TAGS);
        window.EventBus.$off(constants.EVENTS.DONE_MANAGING_TAGS);
    },

    mounted() {
        window.EventBus.$emit('pathChanged', constants.ROUTES.TAGS);
    },

    methods: {
        loadData() {
            const that = this;

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.TAGS
            })
                .then((response) => {
                    that.tagSource = response.data.payload;
                    that.sortChanged();
                })
                .catch((e) => {
                    console.log(e);
                    notifier.showErrorMessage(null, e);
                });
        },

        sortChanged() {
            if (this.tagSource && this.tagSource.length > 0) {
                this.sortedTags = this.tagSource.sort((a, b) => {
                    if (this.sortOrder === constants.SORT_ORDER.ALPHABETIC.code) {
                        return (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
                    } else if (this.sortOrder === constants.SORT_ORDER.DATE_CREATED.code) {
                        return (a.dateCreated < b.dateCreated ? -1 : 1);
                    }

                    return 0;
                });

                if (this.$store.getters.userSettings[constants.USER_SETTINGS.TAGS_PAGE_SORT_ORDER] !== this.sortOrder) {
                    const userSettings = this.$store.getters.userSettings;
                    userSettings[constants.USER_SETTINGS.TAGS_PAGE_SORT_ORDER] = this.sortOrder;
                    this.$store.commit('userSettings', userSettings);
                }
            } else {
                this.sortedTags = [];
            }
        },

        manage() {
            this.managing = !this.managing;

            if (!this.managing) {
                this.doneManaging();
            }
        },

        doneManaging() {
            logger.debug(this.$options.name, 'Done managing');
            this.managing = false;
            window.EventBus.$emit(constants.EVENTS.DONE_MANAGING_TAGS);
        },

        tagSelected(id) {
            logger.debug(this.$options.name, `Tag ID ${id} selected`);
            this.selectedTagIds.push(id);
            logger.debug(this.$options.name, '  Selected tag IDs: ' + JSON.stringify(this.selectedTagIds));
        },

        tagUnselected(id) {
            logger.debug(this.$options.name, `Tag ID ${id} unselected`);
            const index = this.selectedTagIds.indexOf(id);
            this.selectedTagIds.splice(index, 1);
            logger.debug(this.$options.name, '  Selected tag IDs: ' + JSON.stringify(this.selectedTagIds));
        },

        doneManagingButtonClick() {
            logger.debug(this.$options.name, 'triggerDoneManaging');
            window.EventBus.$emit(constants.EVENTS.DONE_MANAGING_TAGS);
        },

        selectAllButtonClick() {
            logger.debug(this.$options.name, 'triggerSelectAll');
            window.EventBus.$emit(constants.EVENTS.SELECT_ALL_TAGS);
        },

        unselectAllButtonClick() {
            logger.debug(this.$options.name, 'triggerUnselectAll');
            window.EventBus.$emit(constants.EVENTS.UNSELECT_ALL_TAGS);
        },

        mergeButtonClick() {
            const that = this;

            if (this.selectedTagIds.length === 0) {
                logger.debug(this.$options.name, 'No tags selected');
                return;
            }

            notifier
                .prompt('Merge Tags', 'What is the name of the new tag?', true, 'Please specify a new tag name')
                .then(newTagName => {
                    logger.debug(that.$options.name, 'New tag name: ' + JSON.stringify(newTagName));

                    if (newTagName) {
                        this.mergeTagsForReal(newTagName);
                        // .then(mergeResult => {
                        //     logger.debug(that.$options.name, 'Merge result: ' + JSON.stringify(mergeResult));
                        // })
                        // .catch(err => {
                        //     logger.warn(that.$options.name, 'Error during merging of tags: ' + JSON.stringify(err));
                        //     notifier.showErrorMessage(null, err);
                        // });
                    } else {
                        logger.debug(that.$options.name, 'Tag merge aborted by user');
                    }
                })
                .catch(err => logger.warn(that.$options.name, JSON.stringify(err)));
        },

        mergeTagsForReal(newTagName) {
            const that = this;
            const payload = {
                ids: this.selectedTagIds,
                name: newTagName
            };

            axios({
                method: 'POST',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BULK_TAGS_MERGE,
                data: payload
            })
                .then(() => {
                    logger.info(this.$options.name, `Tag merge of ${that.selectedTagIds.length} tag(s) successful`)
                    that.doneManagingButtonClick();
                    that.loadData();
                })
                .catch((err) => {
                    logger.warn(this.$options.name, 'Error on tag merge API call: ' + JSON.stringify(err));
                    notifier.showErrorMessage(null, err);
                });
        },

        deleteTagsButtonClick() {
            if (this.selectedTagIds.length > 0) {
                logger.info(this.$options.name, 'Deleting tag IDs: ' + JSON.stringify(this.selectedTagIds));
                this.confirmDeletion();
            } else {
                logger.debug(this.$options.name, 'No tags selected');
            }
        },

        confirmDeletion() {
            const that = this;

            notifier.showDeleteConfirmation(null, `Delete ${this.selectedTagIds.length} tags?`)
                .then((confirmed) => {
                    logger.debug(this.$options.name, 'Delete confirmation result: ' + JSON.stringify(confirmed));

                    if (confirmed) {
                        logger.debug(this.$options.name, 'Deletion confirmed');
                        that.deleteTagsForReal();
                    } else {
                        logger.debug(this.$options.name, 'Deletion cancelled');
                    }
                })
                .catch((err) => {
                    logger.warn(this.$options.name, `Exception on delete confirmation: ${JSON.stringify(err)}`);
                });
        },

        deleteTagsForReal() {
            const that = this;
            const payload = {ids: this.selectedTagIds};

            axios({
                method: 'DELETE',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BULK_TAGS,
                data: payload
            })
                .then(() => {
                    logger.info(this.$options.name, `Bulk deletion of ${that.selectedTagIds.length} tag(s) successful`)
                    that.doneManagingButtonClick();
                    that.loadData();
                })
                .catch((err) => {
                    logger.warn(this.$options.name, 'Error on tag deletion API call: ' + JSON.stringify(err));
                    notifier.showErrorMessage(null, err);
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .tags-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .button-bar {
        display: flex;
        flex-wrap: wrap;
        padding-top: 22px;
    }

    .management-button-group-wrapper {
        background-color: whitesmoke;
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 6px 12px;
    }

    .management-description {
        display: flex;
        justify-content: center;
        margin-bottom: 7px;
    }

    .management-status {
        display: flex;
        justify-content: center;
        margin-top: 7px;
    }

    .button-group {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .button-group a:not(:last-child) {
        margin-right: 10px;
    }

    .tags-column-view {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        margin-top: 20px;
    }

    .manage-mode {
        background-color: #E8F5E9;
    }

    @media (max-width: 700px) {
        .button-bar {
            padding-top: 0;
        }
    }

</style>

<template>
    <div class="tag-card" :class="(managing && selected ? 'selected-tag-card' : 'unselected-tag-card')" @click="selectTag(tag)">
        <div class="tag-title">

            <div v-if="managing === false" class="three-cols">
                <i class="fas fa-tag" v-if="!tag.specialTagName"></i>
                <i class="fas fa-star" v-if="tag.specialTagName" title="Has special behavior"></i>

                <router-link :to="tagNameLink" class="horizontal-padding">
                    {{ tag.name }}
                </router-link>

                <router-link :to="tagEditLink">
                    <i class="far fa-edit"></i>
                </router-link>
            </div>

            <div v-if="managing === true">
                <i class="fas fa-tag"></i>
                &nbsp;
                {{ tag.name }}
            </div>
        </div>
    </div>
</template>

<script>

import constants from '@/assets/js/constants';
import logger from '@/assets/js/logger';

export default {
    name: 'TagCard',

    props: ['tag', 'managing'],

    data() {
        return {
            selected: false
        };
    },

    computed: {
        tagNameLink() {
            return `${constants.ROUTES.BOOKMARKS}?tagId=${this.tag.id}`;
        },

        tagEditLink() {
            return `${constants.ROUTES.TAGS}/${this.tag.id}`;
        }
    },

    created() {
        window.EventBus.$on(constants.EVENTS.CLEAR_TAG_SELECTION, () => {
            logger.debug(this.$options.name, `Clearing tag selection for tag ID ${this.tag.id}`);
            this.selected = false;
        });

        window.EventBus.$on(constants.EVENTS.SELECT_ALL_TAGS, () => {
            if (!this.selected) {
                logger.debug(this.$options.name, `Selecting tag ID ${this.tag.id} through ${constants.EVENTS.SELECT_ALL_TAGS} event`);
                this.selected = true;
                this.$emit(constants.EVENTS.TAG_SELECTED, this.tag.id);
            }
        });

        window.EventBus.$on(constants.EVENTS.UNSELECT_ALL_TAGS, () => {
            if (this.selected) {
                logger.debug(this.$options.name, `Unselecting tag ID ${this.tag.id} through ${constants.EVENTS.UNSELECT_ALL_TAGS} event`);
                this.selected = false;
                this.$emit(constants.EVENTS.TAG_UNSELECTED, this.tag.id);
            }
        });
    },

    beforeDestroy() {
        logger.debug(this.$options.name, 'Unsubscribing from events: CLEAR_TAG_SELECTION, SELECT_ALL_TAGS, UNSELECT_ALL_TAGS');
        window.EventBus.$off(constants.EVENTS.CLEAR_TAG_SELECTION);
        window.EventBus.$off(constants.EVENTS.SELECT_ALL_TAGS);
        window.EventBus.$off(constants.EVENTS.UNSELECT_ALL_TAGS);
    },

    methods: {
        selectTag(tag) {
            if (!this.managing) {
                return;
            }

            logger.debug(this.$options.name, 'Tag ' + tag.id + ' (un)selected');

            this.selected = !this.selected;
            this.$emit((this.selected ? constants.EVENTS.TAG_SELECTED : constants.EVENTS.TAG_UNSELECTED), tag.id);
        }
    }

};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .selected-tag-card {
        border: 2px solid #05a;
    }

    .unselected-tag-card {
        background-color: whitesmoke;
    }

    .tag-card {
        margin: 10px 10px;
        min-width: 150px;

        box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
        border-radius: 5px 5px 5px 5px;
    }

    .tag-card a {
        color: black;
    }

    .tag-card a:hover {
        color: #3273dc;
        transition: color 500ms;
    }

    .tag-title {
        margin: 5px;
        font-size: 1rem;
    }

    .horizontal-padding {
        margin: 0 7px;
    }

    .three-cols {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media (max-width: 700px) {
        .tag-card {
            width: 100%;
        }
    }
</style>

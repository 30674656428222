<template>
    <div class="content-container">
        <div class="bookmarks-section">
            <div>
                <page-title>{{ variablePageTitle }}</page-title>
            </div>
            <div class="button-bar">
                <!--
                Disable until workaround found for browser that blocks opening multiple tabs.
                Actually, browsers only allow the opening of a tab as a direct user input action.
                -->
                <a v-if="false" class="button is-default" @click="openAll">
                    <span class="icon is-right">
                        <i class="fas fa-external-link-alt"></i>
                    </span>
                    &nbsp; Open All
                </a>

                <a class="button is-default" @click="loadData">
                    <span class="icon is-right">
                        <i class="fas fa-sync-alt"></i>
                    </span>
                    &nbsp; Refresh
                </a>

                <a class="button is-default" :class="managing ? 'manage-mode' : ''" @click="manage">
                    <span class="icon is-right">
                        <i class="fas fa-wrench"></i>
                    </span>
                    &nbsp; Manage
                </a>

                <span class="field has-text-left">
                    <span class="select">
                        <select v-model="viewMode" @change="viewChanged">
                            <option :value="viewConstants.CARD.code">{{ viewConstants.CARD.title }}</option>
                            <option :value="viewConstants.LIST.code">{{ viewConstants.LIST.title }}</option>
                            <option :value="viewConstants.LIST_NO_PREVIEW.code">{{ viewConstants.LIST_NO_PREVIEW.title }}</option>
                        </select>
                    </span>
                </span>

                <span class="field has-text-left">
                    <span class="select">
                        <select v-model="sortOrder" @change="sortChanged">
                            <option :value="sortConstants.ALPHABETIC.code">{{ sortConstants.ALPHABETIC.title }}</option>
                            <option :value="sortConstants.DATE_CREATED.code">{{ sortConstants.DATE_CREATED.title }}</option>
                        </select>
                    </span>
                </span>
            </div>
        </div>

        <bookmark-bag :bookmarks="sortedBookmarks" :pref-name="previewWidthSettingName" :managing="managing" :viewMode="viewMode" @refresh="refresh"></bookmark-bag>
    </div>
</template>

<script>
import axios from 'axios';
import BookmarkBag from '@/components/BookmarkBag';
import PageTitle from '@/components/PageTitle';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';
import logger from '@/assets/js/logger';

const ENTITIES = {
    COLLECTION: 'collection',
    TAG: 'tag',
    NONE: 'none'
};

export default {
    name: 'BookmarksPage',

    components: {
        BookmarkBag,
        PageTitle
    },

    data() {
        return {
            previewWidthSettingName: constants.USER_SETTINGS.DEFAULT_PREVIEW_WIDTH,
            bookmarkSource: [],
            sortedBookmarks: [],
            entityType: ENTITIES.NONE,
            managing: false,
            sortConstants: constants.SORT_ORDER,
            sortOrder: this.$store.getters.userSettings[constants.USER_SETTINGS.LINKS_PAGE_SORT_ORDER] || constants.SORT_ORDER.ALPHABETIC.code,
            variablePageTitle: '',

            viewConstants: constants.VIEW_MODE,
            viewMode: constants.VIEW_MODE.CARD.code,

            defaultPageTitle: 'All Bookmarks',
            defaultPageTitleSearch: 'Bookmark Search Results',
            defaultPageTitleRecent: 'Recently Added Bookmarks',
            defaultPageTitlePrefix: 'Bookmarks in '
        };
    },

    watch: {
        $route: 'loadData'
    },

    created: function () {
        this.loadData();

        window.EventBus.$on(constants.EVENTS.DONE_MANAGING_BOOKMARKS, () => {
            logger.debug(this.$options.name, 'Caught event ' + constants.EVENTS.DONE_MANAGING_BOOKMARKS);
            this.managing = false;
        });
    },

    beforeDestroy() {
        logger.debug(this.$options.name, 'Unsubscribing from event ' + constants.EVENTS.DONE_MANAGING_BOOKMARKS);
        window.EventBus.$off(constants.EVENTS.DONE_MANAGING_BOOKMARKS);
    },

    methods: {
        loadData() {
            const userSettings = this.$store.getters.userSettings;
            let url;

            this.viewMode = this.getViewModeFor(userSettings, null, null);

            if (this.$route.query.collectionId) {
                //
                // Collections
                //

                this.viewMode = this.getViewModeFor(userSettings, 'collections', this.$route.query.collectionId);
                url = window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARKS_FOR_COLLECTION.replace('{collectionId}', this.$route.query.collectionId);
                this.entityType = ENTITIES.COLLECTION;
                this.queryForTagOrCollection(url);
                window.EventBus.$emit('pathChanged', constants.ROUTES.BOOKMARKS);
            } else if (this.$route.query.tagId) {
                //
                // Tags
                //

                this.viewMode = this.getViewModeFor(userSettings, 'tags', this.$route.query.tagId);
                url = window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARKS_FOR_TAG.replace('{tagId}', this.$route.query.tagId);
                this.entityType = ENTITIES.TAG;
                this.queryForTagOrCollection(url);
                window.EventBus.$emit('pathChanged', constants.ROUTES.BOOKMARKS);
            } else if (this.$route.query.search) {
                //
                // Search
                //

                url = window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.SEARCH;
                this.queryForSearch(url);
                window.EventBus.$emit('pathChanged', constants.ROUTES.BOOKMARKS);
            } else if (this.$route.query.recent) {
                //
                // Recently added bookmarks
                //

                url = window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.RECENT;
                this.queryForRecentBookmarks(url);
                window.EventBus.$emit('pathChanged', constants.ROUTES.RECENTLY_ADDED);
            } else {
                //
                // All bookmarks
                //

                url = window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARKS;
                this.entityType = ENTITIES.NONE;
                this.queryForTagOrCollection(url);

                window.EventBus.$emit('pathChanged', constants.ROUTES.BOOKMARKS);
            }
        },

        queryForSearch(url) {
            const that = this;
            this.variablePageTitle = this.defaultPageTitleSearch;

            if (!this.$route.query?.search) {
                logger.info(this.$options.name, 'Nothing to search for');
                that.updateBookmarkSource([]);
                return;
            }

            let quotedString = '';

            if (this.$route.query?.search) {
                const searchArray = decodeURIComponent(this.$route.query?.search).split(',');
                searchArray.forEach((token) => {
                    quotedString += (token.indexOf(' ') > -1 ? '"' + token + '"' : token) + ' ';
                });
            }

            axios({
                method: constants.HTTP_ACTION.POST,
                url: url,
                data: { search: quotedString }
            })
                .then((response) => {
                    that.updateBookmarkSource(response.data.payload);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        queryForTagOrCollection(url) {
            const that = this;
            this.variablePageTitle = this.defaultPageTitle;

            logger.debug(this.$options.name, 'Getting bookmarks; url: ' + url);

            axios({
                method: 'GET',
                url: url
            })
                .then((response) => {
                    that.updateBookmarkSource(response.data.payload);
                })
                .catch((e) => {
                    logger.warn(this.$options.name, JSON.stringify(e));
                    notifier.showErrorMessage(null, e);
                });

            if (this.entityType === ENTITIES.COLLECTION) {
                url = window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.COLLECTIONS + `/${this.$route.query.collectionId}`;
            } else if (this.entityType === ENTITIES.TAG) {
                url = window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.TAGS + `/${this.$route.query.tagId}`;
            } else {
                return;
            }

            logger.debug(this.$options.name, 'url: ' + url);

            axios({
                method: 'GET',
                url: url
            })
                .then((response) => {
                    if (response && response.data.payload && response.data.payload.name) {
                        that.variablePageTitle = that.defaultPageTitlePrefix + response.data.payload.name;
                    }
                })
                .catch((e) => {
                    console.log(e);
                    notifier.showErrorMessage(null, e);
                });
        },

        queryForRecentBookmarks(url) {
            const that = this;
            this.variablePageTitle = this.defaultPageTitleRecent;

            logger.debug(this.$options.name, 'Getting bookmarks; url: ' + url);

            axios({
                method: 'GET',
                url: url
            })
                .then((response) => {
                    that.updateBookmarkSource(response.data.payload);
                })
                .catch((e) => {
                    logger.warn(this.$options.name, JSON.stringify(e));
                    notifier.showErrorMessage(null, e);
                });
        },

        refresh() {
            this.loadData();
        },

        manage() {
            this.managing = !this.managing;

            if (!this.managing) {
                this.doneManaging();
            }
        },

        doneManaging() {
            logger.debug(this.$options.name, 'Done managing');
            this.managing = false;
            window.EventBus.$emit(constants.EVENTS.DONE_MANAGING_BOOKMARKS);
        },

        openAll() {
            if (this.sortedBookmarks && this.sortedBookmarks.length > 0) {
                this.sortedBookmarks.forEach((bookmark) => {
                    window.open(bookmark.address, '_blank');
                });
            }
        },

        updateBookmarkSource(bookmarks) {
            this.bookmarkSource = bookmarks;
            this.sortChanged();
        },

        sortChanged() {
            if (this.bookmarkSource && this.bookmarkSource.length > 0) {
                this.sortedBookmarks = this.bookmarkSource.sort((a, b) => {
                    if (this.sortOrder === constants.SORT_ORDER.ALPHABETIC.code) {
                        if (a.title == null) return -1;
                        if (b.title == null) return 1;

                        return (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1);
                    } else if (this.sortOrder === constants.SORT_ORDER.DATE_CREATED.code) {
                        return (a.dateCreated < b.dateCreated ? -1 : 1);
                    }

                    return 0;
                });

                if (this.$store.getters.userSettings[constants.USER_SETTINGS.LINKS_PAGE_SORT_ORDER] !== this.sortOrder) {
                    const userSettings = this.$store.getters.userSettings;
                    userSettings[constants.USER_SETTINGS.LINKS_PAGE_SORT_ORDER] = this.sortOrder;
                    this.$store.commit('userSettings', userSettings);
                }
            } else {
                this.sortedBookmarks = [];
            }
        },

        viewChanged() {
            const userSettings = this.$store.getters.userSettings;

            if (userSettings) {
                if (!userSettings.views) userSettings.views = {};
                if (!userSettings.views.collections) userSettings.views.collections = {};
                if (!userSettings.views.tags) userSettings.views.tags = {};

                if (this.$route.query.collectionId) {
                    userSettings.views.collections[this.$route.query.collectionId] = this.viewMode;
                } else if (this.$route.query.tagId) {
                    userSettings.views.tags[this.$route.query.tagId] = this.viewMode;
                } else {
                    userSettings.views.defaultViewMode = this.viewMode;
                }

                this.$store.commit('userSettings', userSettings);
            }
        },

        getViewModeFor(userSettings, type, value) {
            if (!userSettings) return null;

            let vm = userSettings?.views?.defaultViewMode || constants.VIEW_MODE.CARD.code; // default value

            if (!type || !value) {
                return vm;
            }

            if (userSettings && userSettings.views && userSettings.views[type] && userSettings.views[type][value]) {
                vm = userSettings.views[type][value];
            } else if (userSettings && userSettings.views && userSettings.views.defaultViewMode) {
                vm = userSettings.views.defaultViewMode;
            }

            logger.debug(this.$options.name, 'viewMode: ' + vm);
            return vm;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .bookmarks-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .button-bar {
        display: flex;
        flex-wrap: wrap;
        padding-top: 22px;
    }

    .manage-mode {
        background-color: #E8F5E9;
    }

    @media (max-width: 700px) {
        .button-bar {
            padding-top: 0;
        }
    }

</style>

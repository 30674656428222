import axios from 'axios';
import Vue from 'vue';
import store from '../../store';
import notifier from '../../assets/js/notifier.js';
import constants from '../js/constants.js';

window.EventBus = new Vue();

function checkForUpdate() {
    setTimeout(() => {
        axios({
            method: 'GET',
            url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.VERSION
        })
            .then((response) => {
                if (response.data.payload !== constants.VERSION) {
                    let message = `A new version is available. Please reload the page to update to the latest version.`;
                    notifier.showInfoMessage('Update Available', message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, 2000);
}

function refreshFavCollections() {
    axios({
        method: 'get',
        url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.FAVOURITE_COLLECTIONS_FOR_USER,
        headers: { token: store.getters.token }
    })
        .then((response) => {
            store.commit('favouriteCollections', response.data.payload);
        })
        .catch((e) => {
            console.log(e);
        });
}

function getUserSubscription() {
    axios({
        method: 'GET',
        url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.CURRENT_USER_SUBSCRIPTION.replace('{userId}', store.getters.user.id),
        headers: { token: store.getters.token }
    })
        .then((response) => {
            if (response && response.data) {
                store.commit('subscription', response.data);
            } else {
                store.commit('subscription', response.data);
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

function getUserPermissions() {
    axios({
        method: 'GET',
        url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.PERMISSIONS_FOR_USER.replace('{userId}', store.getters.user.id),
        headers: { token: store.getters.token }
    })
        .then((response) => {
            if (response && response.data) {
                store.commit('permissions', response.data);
            }
        })
        .catch((e) => {
            console.log(e);
        });
}

function getUserBookmarkTemplates() {
    axios({
        method: 'GET',
        url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARK_TEMPLATES
    })
        .then((response) => {
            if (response && response.data.payload) {
                const sortedList = response.data.payload.sort((a, b) => {
                    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                });
                store.commit('bookmarkTemplates', sortedList);
            }
        })
        .catch((e) => {
            console.log(e);
        });
}

// function checkForInvites() {
//     axios({
//         method: 'GET',
//         url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.HAS_INVITES.replace('{userId}', store.getters.user.id),
//         headers: { token: store.getters.token }
//     })
//         .then((response) => {
//             if (response && response.data) {
//                 window.EventBus.$emit('hasInvitesUpdated', response.data.hasInvites);
//             } else {
//                 window.EventBus.$emit('hasInvitesUpdated', false);
//             }
//         })
//         .catch((e) => {
//             console.log(e);
//             window.EventBus.$emit('hasInvitesUpdated', false);
//         });
// }

window.EventBus.$on(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS, refreshFavCollections);
window.EventBus.$on('checkForUpdate', checkForUpdate);                      // TODO: Use constants.EVENTS.???
window.EventBus.$on('getUserSubscription', getUserSubscription);            // TODO: Use constants.EVENTS.???
window.EventBus.$on('getUserPermissions', getUserPermissions);              // TODO: Use constants.EVENTS.???
window.EventBus.$on('getUserBookmarkTemplates', getUserBookmarkTemplates);  // TODO: Use constants.EVENTS.???
// window.EventBus.$on('checkForInvites', checkForInvites);

window.EventBus.$on('showShortcutModal', () => {
    const element = document.getElementById('shortcut-modal');
    if (element) {
        element.classList.add('is-active');
    }
});

window.EventBus.$on('hideShortcutModal', () => {
    const element = document.getElementById('shortcut-modal');
    if (element) {
        element.classList.remove('is-active');
    }
});

window.EventBus.$on('focusSearchBox', () => {
    const element = document.getElementById('search');
    if (element) {
        element.focus();
    }
});

function init() {
    // do nothing
}

// -----------------------------------------------------------------------------
// Private functions
// -----------------------------------------------------------------------------

// function transformGroupGraphIntoMenuTree(data) {
//     const tree = data;
//
//     const crawler = function (node) {
//         node.path = `links?groupId=${node.id}`;
//         node.folder = (node.children && node.children.length > 0);
//
//         if (node.children && node.children.length > 0) {
//             node.children = node.children.sort((a, b) => {
//                 if (a.title.toLowerCase() < b.title.toLowerCase()) {
//                     return -1;
//                 } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
//                     return 1;
//                 }
//
//                 return 0;
//             });
//
//             for (let i = 0; i < node.children.length; i++) {
//                 crawler(node.children[i]);
//             }
//         }
//     };
//
//     crawler(tree);
//
//     return tree;
// }

// -----------------------------------------------------------------------------
// Export module goodies
// -----------------------------------------------------------------------------

export default {
    init
};

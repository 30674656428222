<template>
    <div>

        <div v-if="managing === true" class="management-button-group-wrapper">

            <div class="management-description">
                Manage your bookmarks with bulk edit, preview image refresh and delete functions
            </div>

            <div class="button-group">
                <a class="button is-primary" @click="triggerDoneManaging">
                <span class="icon is-right">
                    <i class="fas fa-external-link-alt"></i>
                </span>
                    &nbsp; Done
                </a>

                <a class="button is-default" @click="triggerSelectAll">
                <span class="icon is-right">
                    <i class="far fa-check-square"></i>
                </span>
                    &nbsp; Select All
                </a>

                <a class="button is-default" @click="triggerUnselectAll">
                <span class="icon is-right">
                    <i class="far fa-square"></i>
                </span>
                    &nbsp; Unselect All
                </a>

                <a class="button is-default" @click="triggerPreviewRefresh">
                <span class="icon is-right">
                    <i class="far fa-images"></i>
                </span>
                    &nbsp; Refresh Preview Images
                </a>

                <a class="button is-default" @click="triggerEdit">
                <span class="icon is-right">
                    <i class="far fa-edit"></i>
                </span>
                    &nbsp; Edit
                </a>

                <a class="button is-danger" @click="deleteBookmarks">
                <span class="icon is-right">
                    <i class="fas fa-sync-alt"></i>
                </span>
                    &nbsp; Delete
                </a>
            </div>

            <div class="management-status">
                {{ selectedBookmarkIds.length }} of {{ bookmarks.length }} selected
            </div>

        </div>

        <div v-if="isCardType" class="slider-wrapper">
            <input v-model="previewWidth" type="range" min="170" max="600" value="50" class="slider" id="card-slider" @change="sliderChanged">
        </div>

        <div v-if="isCardType" class="bookmarks-card-view">
            <bookmark-card v-for="bookmark in bookmarks"
                           :key="bookmark.id"
                           :bookmark="bookmark"
                           :preview-width="previewWidth"
                           :managing="managing"
                           @refresh="refresh"
                           @bookmarkSelected="bookmarkSelected"
                           @bookmarkUnselected="bookmarkUnselected">
            </bookmark-card>
        </div>

        <div v-if="!isCardType" class="bookmarks-list-view">
            <bookmark-list-item v-for="bookmark in bookmarks"
                                :key="bookmark.id"
                                :bookmark="bookmark"
                                :managing="managing"
                                :viewMode="viewMode"
                                @refresh="refresh"
                                @bookmarkSelected="bookmarkSelected"
                                @bookmarkUnselected="bookmarkUnselected">
            </bookmark-list-item>
        </div>

        <!-- --------------------------------------------------------------- -->
        <!-- Update Modal                                                    -->
        <!-- --------------------------------------------------------------- -->

        <div id="bulk-bookmark-edit-modal" class="modal">
            <div class="modal-background" @click="hideEditModal"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Edit Bookmarks</p>
                    <button class="delete" aria-label="close" @click="hideEditModal"></button>
                </header>
                <section class="modal-card-body">

                    <div class="field">
                        <label class="label">Tags</label>
                        <div class="control" @keydown.enter.prevent.self="">
                            <v-select v-model="selectedTags" :options="allTags" class="thing-selector" label="name" multiple taggable @search="asyncFindTags"></v-select>
                        </div>
                    </div>

                    <div class="field">
                        <label class="checkbox">
                            <input id="keepTags" type="checkbox" v-model="keepTags">
                            Keep existing tags (else all will be removed from bookmark)
                        </label>
                    </div>

                    <div class="field">
                        <label class="label">Collections</label>
                        <div class="control" @keydown.enter.prevent.self="">
                            <v-select v-model="selectedCollections" :options="allCollections" class="thing-selector" label="name" multiple taggable @search="asyncFindCollections"></v-select>
                        </div>
                    </div>

                    <div class="field">
                        <label class="checkbox">
                            <input id="keepCollections" type="checkbox" v-model="keepCollections">
                            Keep existing collections (else all will be removed from bookmark)
                        </label>
                    </div>

                    <div class="field">
                        <label class="label">Notes</label>
                        <div class="control">
                            <textarea id="bookmarkNotes" v-model="notes" class="textarea"></textarea>
                        </div>
                        <div><i>Replaces existing notes</i></div>
                    </div>

                </section>
                <footer class="modal-card-foot">
                    <button :class="{ 'is-loading': isSaving }" class="button is-info" @click="saveBookmarks">Save</button>
                    <button class="button" @click="hideEditModal">Cancel</button>
                </footer>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import BookmarkCard from '@/components/BookmarkCard';
import BookmarkListItem from "@/components/BookmarkListItem";
import notifier from '@/assets/js/notifier';
import constants from '@/assets/js/constants';
import logger from '@/assets/js/logger';

let changeTimer = null;

export default {
    name: 'BookmarkBag',

    components: {
        BookmarkCard,
        BookmarkListItem,
        'v-select': vSelect
    },
    props: ['bookmarks', 'prefName', 'managing', 'viewMode'],

    data() {
        return {
            selectedBookmarkIds: [],
            previewWidth: this.$store.getters.userSettings[this.prefName] || 350,

            // model variables
            isSaving: false,
            allTags: [],
            allCollections: [],
            selectedTags: [],
            selectedCollections: [],
            notes: '',
            keepTags: true,
            keepCollections: true
        };
    },

    computed: {
        isCardType() {
            return this.viewMode === constants.VIEW_MODE.CARD.code;
        }
    },

    created() {
        window.EventBus.$on(constants.EVENTS.DONE_MANAGING_BOOKMARKS, () => {
            logger.debug(this.$options.name, `Caught event ${constants.EVENTS.DONE_MANAGING_BOOKMARKS}`);
            this.selectedBookmarkIds = [];
            window.EventBus.$emit(constants.EVENTS.CLEAR_BOOKMARK_SELECTION);
        });
    },

    beforeDestroy() {
        logger.debug(this.$options.name, `Unsubscribing from event ${constants.EVENTS.DONE_MANAGING_BOOKMARKS}`);
        window.EventBus.$off(constants.EVENTS.DONE_MANAGING_BOOKMARKS);
    },

    methods: {
        sliderChanged() {
            const that = this;

            if (this.prefName && !changeTimer) {
                changeTimer = setTimeout(() => {
                    const userSettings = that.$store.getters.userSettings;
                    userSettings[that.prefName] = that.previewWidth;
                    that.$store.commit('userSettings', userSettings);

                    changeTimer = null;
                }, 1000);
            }
        },

        refresh() {
            this.$emit(constants.EVENTS.REFRESH);
            window.EventBus.$emit(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS);
        },

        triggerDoneManaging() {
            logger.debug(this.$options.name, 'triggerDoneManaging');
            window.EventBus.$emit(constants.EVENTS.DONE_MANAGING_BOOKMARKS);
        },

        triggerSelectAll() {
            logger.debug(this.$options.name, 'triggerSelectAll');
            window.EventBus.$emit(constants.EVENTS.SELECT_ALL_BOOKMARKS);
        },

        triggerUnselectAll() {
            logger.debug(this.$options.name, 'triggerUnselectAll');
            window.EventBus.$emit(constants.EVENTS.UNSELECT_ALL_BOOKMARKS);
        },

        triggerPreviewRefresh() {
            if (this.selectedBookmarkIds.length > 0) {
                logger.info(this.$options.name, 'Refreshing bookmark previews for IDs: ' + JSON.stringify(this.selectedBookmarkIds));
                this.confirmPreviewRefresh();
            } else {
                logger.debug(this.$options.name, 'No bookmarks selected');
            }
        },

        triggerEdit() {
            logger.debug(this.$options.name, 'triggerEdit');
            if (this.selectedBookmarkIds.length > 0) {
                this.showEditModal();
            } else {
                logger.debug(this.$options.name, 'No bookmarks selected');
            }
        },

        doneManaging() {
            logger.info(this.$options.name, 'Done managing');
            this.managing = false;

            logger.debug(this.$options.name, 'Emitting event: ' + constants.EVENTS.CLEAR_BOOKMARK_SELECTION);
            window.EventBus.$emit(constants.EVENTS.CLEAR_BOOKMARK_SELECTION);
        },

        bookmarkSelected(id) {
            logger.debug(this.$options.name, `Bookmark ID ${id} selected`);
            this.selectedBookmarkIds.push(id);
            logger.debug(this.$options.name, '  Selected bookmark IDs: ' + JSON.stringify(this.selectedBookmarkIds));
        },

        bookmarkUnselected(id) {
            logger.debug(this.$options.name, `Bookmark ID ${id} unselected`);
            const index = this.selectedBookmarkIds.indexOf(id);
            this.selectedBookmarkIds.splice(index, 1);
            logger.debug(this.$options.name, '  Selected bookmark IDs: ' + JSON.stringify(this.selectedBookmarkIds));
        },

        deleteBookmarks() {
            if (this.selectedBookmarkIds.length > 0) {
                logger.info(this.$options.name, 'Deleting bookmark IDs: ' + JSON.stringify(this.selectedBookmarkIds));
                this.confirmDeletion();
            } else {
                logger.debug(this.$options.name, 'No bookmarks selected');
            }
        },

        confirmDeletion() {
            const that = this;

            notifier.showDeleteConfirmation(null, `Delete ${this.selectedBookmarkIds.length} bookmarks?`)
                .then((confirmed) => {
                    logger.debug(this.$options.name, 'Delete confirmation result: ' + JSON.stringify(confirmed));

                    if (confirmed) {
                        logger.debug(this.$options.name, 'Deletion confirmed');
                        that.deleteBookmarksForReal();
                    } else {
                        logger.debug(this.$options.name, 'Deletion cancelled');
                    }
                })
                .catch((err) => {
                    logger.warn(this.$options.name, `Error on delete confirmation: ${JSON.stringify(err)}`);
                });
        },

        deleteBookmarksForReal() {
            const that = this;
            const payload = { ids: this.selectedBookmarkIds };

            axios({
                method: 'DELETE',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BULK_BOOKMARKS,
                data: payload
            })
                .then(() => {
                    logger.info(this.$options.name, `Bulk deletion of ${that.selectedBookmarkIds.length} bookmark(s) successful`)
                    that.triggerDoneManaging();
                    that.refresh();
                })
                .catch((err) => {
                    logger.warn(this.$options.name, 'Error on bookmark deletion API call: ' + JSON.stringify(err));
                    notifier.showErrorMessage(null, err);
                });
        },

        confirmPreviewRefresh() {
            const that = this;

            notifier.showGeneralConfirmation(null, `Refresh ${this.selectedBookmarkIds.length} bookmark previews?`, 'Yes, refresh previews')
                .then((confirmed) => {
                    logger.debug(this.$options.name, 'Preview refresh confirmation result: ' + JSON.stringify(confirmed));

                    if (confirmed) {
                        logger.debug(this.$options.name, 'Preview refresh confirmed');
                        that.refreshPreviewsForReal();
                    } else {
                        logger.debug(this.$options.name, 'Preview refresh cancelled');
                    }
                })
                .catch((err) => {
                    logger.warn(this.$options.name, `Error on preview refresh confirmation: ${JSON.stringify(err)}`);
                });
        },

        refreshPreviewsForReal() {
            const that = this;
            const payload = { ids: this.selectedBookmarkIds };

            axios({
                method: 'POST',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BULK_BOOKMARKS_REFRESH_PREVIEWS,
                data: payload
            })
                .then(() => {
                    logger.info(this.$options.name, `Bulk refresh of ${that.selectedBookmarkIds.length} previews successful`)
                    that.triggerDoneManaging();
                    that.refresh();
                })
                .catch((err) => {
                    logger.warn(this.$options.name, 'Error on preview refresh API call: ' + JSON.stringify(err));
                    notifier.showErrorMessage(null, err);
                });
        },

        // ---------------------------------------------------------------------
        // Modal methods
        // ---------------------------------------------------------------------

        showEditModal() {
            const element = document.getElementById('bulk-bookmark-edit-modal');

            if (element) {
                element.classList.add('is-active');

                this.asyncFindTags('', () => {});
                this.asyncFindCollections('', () => {});
            }

            // this.$refs.feedbackType.focus();
        },

        hideEditModal() {
            const element = document.getElementById('bulk-bookmark-edit-modal');

            if (element) {
                element.classList.remove('is-active');
            }

            // this.resetFeedbackForm();
        },

        asyncFindTags(query, loading) {
            const that = this;
            loading(true);

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.TAGS + `?search=${query}`
            })
                .then((response) => {
                    that.allTags = [];
                    loading(false);

                    if (response.data && response.data.payload.length > 0) {
                        that.allTags = response.data.payload.sort((a, b) => {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        });
                    }
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                    loading(false);
                });
        },

        asyncFindCollections(query, loading) {
            const that = this;
            loading(true);

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.COLLECTIONS + `?search=${query}`
            })
                .then((response) => {
                    that.allCollections = [];
                    loading(false);

                    if (response.data && response.data.payload.length > 0) {
                        that.allCollections = response.data.payload.sort((a, b) => {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        });
                    }
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                    loading(false);
                });
        },

        saveBookmarks() {
            let that = this;
            this.isSaving = true;

            // setTimeout(() => {
            //     that.isSaving = false;
            //     that.hideEditModal();
            //
            //     logger.debug(that.$options.name, 'Selected tags: ' + JSON.stringify(that.selectedTags));
            //     logger.debug(that.$options.name, 'Selected collections: ' + JSON.stringify(that.selectedCollections));
            // }, 2000);

            if (this.selectedTags.length > 0) {
                for (let index = 0; index < this.selectedTags.length; index++) {
                    if (!this.selectedTags[index].id) {
                        this.selectedTags[index] = {
                            id: 0,
                            name: this.selectedTags[index]
                        };
                    }
                }
            }

            if (this.selectedCollections.length > 0) {
                for (let index = 0; index < this.selectedCollections.length; index++) {
                    if (!this.selectedCollections[index].id) {
                        this.selectedCollections[index] = {
                            id: 0,
                            name: this.selectedCollections[index]
                        };
                    }
                }
            }

            const payload = {
                ids: this.selectedBookmarkIds,
                tags: this.selectedTags,
                collections: this.selectedCollections,
                notes: this.notes,
                keepTags: this.keepTags,
                keepCollections: this.keepCollections
            };

            logger.debug(this.$options.name, JSON.stringify(payload));

            axios({
                method: 'POST',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BULK_BOOKMARKS_UPDATE,
                data: payload
            })
                .then(() => {
                    that.isSaving = false;
                    logger.info(this.$options.name, `Bulk update of ${that.selectedBookmarkIds.length} bookmark(s) successful`)
                    that.hideEditModal();
                    that.triggerDoneManaging();
                    that.refresh();
                })
                .catch((err) => {
                    that.isSaving = false;
                    logger.warn(this.$options.name, 'Error on preview refresh API call: ' + JSON.stringify(err));
                    notifier.showErrorMessage(null, err);
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .management-button-group-wrapper {
        background-color: whitesmoke;
        border: 1px solid lightgray;
        border-radius: 5px;
        margin: 10px 0;
        padding: 6px 12px;
    }

    .management-description {
        display: flex;
        justify-content: center;
        margin-bottom: 7px;
    }

    .management-status {
        display: flex;
        justify-content: center;
        margin-top: 7px;
    }

    .button-group {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .button-group a:not(:last-child) {
        margin-right: 10px;
    }

    .slider-wrapper {
        display: block;
        margin: 10px 0 0 0;
        width: 100%;
    }

    /* Slider example: https://www.w3schools.com/howto/howto_js_rangeslider.asp */

    .slider {
        -webkit-appearance: none;   /* Override default CSS styles                          */
        appearance: none;
        width: 100%;                /* Full-width                                           */
        height: 20px;               /* Specified height                                     */
        background: #d3d3d3;        /* Background                                           */
        outline: none;              /* Remove outline                                       */
        opacity: 0.5;               /* Set transparency (for mouse-over effects on hover)   */
        -webkit-transition: .2s;    /* 0.2 seconds transition on hover                      */
        transition: opacity .2s;
    }

    .slider:hover {
        opacity: 1;                 /* Fully shown on mouse-over                            */
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;   /* Override default look                                */
        appearance: none;
        width: 20px;                /* Set a specific slider handle width                   */
        height: 20px;               /* Slider handle height                                 */
        background: gray;           /* Background                                           */
        cursor: pointer;            /* Cursor on hover                                      */
    }

    .slider::-moz-range-thumb {
        width: 20px;                /* Set a specific slider handle width                   */
        height: 20px;               /* Slider handle height                                 */
        background: gray;           /* Background                                           */
        cursor: pointer;            /* Cursor on hover                                      */
    }

    .bookmarks-card-view {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .bookmarks-list-view {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    /* The >>> thing helps this work in a scoped environment */
    .thing-selector >>> .vs__selected {
        background: lightskyblue;
    }

</style>

<template>
    <div class="content">
        <page-title>Upgrade Your Subscription</page-title>

        <p>Choose from the subscription options below. We have an option that is just right for you.</p>

        <form @submit.prevent>

            <div class="field has-text-left">
                <div class="select">
                    <select v-model="planOption">
                        <option v-for="option in planOptions" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
                    </select>
                </div>
            </div>

            <div class="control-buttons">
                <div class="field is-grouped">
                    <div class="control">
                        <button id="save-button" :disabled="true" class="button is-info" type="button" @click="pay">Pay With PayPal</button>
                    </div>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
import axios from 'axios';
import PageTitle from '@/components/PageTitle';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';

export default {
    name: 'UpgradePage',

    components: {
        PageTitle
    },

    data() {
        return {
            planOptions: [],
            planOption: null
        };
    },

    created() {
        this.loadData();
    },

    mounted() {
        window.EventBus.$emit('pathChanged', constants.ROUTES.NONE);
    },

    methods: {
        loadData() {
            const that = this;
            const url = window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.SUBSCRIPTION_PLAN_OPTIONS.replace('{subPlanId}', constants.PAID_SUBSCRIPTION_PLAN_ID);

            axios({
                method: constants.HTTP_ACTION.GET,
                url: url,
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    that.planOptions = response.data;

                    if (that.planOptions && that.planOptions.length > 0) {
                        that.planOption = that.planOptions[0].id;
                    }
                })
                .catch((e) => {
                    console.log(e);
                    notifier.showErrorMessage(null, e);
                });
        },

        pay() {
            const that = this;

            console.log('Thanks for choosing plan option' + this.planOption + '!');

            const details = {
                subPlanOptionId: this.planOption,
                userId: this.$store.getters.user.id,
                gatewayTransId: 'dummy',
                gatewayTransParent: 'dummy',
                payerRef: 'dummy',
                source: constants.TRANS_SOURCES.PAYPAL
            };

            axios({
                method: constants.HTTP_ACTION.POST,
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.SUBSCRIBE,
                headers: { token: this.$store.getters.token },
                data: details
            })
                .then((result) => {
                    that.$store.commit('subscription', result.data);

                    notifier.showSuccessMessage(null, 'Payment successful. Check out your subscription details...')
                        .then(() => {
                            that.$router.push(constants.ROUTES.HOME);
                        });
                })
                .catch((e) => {
                    console.log(e);
                    notifier.showErrorMessage(null, e);
                });
        },

        cancel() {
            this.$router.go(-1);
            return false;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .control-buttons {
        display: flex;
        justify-content: space-between;
    }
</style>

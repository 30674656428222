<template>
    <div>
        <section class="hero">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <div class="form-container">
                        <h3 class="title">Sign Up Confirmation</h3>

                        <div class="details-container">
                            <div class="has-text-right">Email address:</div>
                            <div class="has-text-left user-data">{{email}}</div>
                        </div>

                        <nav class="level">
                            <div class="level-item has-text-centered">
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button id="sign-up-button" v-bind:class="{ 'is-loading': loading }" class="button is-info" type="submit" @click="signUp">
                                            Sign Up
                                        </button>
                                    </div>

                                    <div class="control">
                                        <button id="back-button" class="button is-default" type="submit" @click="cancel">
                                            Go Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </nav>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';
import JSSHA from 'jssha';
// import FormError from '@/components/FormError';
import PageTitle from '@/components/PageTitle';
import notifier from '@/assets/js/notifier';
import constants from '@/assets/js/constants';
// import Form from '@/assets/js/form';

// const profileImage = require('@/assets/images/profile-01.png');

function hashPassword(pwd) {
    const shaObj = new JSSHA('SHA-512', 'TEXT');
    shaObj.update(pwd);
    return shaObj.getHash('HEX');
}

export default {
    name: 'SignUpConfirmationPage',

    components: {
        PageTitle
    },

    data() {
        return {
            email: '',
            password: '',
            loading: false
        };
    },

    mounted() {
        // Advance
        // if (this.$store.getters.authenticated) {
        //     this.$router.push(constants.ROUTES.DEFAULT);
        //     return;
        // }

        const details = this.$store.getters.signUp;
        this.email = details.email;
        this.password = details.password;

        // Clear the select menu item
        window.EventBus.$emit('pathChanged', constants.ROUTES.NONE);

        /*
        if (this.$route.query.code) {
            this.form.referralCode = this.$route.query.code;
            // this.loadVoucher();
        }
        */
    },

    methods: {
        signUp() {
            const that = this;

            const payload = {
                email: that.email,
                password: hashPassword(that.password)
            };

            this.loading = true;

            axios.post(window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.SIGN_UP, payload)
                .then((response) => {
                    that.loading = false;
                    that.$router.push(constants.ROUTES.SIGN_UP_SUCCESS);
                })
                .catch((err) => {
                    that.loading = false;
                    console.log('err.response.data: %j', err.response.data);

                    notifier.showErrorMessage(null, err);
                    that.$router.push(constants.ROUTES.SIGN_UP);
                });
        },

        cancel() {
            this.$router.go(-1);
            return false;
        },

        validateUser() {
            const errors = {};

            if (!this.form.email) {
                errors.email = ['Email address is required'];
            }

            if (!this.form.password) {
                errors.password = ['Password is required'];
            }

            // console.log('password: %s', this.form.password);
            // console.log('password2: %s', this.form.password2);

            if (this.form.password !== this.form.password2) {
                console.log('Validating passwords now');

                if (errors.password) {
                    errors.password.push('Passwords do not match');
                } else {
                    errors.password = ['Passwords do not match'];
                }
            }

            return errors;
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .form-container {
        min-width: 250px;
        max-width: 375px;
        margin: auto;
    }

    .level {
        margin-top: 15px;
    }

    .details-container {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 10px;
    }

    .user-data {
        font-weight: bold;
    }

</style>

import moment from 'moment';
import constants from './constants';
let env = constants.ENV.PROD; // initial value
let initialized = false;

const LEVEL = {
    TRACE: 'TRACE',
    DEBUG: 'DEBUG',
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR'
};

// Handy color selector: https://www.materialui.co/colors
const STYLE = {
    'TRACE': 'background-color: white; color: black; padding: 2px 4px;',
    'DEBUG': 'background-color: #E0E0E0; color: black; padding: 2px 4px;',
    'INFO': 'background-color: #BBDEFB; color: black; padding: 2px 4px;',
    'WARN': 'background-color: #FFA726; color: black; padding: 2px 4px;',
    'ERROR': 'background-color: #ef5350; color: white; padding: 2px 4px;'
};

function trace(label, msg) {
    log(LEVEL.TRACE, label, msg);
}

function debug(label, msg) {
    log(LEVEL.DEBUG, label, msg);
}

function info(label, msg) {
    log(LEVEL.INFO, label, msg);
}

function warn(label, msg) {
    log(LEVEL.WARN, label, msg);
}

function error(label, msg) {
    log(LEVEL.ERROR, label, msg);
}

function log(level, label, msg) {
    if (!initialized) {
        if (window.bookmarkPivotConfig && window.bookmarkPivotConfig.env) {
            env = window.bookmarkPivotConfig.env;
            initialized = true;
            let formattedMessage = formatMessage(level, label, `Logger initialized with environment "${env}"`);
            writeIt(formattedMessage, STYLE[LEVEL.INFO]);
        }
    }

    if (env === constants.ENV.PROD) {
        return;
    }

    writeIt(formatMessage(level, label, msg), STYLE[level]);
}
function formatMessage(level, label, msg) {
    return `%c${moment().format(constants.DATE_TIME_MILLIS_FORMAT)} ${level.toUpperCase()} [${label}] ${msg}`;
}

function writeIt(message, style) {
    console.log(message, style);
}

export default {
    trace,
    debug,
    info,
    warn,
    error,
    log
};

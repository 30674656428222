import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import App from './App';
import router from './router';
import store from './store';
import eventHandler from './assets/js/event-handler';
import constants from '@/assets/js/constants';
import logger from '@/assets/js/logger';
import axios from 'axios';

// init() is a dummy to fool ESLint into thinking we're actually using the imported object
eventHandler.init();

// Require these style sheets so that webpack can do its magic.
// It will be injected into the HTML header automatically.
// require('font-awesome/css/font-awesome.css');
require('@fortawesome/fontawesome-free/css/all.css');
require('bulma/css/bulma.css');

// Load Sweet Alert 2 CSS
require('sweetalert2/dist/sweetalert2.min.css');

// Multiselect CSS
require('vue-multiselect/dist/vue-multiselect.min.css');

// Toastr
require('toastr/build/toastr.min.css');
const toastr = require('toastr/build/toastr.min.js');

window.toastr = toastr;

// Mousetrap
const Mousetrap = require('mousetrap');

// What does this do?
Vue.config.productionTip = false;

Vue.use(VueLazyload);

const pathToConfig = '/config/cloud-city.json';
const portsInOrder = [443, 9090, 80];
const logLabel = 'main.js';

getConfig()
    .then((config) => {
        console.log(`Retrieved config: ${JSON.stringify(config)}`);
        window.bookmarkPivotConfig = config;
        startApp();
    }).catch(err => {
        console.log('Unable to retrieve config: ' + JSON.stringify(err));
        // TODO: Maybe display an error to the user?
    });

async function getConfig() {
    return new Promise(async(resolve, reject) => {
        let config;

        try {
            config = await getConfigViaPort(portsInOrder[0]);
            if (config) {
                resolve(config);
            }
        } catch (ignore) {
        }

        if (!config) {
            try {
                config = await getConfigViaPort(portsInOrder[1]);
                if (config) {
                    resolve(config);
                }
            } catch (err) {
                reject(err);
            }
        }
    });
}

async function getConfigViaPort(port) {
    return new Promise((resolve, reject) => {
        const url = `${location.protocol}//${location.hostname}:${port}${pathToConfig}`;
        console.log(`Getting config using URL: ${url}`);

        axios({
            method: 'GET',
            url: url
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function startApp() {
    logger.info(logLabel, 'Config loaded. Starting App...');

    // -----------------------------------------------------------------------------
    // Set the authenticated user in the store
    // -----------------------------------------------------------------------------
    const savedUser = window.localStorage.getItem('user');
    const savedToken = window.localStorage.getItem('token');

    axios.interceptors.response.use((response) => {
        return response;
    }, function (error) {

        if (error.response.status === 403) {
            logger.info(logLabel, '403 error message: ' + error.response.data.message);

            // Clear current user details from the store
            store.commit('user', null);
            store.commit('token', null);
            store.commit('authenticated', false);

            // Ask the user to log in again
            router.push(constants.ROUTES.LOGIN);
        }

        return Promise.reject(error.response);
    });

    // TODO: Validate the token. If expired, purge the user details and let the user log in again.

    if (savedUser && savedToken) {
        const usr = JSON.parse(savedUser);

        if (usr) {
            store.commit('user', usr);
            store.commit('token', savedToken);
            store.commit('authenticated', true);

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + savedToken;

            window.EventBus.$emit(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS); // Set the collections in the top part
            window.EventBus.$emit('checkForUpdate');
            // window.EventBus.$emit('getUserSubscription');
            // window.EventBus.$emit('getUserPermissions');
            window.EventBus.$emit('getUserBookmarkTemplates');
            // window.EventBus.$emit('checkForInvites');

            logger.info(logLabel, `Welcome back, ${usr.preferredName || usr.email}`);
        } else {
            store.commit('authenticated', false);
        }

        logger.info(logLabel, 'Bookmark Pivot version ' + constants.VERSION + ' ready...');
    } else {
        // TODO: Redirect to login page

        // TODO: Maybe do "store.commit('authenticated', false);" here as well?
    }

    const userSettingsString = window.localStorage.getItem('userSettings');

    if (userSettingsString) {
        let userSettings = JSON.parse(userSettingsString);

        if (!userSettings) {
            userSettings = {};
        }

        if (userSettings) {
            store.commit('userSettings', userSettings);
        }
    }

    bindKeyboardShortcuts();

    /* eslint-disable no-new */
    new Vue({
        el: '#app',
        router,
        store,
        components: { App },
        template: '<App/>'
    });
}

function bindKeyboardShortcuts() {
    // Initial stuff
    Mousetrap.bind('?', function () { window.EventBus.$emit('showShortcutModal'); });

    // Go
    Mousetrap.bind('h', function () { router.push(constants.ROUTES.HOME); });
    Mousetrap.bind('s', function () { window.EventBus.$emit('focusSearchBox'); });
    Mousetrap.bind('b', function () { router.push(constants.ROUTES.BOOKMARKS); });
    Mousetrap.bind('c', function () { router.push(constants.ROUTES.COLLECTIONS); });
    Mousetrap.bind('t', function () { router.push(constants.ROUTES.TAGS); });
    Mousetrap.bind('f', function () { router.push(constants.ROUTES.FRIENDS); });
    Mousetrap.bind('m', function () { router.push(constants.ROUTES.BOOKMARK_TEMPLATES); });

    // Create
    Mousetrap.bind('n b', function () { router.push(constants.ROUTES.BOOKMARKS + '/new'); });
    Mousetrap.bind('n c', function () { router.push(constants.ROUTES.COLLECTIONS + '/new'); });
    Mousetrap.bind('n t', function () { router.push(constants.ROUTES.TAGS + '/new'); });
    Mousetrap.bind('n f', function () { router.push(constants.ROUTES.FRIENDS + '/new'); });
    Mousetrap.bind('n m', function () { router.push(constants.ROUTES.BOOKMARK_TEMPLATES + '/new'); });
}

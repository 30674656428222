<template>
    <li>
        <div :class="{bold: isFolder}">
            <span v-if="isFolder" @click="toggle"><i :class="open ? parentOpenClass : parentCloseClass"></i></span>
            <span v-if="!isFolder"><i :class="itemClass"></i></span>
            <input v-model="model.selected" v-bind:true-value="true" v-bind:false-value="false" type="checkbox"
                   @click="selectClicked">
            <span @click="toggle">{{model.title}}</span>
        </div>
        <ul v-show="open" v-if="isFolder">
            <tree-node v-for="model in model.children" :key="model.id"
                       :model="model"
                       :parent-open-class="parentOpenClass"
                       :parent-close-class="parentCloseClass"
                       :item-class="itemClass"
                       class="item"
                       @ticked="ticked">
            </tree-node>
        </ul>
    </li>
</template>

<script>
import TreeNode from '@/components/TreeNode';

export default {
    name: 'TreeNode',

    components: {
        TreeNode
    },

    props: ['model', 'parentOpenClass', 'parentCloseClass', 'itemClass'],

    data: function () {
        return {
            open: true
        };
    },

    computed: {
        isFolder: function () {
            return this.model.children && this.model.children.length;
        }
    },

    methods: {
        toggle: function () {
            if (this.isFolder) {
                this.open = !this.open;
            } else {
                this.model.selected = !this.model.selected;
                this.$emit('ticked', this.model);
            }
        },

        selectClicked() {
            const that = this;

            setTimeout(() => {
                that.$emit('ticked', that.model);
            }, 10);
        },

        ticked(element) {
            this.$emit('ticked', element);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .item {
        cursor: pointer;
    }

    .bold {
        font-weight: bold;
    }

    ul {
        padding-left: 2em;
        line-height: 1.5em;
        /*list-style-type: dot;*/
    }
</style>

<template>
    <div class="title">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'PageTitle'
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .title {
        font-size: 24px;
        padding-top: 22px;
        white-space: nowrap;
    }

    @media (max-width: 700px) {
        .title {
            padding-top: 10px;
            margin-bottom: 5px !important;
            text-align: center;
        }
    }

</style>

<template>
    <div class="search-box">
        <div class="search-component-container">
            <span v-for="token in tokens"
                  :key="token"
                  :class="{
                      token: true,
                      'tag-token': token.startsWith('#'),
                      'collection-token': token.startsWith('&'),
                      'term-token': (!token.startsWith('#') && !token.startsWith('&'))}">{{ token }} <i class="close fa fa-times" @click="removeToken(token)"></i>
            </span>

            <input id="search" ref="search" v-model="search" placeholder="search..."
                   @keydown="keyIntercept"
                   @keyup="performLookup"
                   @focus="listVisible = false">

            <span class="icon is-right">
                <i v-if="tokens.length === 0" class="fas fa-search"></i>
                <i v-if="tokens.length > 0" class="clear-icon fa fa-times-circle" @click="clearAll"></i>
            </span>
        </div>

        <div v-if="listVisible" class="search-list-container">
            <select id="search-list" ref="searchList" multiple="multiple"
                    @keydown="listIntercept"
                    @dblclick="performSearch($event.target.value, false)">
                <option v-for="(result, index) in searchResults" :value="result" :key="result" :selected="index === 0">{{ result }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';
import logger from '@/assets/js/logger';

export default {
    name: 'SearchBox',

    data() {
        return {
            tokens: [],
            search: '',
            searchResults: [],
            listVisible: false
        };
    },

    watch: {
        $route: 'scanQueryParameters'
    },

    mounted() {
        this.scanQueryParameters();
    },

    methods: {
        keyIntercept: function (event) {
            switch (event.keyCode) {
            // BACKSPACE
            case 8:
                if (!this.search) {
                    this.listVisible = false;
                    this.tokens.pop();

                    if (this.tokens.length === 0) {
                        this.tokens = [];
                        this.search = '';
                    }
                }
                break;
            // TAB
            case 9:
                event.preventDefault();

                if (this.search && this.search.trim().length > 0) {
                    this.performSearch(this.search.trim(), true);
                    this.search = '';
                }
                break;
            // CARRIAGE RETURN
            case 13:
                if (this.search && this.search.trim().length > 0) {
                    this.performSearch(this.search.trim(), true);
                    this.search = '';
                }

                break;
            // UP ARROW
            case 38:
                break;
            // DOWN ARROW
            case 40:
                if (this.listVisible) {
                    this.$refs.searchList.focus();
                    event.preventDefault();
                }

                break;
            }
        },

        listIntercept: function (event) {
            switch (event.keyCode) {
            // TAB
            case 9:
                this.listVisible = false;
                break;
            // CARRIAGE RETURN
            case 13:
                this.performSearch(event.target.value, false);
                break;
            // ESCAPE
            case 27:
                this.$refs.search.focus();
                this.listVisible = false;
                break;
            // UP ARROW
            case 38:
                if (event.target.options.selectedIndex === 0) {
                    this.$refs.search.focus();
                    this.listVisible = false;
                }
                break;
            }
        },

        addToken(token, includePrefix) {
            if (!token || token.trim().length === 0) {
                return;
            }

            let prefix = '';

            if (!includePrefix) {
                if (this.search.startsWith('#')) {
                    prefix = '#';
                } else if (this.search.startsWith('&')) {
                    prefix = '&';
                }
            }

            const index = this.tokens.indexOf(prefix + token);

            if (index === -1) {
                this.tokens.push(prefix + token);
                this.search = '';
                this.searchResults = [];
                this.listVisible = false;
                this.$refs.search.focus();
            }
        },

        removeToken(token) {
            const index = this.tokens.indexOf(token);
            this.tokens.splice(index, 1);

            if (this.tokens.length > 0) {
                this.showResults();
            }
        },

        performSearch(token, includePrefix) {
            this.addToken(token, includePrefix);
            this.showResults();
        },

        showResults() {
            this.$router.push({ path: constants.ROUTES.SEARCH, query: { search: encodeURIComponent(this.tokens.join(',')) }});
        },

        performLookup() {
            if (!this.search || this.search.length === 0) {
                this.listVisible = false;
                return;
            }

            if (this.search.startsWith('#') && this.search.length > 2) {
                this.lookupTag(this.search.trim().substring(1));
                this.listVisible = true;
            } else if (this.search.startsWith('&') && this.search.length > 2) {
                this.lookupCollection(this.search.trim().substring(1));
                this.listVisible = true;
            }
        },

        lookupTag(snippet) {
            const that = this;
            that.searchResults = [];

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.TAGS + `?search=${snippet}`
            })
                .then((response) => {
                    if (response.data && response.data.payload.length > 0) {
                        that.searchResults = response.data.payload
                            .map((item) => {
                                return item.name;
                            })
                            .sort((a, b) => {
                                return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
                            });
                    }
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });
        },

        lookupCollection(snippet) {
            const that = this;
            that.searchResults = [];

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.COLLECTIONS + `?search=${snippet}`
            })
                .then((response) => {
                    if (response.data && response.data.payload.length > 0) {
                        that.searchResults = response.data.payload
                            .map((item) => {
                                return item.name;
                            })
                            .sort((a, b) => {
                                return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
                            });
                    }
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });
        },

        clearAll() {
            this.tokens = [];
            this.search = '';
        },

        scanQueryParameters() {
            const that = this;

            if (!this.$route.query?.search) {
                this.tokens = [];
                return;
            }

            if (this.tokens.length > 0)
                return;

            const searchQuery = decodeURIComponent(this.$route.query?.search);

            searchQuery.split(',').forEach(token => {
                that.addToken(token, true);
            })
        }
    }
};

</script>

<style scoped>
    .search-box {
        border: 1px solid lightgray;
        border-radius: 5px;
        position: relative;
    }

    .search-component-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .search-list-container {
        background-color: white;

        border: 1px solid lightgray;
        border-radius: 5px;

        position: absolute;
        bottom: -203px;
        right: 0;

        height: 200px;
        width: 287px;

        padding: 4px;

        z-index: 999;
    }

    select {
        border: 1px solid white; /* use same color as search-list-container background */

        font-size: 1.1rem;

        height: 100%;
        width: 100%;

        outline: none;
    }

    option {
        border-radius: 3px;
        margin: 5px 0 5px 5px;
        padding: 3px;
    }

    .token {
        border: 1px solid lightgray;
        border-radius: 5px;
        margin: 5px;
        padding: 0 3px;
    }

    .tag-token {
        background-color: lightgreen;
    }

    .collection-token {
        background-color: lightblue;
    }

    .term-token {
        background-color: palegoldenrod;
    }

    .search-component-container input {
        font-size: 1rem;
        flex: 1;
        border: 1px solid white;
        outline: none;
        min-width: 150px;
        margin: 5px;
    }

    .icon {
        color: lightgray;
        margin: 5px;
    }

    .clear-icon {
        color: gray;
        cursor: pointer;
    }

    .close {
        color: gray;
        cursor: pointer;
        padding: 0 2px;
        vertical-align: baseline;
    }

    @media (max-width: 700px) {
        .search-box {
            margin-left: 5px;
        }
    }

</style>

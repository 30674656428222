<template>
    <div>
        <section class="hero">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <div class="form-container">
                        <h3 class="title">Sign Up Success</h3>

                        <p>
                            Welcome to the Bookmark Pivot family! We've sent an email to the address <b>{{email}}</b>
                            with a link that you can follow to activate your account.
                        </p>

                        <p>
                            This activation step is important as it serves two purposes:
                        </p>

                        <p>
                            Firstly, we establish that you have access to the email address you supplied so that
                            you can rest assured that you will be able to recover your account at Bookmark Pivot in
                            the unfortunate event that your password gets lost.
                        </p>

                        <p>
                            Secondly, we prevent bots from spamming our database with junk.
                        </p>

                        <p>
                            Please take a moment and check your inbox for a message from <i>hello@bookmarkpivot.com</i>
                            with the subject <i>Activate your Bookmark Pivot account</i>. Click the link to activate
                            your account and start using Bookmark Pivot immediately.
                        </p>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';

export default {
    name: 'SignUpSuccessPage',

    components: {
        PageTitle
    },

    data() {
        return {
            email: ''
        };
    },

    mounted() {
        const details = this.$store.getters.signUp;
        this.email = details.email;
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .form-container {
        max-width: 600px;
        margin: auto;
    }

    p {
        margin-bottom: 15px;
        text-align: justify;
    }

    @media (max-width: 700px) {
        .form-container {
            min-width: 250px;
            max-width: 375px;
            margin: auto;
        }

        p {
            text-align: left;
        }
    }

</style>

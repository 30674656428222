import Vue from 'vue';
import Router from 'vue-router';

import Sys404Page from '@/components/Sys404Page';
import SignUpPage from '@/components/SignUpPage';
import SignUpConfirmationPage from '@/components/SignUpConfirmationPage';
import SignUpSuccessPage from '@/components/SignUpSuccessPage';
import SignUpResendPage from '@/components/SignUpResendPage';
import SignUpActivationPage from '@/components/SignUpActivationPage';
import AboutPage from '@/components/AboutPage';
import BookmarkTemplatesPage from '@/components/BookmarkTemplatesPage';
import BookmarkTemplatePage from '@/components/BookmarkTemplatePage';
import FriendsPage from '@/components/friend/FriendsPage';
import FriendPage from '@/components/friend/FriendPage';
import HomePage from '@/components/HomePage';
import ImportPage from '@/components/ImportPage';
import InviteFriendPage from '@/components/InviteFriendPage';
import LoginPage from '@/components/LoginPage';
import LogoutPage from '@/components/LogoutPage';
import ForgotPasswordPage from '@/components/ForgotPasswordPage';
import RecoverPasswordPage from '@/components/RecoverPasswordPage';
import RecentlyVisitedPage from '@/components/RecentlyVisitedPage';
import RecentlyAddedPage from '@/components/RecentlyAddedPage';
import BookmarksPage from '@/components/BookmarksPage';
import BookmarkPage from '@/components/BookmarkPage';
import SharePage from '@/components/SharePage';
import ExpressBookmarkPage from '@/components/ExpressBookmarkPage';
import TagsPage from '@/components/TagsPage';
import TagPage from '@/components/TagPage';
import CollectionsPage from '@/components/CollectionsPage';
import CollectionPage from '@/components/CollectionPage';
import UpgradePage from '@/components/UpgradePage';

import NewBasic from '@/components/NewBasic';
import NewFull from '@/components/NewFull';

import constants from '@/assets/js/constants';

import store from '../store';

Vue.use(Router);

const router = new Router({
    // mode: 'history',

    routes: [
        {
            path: constants.ROUTES.HOME,
            name: 'home',
            component: HomePage,
            meta: { requiresAuth: true }
        },
        {
            path: constants.ROUTES.SIGN_UP,
            name: 'signUp',
            component: SignUpPage,
            meta: { requiresAuth: false }
        },
        {
            path: constants.ROUTES.SIGN_UP_CONFIRMATION,
            name: 'signUpConfirmation',
            component: SignUpConfirmationPage,
            meta: { requiresAuth: false }
        },
        {
            path: constants.ROUTES.SIGN_UP_SUCCESS,
            name: 'signUpSuccess',
            component: SignUpSuccessPage,
            meta: { requiresAuth: false }
        },
        {
            path: constants.ROUTES.SIGN_UP_RESEND,
            name: 'signUpResend',
            component: SignUpResendPage,
            meta: { requiresAuth: false }
        },
        {
            path: `${constants.ROUTES.SIGN_UP_ACTIVATION}/:token`,
            name: 'signUpActivation',
            component: SignUpActivationPage,
            meta: { requiresAuth: false }
        },
        {
            path: constants.ROUTES.ABOUT,
            name: 'about',
            component: AboutPage,
            meta: { requiresAuth: false }
        },
        {
            path: constants.ROUTES.IMPORT,
            name: 'import',
            component: ImportPage,
            meta: { requiresAuth: true }
        },
        {
            path: constants.ROUTES.INVITE_FRIEND,
            name: 'inviteFriend',
            component: InviteFriendPage,
            meta: { requiresAuth: true }
        },
        {
            path: constants.ROUTES.LOGIN,
            name: 'login',
            component: LoginPage,
            meta: { requiresAuth: false }
        },
        {
            path: constants.ROUTES.LOGOUT,
            name: 'logout',
            component: LogoutPage,
            meta: { requiresAuth: false }
        },
        {
            path: constants.ROUTES.FORGOT_PASSWORD,
            name: 'forgotPassword',
            component: ForgotPasswordPage,
            meta: { requiresAuth: false }
        },
        {
            path: `${constants.ROUTES.RECOVER_PASSWORD}/:token`,
            name: 'recoverPassword',
            component: RecoverPasswordPage,
            meta: { requiresAuth: false }
        },
        // {
        //     path: constants.ROUTES.RECENTLY_VISITED,
        //     name: 'recentlyVisited',
        //     component: RecentlyVisitedPage,
        //     meta: { requiresAuth: true }
        // },
        {
            path: constants.ROUTES.RECENTLY_ADDED,
            name: 'recentBookmarks',
            component: BookmarksPage,
            meta: { requiresAuth: true }
        },
        {
            path: `${constants.ROUTES.EXPRESS_BOOKMARK}/:id`,
            name: 'expressBookmark',
            component: ExpressBookmarkPage,
            meta: { requiresAuth: true }
        },
        {
            path: constants.ROUTES.BOOKMARKS,
            name: 'bookmarks',
            component: BookmarksPage,
            meta: { requiresAuth: true }
        },
        {
            path: `${constants.ROUTES.BOOKMARKS}/:id`,
            name: 'bookmark',
            component: BookmarkPage,
            meta: { requiresAuth: true }
        },
        {
            path: constants.ROUTES.BOOKMARK_TEMPLATES,
            name: 'bookmarkTemplates',
            component: BookmarkTemplatesPage,
            meta: { requiresAuth: true }
        },
        {
            path: `${constants.ROUTES.BOOKMARK_TEMPLATES}/:id`,
            name: 'bookmarkTemplate',
            component: BookmarkTemplatePage,
            meta: { requiresAuth: true }
        },
        {
            path: constants.ROUTES.FRIENDS,
            name: 'friends',
            component: FriendsPage,
            meta: { requiresAuth: true }
        },
        {
            path: `${constants.ROUTES.FRIENDS}/:id`,
            name: 'friend',
            component: FriendPage,
            meta: { requiresAuth: true }
        },
        {
            path: `${constants.ROUTES.SHARE}/:id`,
            name: 'share',
            component: SharePage,
            meta: { requiresAuth: true }
        },
        {
            path: constants.ROUTES.TAGS,
            name: 'tags',
            component: TagsPage,
            meta: { requiresAuth: true }
        },
        {
            path: `${constants.ROUTES.TAGS}/:id`,
            name: 'tag',
            component: TagPage,
            meta: { requiresAuth: true }
        },
        {
            path: constants.ROUTES.COLLECTIONS,
            name: 'collections',
            component: CollectionsPage,
            meta: { requiresAuth: true }
        },
        {
            path: `${constants.ROUTES.COLLECTIONS}/:id`,
            name: 'collection',
            component: CollectionPage,
            meta: { requiresAuth: true }
        },
        {
            path: constants.ROUTES.SEARCH,
            name: 'search',
            component: BookmarksPage,
            meta: { requiresAuth: true }
        },
        {
            path: constants.ROUTES.UPGRADE,
            name: 'upgrade',
            component: UpgradePage,
            meta: { requiresAuth: true }
        },
        {
            path: '*',
            name: '404',
            component: Sys404Page,
            meta: { requiresAuth: false }
        }
    ]
});

router.beforeEach((to, from, next) => {
    const authenticated = store.getters.authenticated;

    // If this route requires auth, check if logged in.
    // If not, redirect to login page.
    if (to.meta.requiresAuth && !authenticated) {
        console.log('Not logged in. Redirecting to login page.');
        next({ path: constants.ROUTES.LOGIN, query: { redirect: to.fullPath } });
        // next(false);
        // window.location = process.env.VUE_APP_URL_LOGIN + `?redirect=${to.fullPath}`;
    } else {
        next(); // make sure to always call next()!
    }
});

export default router;

<template>
    <div class="privileged-menu">
        <ul>
<!--            <li :class="{'active': activePath === homePath}"><router-link :to="{ path: homePath }"><i :class="'fas fa-home'"></i> Home</router-link></li>-->
            <li :class="{'active': activePath === tagPath}"><router-link :to="{ path: tagPath }"><i :class="'fas fa-tag'"></i> Tags</router-link></li>
            <li :class="{'active': activePath === collectionPath}"><router-link :to="{ path: collectionPath }"><i :class="'fas fa-shopping-bag'"></i> Collections</router-link></li>
            <li :class="{'active': activePath === recentPath}"><router-link :to="{ path: recentPath }"><i :class="'fas fa-bookmark'"></i> Recent Bookmarks</router-link></li>
            <li :class="{'active': activePath === bookmarkPath}"><router-link :to="{ path: bookmarkPath }"><i :class="'fas fa-bookmark'"></i> Bookmarks</router-link></li>
            <li :class="{'active': activePath === templatePath}"><router-link :to="{ path: templatePath }"><i :class="'fas fa-clone'"></i> Templates</router-link></li>
            <li :class="{'active': activePath === friendPath}"><router-link :to="{ path: friendPath }"><i :class="'fas fa-user-friends'"></i> Friends</router-link></li>
            <li :class="{'active': activePath === aboutPath}"><router-link :to="{ path: aboutPath }"><i :class="'fas fa-address-card'"></i> About</router-link></li>
        </ul>
    </div>
</template>

<script>

import constants from '@/assets/js/constants';

export default {
    name: 'MainMenu',

    components: {
    },

    data() {
        return {
            activePath: '',
            homePath: constants.ROUTES.HOME,
            tagPath: constants.ROUTES.TAGS,
            collectionPath: constants.ROUTES.COLLECTIONS,
            recentPath: constants.ROUTES.RECENTLY_ADDED,
            bookmarkPath: constants.ROUTES.BOOKMARKS,
            templatePath: constants.ROUTES.BOOKMARK_TEMPLATES,
            friendPath: constants.ROUTES.FRIENDS,
            aboutPath: constants.ROUTES.ABOUT
        };
    },

    created() {
        const self = this;

        window.EventBus.$on('pathChanged', (path) => {
            self.activePath = path;
        });
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    a {
        color: black;
    }

    a:hover {
        color: #3273dc;
    }

    li {
        font-size: 110%;
        margin-bottom: 5px;
    }

    li.active > a {
        color: inherit;
    }

    .active {
        color: #3273dc;
        font-style: italic;
        font-weight: bold;
    }

    .fas {
        width: 24px;
    }

    @media (max-width: 700px) {
        li {
            font-size: 100%;
            display: inline-block;
            margin: 0 15px 0 0;
        }

        .fas {
            width: 20px;
        }
    }

</style>

<template>
    <div>
        <page-title>Add New Bookmark</page-title>

        <form @submit.prevent @keydown="form.errorWrapper.clear($event.target.name)">
            <div class="field">
                <label class="label">Address</label>
                <div class="control has-icons-right">
                    <input id="address" ref="address" v-model="form.address" type="text" name="address" class="input"/>
                    <span class="icon is-right">
                        <i class="fas fa-link"></i>
                    </span>
                </div>
                <form-error :field="'address'" :form="form"/>
            </div>

            <div v-if="bookmarkTemplate" class="bookmark-template-wrapper">
                <p>Applying bookmark template <b>{{ bookmarkTemplate.name }}</b> with tags
                    <span class="formatted-tag-list">{{ formattedTagList }}</span> and collections
                <span class="formatted-collection-list">{{ formattedCollectionList }}</span></p>
            </div>

            <div class="control-buttons">
                <div class="field is-grouped">
                    <div class="control">
                        <button id="save-button" class="button is-info" type="submit" @click="saveBookmark">Save</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import FormError from '@/components/FormError';
import PageTitle from '@/components/PageTitle';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';
import Form from '@/assets/js/form';

export default {
    name: 'ExpressLinkPage',

    components: {
        FormError,
        PageTitle
    },

    data() {
        return {
            form: new Form({
                bookmarkTemplateId: 0,
                address: '',
                userId: this.$store.getters.user.id
            }),
            bookmarkTemplate: null
        };
    },

    computed: {
        formattedTagList() {
            if (!this.bookmarkTemplate || !this.bookmarkTemplate.tags || this.bookmarkTemplate.tags.length === 0) {
                return '{none}';
            }

            return this.bookmarkTemplate.tags.map((item) => {
                return item.name;
            }).reduce((accumulator, currentValue) => {
                return accumulator + (accumulator.length > 0 ? ', ' : '') + currentValue;
            });
        },
        formattedCollectionList() {
            if (!this.bookmarkTemplate || !this.bookmarkTemplate.collections || this.bookmarkTemplate.collections.length === 0) {
                return '{none}';
            }

            return this.bookmarkTemplate.collections.map((item) => {
                return item.name;
            }).reduce((accumulator, currentValue) => {
                return accumulator + (accumulator.length > 0 ? ', ' : '') + currentValue;
            });
        }
    },

    watch: {
        $route: 'routeChanged'
    },

    mounted() {
        this.$refs.address.focus();
        window.EventBus.$emit('pathChanged', constants.ROUTES.EXPRESS_BOOKMARK);
        this.form.bookmarkTemplateId = (this.$route.params.id ? parseInt(this.$route.params.id, 10) : null);

        this.loadTemplate();
    },

    methods: {
        loadTemplate() {
            if (!this.form.bookmarkTemplateId) {
                return;
            }

            const that = this;

            axios({
                method: constants.HTTP_ACTION.GET,
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARK_TEMPLATES
                    .concat('/')
                    .concat(that.form.bookmarkTemplateId),
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    if (response && response.data && response.data.payload) {
                        that.bookmarkTemplate = response.data.payload;
                    }
                })
                .catch((err) => {
                    that.form.recordErrors(err.response.data);
                    notifier.showErrorMessage(null, err);
                });
        },

        saveBookmark() {
            const that = this;

            axios({
                method: constants.HTTP_ACTION.POST,
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARKS,
                headers: { token: this.$store.getters.token },
                data: this.form.getData()
            })
                .then(() => {
                    that.$router.go(-1);
                })
                .catch((err) => {
                    // that.form.recordErrors(err.response.data);
                    notifier.showErrorMessage(null, err);
                });
        },

        routeChanged() {
            this.form.bookmarkTemplateId = parseInt(this.$route.params.id, 10);
            this.loadTemplate();
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .bookmark-template-wrapper {
        margin-bottom: 10px;
    }

    .formatted-tag-list {
        color: green;
        font-style: italic;
    }

    .formatted-collection-list {
        color: blue;
        font-style: italic;
    }

</style>

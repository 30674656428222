<template>
    <h2 class="sub-title">
        <slot></slot>
    </h2>
</template>

<script>
export default {
    name: 'PageSubTitle'
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .sub-title {
        font-size: 120%;
        font-weight: bold;
        margin-bottom: 10px;
    }

    @media (max-width: 700px) {
        .sub-title {
            margin: 5px 0 5px 0;
            text-align: center;
        }
    }
</style>

<template>
    <div class="collection-card" :class="(managing && selected ? 'selected-collection-card' : 'unselected-collection-card')" @click="selectCollection(collection)">
        <div class="collection-title">

            <div v-if="managing === false" class="three-cols">
                <i class="fas fa-shopping-bag" v-if="collection.favourite === false" title="Standard collection"></i>
                <i class="fas fa-star" v-if="collection.favourite === true" title="Favorite collection"></i>

                <router-link :to="collectionNameLink" class="horizontal-padding">
                    {{ formatName(collection) }}
                </router-link>

                <router-link :to="collectionEditLink">
                    <i class="far fa-edit"></i>
                </router-link>
            </div>

            <div v-if="managing === true">
                <i class="fas fa-shopping-bag" v-if="collection.favourite === false" title="Standard collection"></i>
                <i class="fas fa-star" v-if="collection.favourite === true" title="Favorite collection"></i>
                {{ collection.name }}
            </div>
        </div>
    </div>
</template>

<script>

import constants from '@/assets/js/constants';
import logger from '@/assets/js/logger';

export default {
    name: 'CollectionCard',

    props: ['collection', 'managing'],

    data() {
        return {
            selected: false
        };
    },

    computed: {
        collectionNameLink() {
            return `${constants.ROUTES.BOOKMARKS}?collectionId=${this.collection.id}`;
        },

        collectionEditLink() {
            return `${constants.ROUTES.COLLECTIONS}/${this.collection.id}`;
        }
    },

    created() {
        window.EventBus.$on(constants.EVENTS.CLEAR_COLLECTION_SELECTION, () => {
            logger.debug(this.$options.name, `Clearing collection selection for collection ID ${this.collection.id}`);
            this.selected = false;
        });

        window.EventBus.$on(constants.EVENTS.SELECT_ALL_COLLECTIONS, () => {
            if (!this.selected) {
                logger.debug(this.$options.name, `Selecting collection ID ${this.collection.id} through ${constants.EVENTS.SELECT_ALL_COLLECTIONS} event`);
                this.selected = true;
                this.$emit(constants.EVENTS.COLLECTION_SELECTED, this.collection.id);
            }
        });

        window.EventBus.$on(constants.EVENTS.UNSELECT_ALL_COLLECTIONS, () => {
            if (this.selected) {
                logger.debug(this.$options.name, `Unselecting collection ID ${this.collection.id} through ${constants.EVENTS.UNSELECT_ALL_COLLECTIONS} event`);
                this.selected = false;
                this.$emit(constants.EVENTS.COLLECTION_UNSELECTED, this.collection.id);
            }
        });
    },

    beforeDestroy() {
        logger.debug(this.$options.name, 'Unsubscribing from events: CLEAR_COLLECTION_SELECTION, SELECT_ALL_COLLECTIONS, UNSELECT_ALL_COLLECTIONS');
        window.EventBus.$off(constants.EVENTS.CLEAR_COLLECTION_SELECTION);
        window.EventBus.$off(constants.EVENTS.SELECT_ALL_COLLECTIONS);
        window.EventBus.$off(constants.EVENTS.UNSELECT_ALL_COLLECTIONS);
    },

    methods: {

        formatName(collection) {
            if (collection && collection.favourite) {
                return `${collection.name} (${collection.favOrder})`;
            }

            return collection.name;
        },

        selectCollection(collection) {
            if (!this.managing) {
                return;
            }

            logger.debug(this.$options.name, 'Collection ' + collection.id + ' (un)selected');

            this.selected = !this.selected;
            this.$emit((this.selected ? constants.EVENTS.COLLECTION_SELECTED : constants.EVENTS.COLLECTION_UNSELECTED), collection.id);
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .selected-collection-card {
        border: 2px solid #05a;
    }

    .unselected-collection-card {
        background-color: whitesmoke;
    }

    .collection-card {
        margin: 10px 10px;
        min-width: 150px;

        box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
        border-radius: 5px 5px 5px 5px;
    }

    .collection-card a {
        color: black;
    }

    .collection-card a:hover {
        color: #3273dc;
        transition: color 500ms;
    }

    .collection-title {
        margin: 5px;
        font-size: 1rem;
    }

    .horizontal-padding {
        margin: 0 7px;
    }

    .three-cols {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media (max-width: 700px) {
        .collection-card {
            width: 100%;
        }
    }

</style>

<template>
    <div v-if="$store.getters.authenticated" class="left-part">
        <main-menu></main-menu>
    </div>
</template>

<script>

import MainMenu from './MainMenu';

export default {
    name: 'LeftPart',

    components: {
        MainMenu
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .left-part {
        max-width: 250px;
        min-width: 250px;
        padding: 22px 10px 0 10px;
    }

    @media (max-width: 700px) {
        .left-part {
            max-width: max-content;
            min-width: 200px;
            padding: 22px 10px 0 10px;
        }
    }

</style>

import Swal from 'sweetalert2';
import logger from './logger';

function showBriefMessage(title, msg, type) {
    Swal.fire({
        title,
        text: msg,
        icon: type,
        timer: 1000
    })
        .catch(() => {
        });
}

function showErrorMessage(title, error) {
    let text;
    const EXPIRED_TITLE = 'Session Expired';
    const COMMON_API_ERROR = 'Cannot read property \'status\' of undefined';

    // TODO: This path needs more work
    if (error.response) {
        // Request made and server responded
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);

        if (error.response.data && error.response.data.message) {
            text = error.response.data.message;
        } else {
            text = JSON.stringify(error.response);
        }
    } else if (error.data) {
        text = error.data.message;
    } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        text = error.message;

        if (text === COMMON_API_ERROR) {
            text = 'An unexpected error occurred during communication with the server';
        }
    } else if (error && error.length > 0) {
        text = error;
    }

    if (text === undefined) {
        text = 'An error occurred';
    }

    if (error.response && error.response.status && error.response.status === 403) {
        title = EXPIRED_TITLE;
    } else if (error.data && error.data.status && error.data.status === 403) {
        title = EXPIRED_TITLE;
    }

    return Swal.fire((title || 'Oops!'), text, 'error');
}

// TODO: This function is obsolete and will be removed in the future.
function showSimpleErrorMessage(exception) {
    return Swal.fire('', exception.response.data.message, 'error');
}

function showInfoMessage(title, msg) {
    return Swal.fire((title || 'Sweet'), msg, 'info');
}

function showSuccessMessage(title, msg) {
    return Swal.fire((title || 'Sweet'), msg, 'success');
}

function showDeleteConfirmation(title, msg) {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: title || 'Are you sure?',
            html: msg,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff3860',
            cancelButtonColor: 'grey',
            confirmButtonText: 'Yes, delete it',
            focusCancel: true
        })
            .then((result) => {
                logger.debug('notifier.js', 'Sweetalert prompt result: ' + JSON.stringify(result));

                if (result && result.value) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function showGeneralConfirmation(title, msg, confirmButtonText) {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: title || 'Are you sure?',
            html: msg,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#118d04',
            cancelButtonColor: 'grey',
            confirmButtonText: confirmButtonText,
            focusCancel: true
        })
            .then((result) => {
                logger.debug('notifier.js', 'Sweetalert prompt result: ' + JSON.stringify(result));

                if (result && result.value) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

// -----------------------------------------------------------------------------
// Prompts
// -----------------------------------------------------------------------------

function prompt(title, inputLabel, isMandatory, inputHelp) {

    return new Promise((resolve, reject) => {
        // let inputValue = '<new name>';

        Swal.fire({
            title: title,
            input: 'text',
            inputLabel: inputLabel,
            // inputValue: inputValue,
            showCancelButton: true,
            icon: 'question',
            inputValidator: (value) => {
                if (isMandatory && !value) {
                    return inputHelp || 'Please specify a value';
                }
            }
        })
            .then(result => {
                logger.debug('notifier.js', 'Result: ' + JSON.stringify(result));

                if (result.isConfirmed) {
                    resolve(result.value);
                } else {
                    resolve();
                }
            })
            .catch(err => reject(err));
    });
}

// -----------------------------------------------------------------------------
// Toasters
// Check out: https://github.com/CodeSeven/toastr
// -----------------------------------------------------------------------------

function showToaster(type, title, msg) {
    window.toastr[type](msg, title);
}

function showInfoToaster(title, msg) {
    showToaster('info', title, msg);
}

function showSuccessToaster(title, msg) {
    showToaster('success', title, msg);
}

function showWarningToaster(title, msg) {
    showToaster('warning', title, msg);
}

function showErrorToaster(title, msg) {
    showToaster('error', title, msg);
}

// -----------------------------------------------------------------------------
// Module exports
// -----------------------------------------------------------------------------

export default {
    // showBriefMessage,
    showErrorMessage,
    showSimpleErrorMessage,
    showInfoMessage,
    showSuccessMessage,
    showDeleteConfirmation,
    showGeneralConfirmation,

    // Prompts
    prompt,

    // Toasters
    showInfoToaster,
    showSuccessToaster,
    showWarningToaster,
    showErrorToaster
};

<template>
    <div>
        <page-title>Save to Bookmark Pivot</page-title>

        <form @submit.prevent="saveLink" @keydown="form.errorWrapper.clear($event.target.name)">
            <div class="field">
                <label class="label">Address</label>
                <div class="control">
                    <input ref="address" v-model="form.address" name="address" class="input" type="text">
                </div>
                <form-error :field="'address'" :form="form"/>
            </div>
            <div class="field">
                <label class="label">Title</label>
                <div class="control">
                    <input v-model="form.title" class="input" type="text">
                </div>
            </div>
            <div class="field">
                <label class="label">Tags</label>
                <div class="control">
                    <multiselect
                        v-model="selectedTags"
                        :options="tags"
                        :multiple="true"
                        :searchable="true"
                        :loading="isLoadingTags"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="8"
                        :max-height="500"
                        :show-no-results="false"
                        :taggable="true"
                        label="name"
                        track-by="id"
                        placeholder="Search or add a tag"
                        open-direction="bottom"
                        @search-change="asyncFindTags"
                        @tag="addTag"/>
                </div>
            </div>

            <div class="field">
                <label class="label">Groups</label>
                <div class="control">
                    <multiselect
                        v-model="selectedGroups"
                        :options="groups"
                        :multiple="true"
                        :searchable="true"
                        :loading="isLoadingGroups"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="8"
                        :max-height="500"
                        :show-no-results="false"
                        :taggable="true"
                        label="name" track-by="id" placeholder="Search or add a group" open-direction="bottom" @search-change="asyncFindGroups" @tag="addGroup"/>
                </div>
            </div>
            <div class="field">
                <label class="label">Description</label>
                <div class="control">
                    <textarea v-model="form.notes" class="textarea"></textarea>
                </div>
            </div>

            <div class="field is-grouped">
                <div class="control">
                    <button id="save-button" class="button is-primary" type="submit">Save</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import FormError from '@/components/FormError';
import Multiselect from 'vue-multiselect';
import PageTitle from '@/components/PageTitle';
import notifier from '@/assets/js/notifier';
import Form from '@/assets/js/form';
import constants from '@/assets/js/constants';

export default {
    name: 'NewFullPage',

    components: {
        FormError,
        Multiselect,
        PageTitle
    },

    data() {
        return {
            tags: [],
            groups: [],
            selectedTags: [],
            selectedGroups: [],
            isLoadingTags: false,
            isLoadingGroups: false,
            form: new Form({})
        };
    },

    created() {
        this.form.userId = this.$store.getters.user.id;
        this.form.title = this.$route.query.title || '';
        this.form.address = this.$route.query.url || '';
        this.form.notes = this.$route.query.description || '';

        this.loadData();
    },

    mounted() {
        this.$refs.address.focus();
    },

    methods: {
        loadData() {
            const that = this;

            // Get user's tags
            // ---------------
            axios({
                method: 'GET',
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.TAGS_FOR_USER.replace('{userId}', this.$store.getters.user.id),
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        that.tags = response.data.sort((a, b) => {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });

            // Get user's groups
            // -----------------
            axios({
                method: 'GET',
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.GROUPS_FOR_USER.replace('{userId}', this.$store.getters.user.id),
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        that.groups = response.data.sort((a, b) => {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        saveLink() {
            const that = this;
            this.form.tags = this.selectedTags;
            this.form.groups = this.selectedGroups;

            axios({
                method: 'post',
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.BOOKMARKS,
                headers: { token: this.$store.getters.token },
                data: this.form.getData()
            })
                .then(() => {
                    notifier.showSuccessToaster('Saved', 'Link saved to Bookmark Pivot');

                    setTimeout(() => {
                        window.close();
                    }, 1500);
                })
                .catch((err) => {
                    that.form.recordErrors(err.response.data);
                    notifier.showErrorMessage(null, err);
                });
        },

        addTag(newTag) {
            const tag = {
                name: newTag,
                id: null
            };
            this.tags.push(tag);
            this.selectedTags.push(tag);
        },

        addGroup(newGroup) {
            const group = {
                name: newGroup,
                id: null
            };
            this.groups.push(group);
            this.selectedGroups.push(group);
        },

        asyncFindTags(query) {
            const that = this;
            this.isLoadingTags = true;
            console.log('query: ' + query);

            axios({
                method: 'GET',
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.TAGS_FOR_USER.replace('{userId}', this.$store.getters.user.id) + `?search=${query}`,
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    that.tags = [];
                    that.isLoadingTags = false;

                    if (response.data && response.data.length > 0) {
                        that.tags = response.data.sort((a, b) => {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                    that.isLoadingTags = false;
                });
        },

        asyncFindGroups(query) {
            const that = this;
            this.isLoadingGroups = true;
            console.log('query: ' + query);

            axios({
                method: 'GET',
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.GROUPS_FOR_USER.replace('{userId}', this.$store.getters.user.id) + `?search=${query}`,
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    that.groups = [];
                    that.isLoadingGroups = false;

                    if (response.data && response.data.length > 0) {
                        that.groups = response.data.sort((a, b) => {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                    that.isLoadingGroups = false;
                });
        }
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
    <div>
        <page-title>Share a Bookmark</page-title>

        <div class="subtitle">{{ bookmark.title }}</div>

        <form @submit.prevent>

            <div class="field">
                <label class="label">Existing Friends</label>
                <div class="control" @keydown.enter.prevent.self="">
                    <v-select v-model="selectedFriend"
                              :options="allFriends"
                              :disabled="disableFriendSelector"
                              class="thing-selector"
                              label="displayName"></v-select>
                </div>
            </div>

            <div class="field">
                <label class="label">New friend name</label>
                <div class="control has-icons-right">
                    <input ref="nameRef" v-model="newName" :disabled="selectedFriend" class="input" type="text">
                    <span class="icon is-right">
                        <i class="fas fa-user"></i>
                    </span>
                </div>
            </div>

            <div class="field">
                <label class="label">Their email address</label>
                <div class="control has-icons-right">
                    <input ref="emailRef" v-model="newEmail" :disabled="selectedFriend" class="input" type="text">
                    <span class="icon is-right">
                        <i class="fas fa-envelope"></i>
                    </span>
                </div>
            </div>

            <div class="field">
                <label class="label">Your message</label>
                <div class="control has-icons-right">
                    <textarea v-model="message" class="textarea" rows="4"></textarea>
                    <span class="icon is-right">
                        <i class="fas fa-comment-alt"></i>
                    </span>
                </div>
            </div>

            <div class="control-buttons">
                <div class="field is-grouped">
                    <div class="control">
                        <button id="save-button" class="button is-info" type="submit" @click="shareBookmark">Share</button>
                    </div>

                    <div class="control back-link">
                        <a @click="cancel">Go back</a>
                    </div>
                </div>
            </div>
        </form>

        <img :src="previewPath" class="preview-image" alt="">
    </div>
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import PageTitle from '@/components/PageTitle';
import notifier from '@/assets/js/notifier';
import constants from '@/assets/js/constants';
import validator from '@/assets/js/validation-helper';

const unavailImg = require('@/assets/images/unavailable.png');

export default {
    name: 'SharePage',

    components: {
        'v-select': vSelect,
        PageTitle
    },

    data() {
        return {
            bookmark: {
                id: null,
                address: null,
                title: null
            },
            allFriends: [],
            selectedFriend: null,
            newName: '',
            newEmail: '',
            message: ''
        };
    },

    computed: {
        previewPath() {
            if (this.bookmark.preview) {
                return window.bookmarkPivotConfig.previewsUrl + '/' + this.bookmark.preview;
            }

            return unavailImg;
        },

        disableFriendSelector() {
            let disable = false;

            if ((this.newName && this.newName.length > 0) || (this.newEmail && this.newEmail.length > 0)) {
                disable = true;
            }

            return disable;
        }
    },

    watch: {
        $route: 'loadData'
    },

    created() {
        this.loadData();
    },

    methods: {
        loadData() {
            const that = this;

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARKS + `/${this.$route.params.id}`,
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    if (response && response.data) {
                        that.bookmark = response.data.payload;
                    } else {
                        notifier.showErrorMessage(null, 'Unable to find bookmark');
                    }
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.FRIENDS_FOR_USER,
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    that.allFriends = response.data.payload;
                    that.allFriends.forEach((item) => {
                        item.displayName = item.name + ' - ' + item.email;
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        shareBookmark() {
            const errorList = this.validateSubmission();

            if (errorList && errorList.length > 0) {
                notifier.showErrorMessage(null, errorList[0]);
                return;
            }

            const that = this;
            const sharePayload = {
                bookmarkId: that.bookmark.id,
                friendId: that.selectedFriend ? that.selectedFriend.id : null,
                systemUserId: that.selectedFriend ? that.selectedFriend.systemUserId : null,
                name: that.selectedFriend ? that.selectedFriend.name : that.newName,
                email: that.selectedFriend ? that.selectedFriend.email : that.newEmail,
                message: that.message
            };

            axios({
                method: 'POST',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.SHARE_FOR_USER,
                data: sharePayload
            })
                .then(() => {
                    notifier.showSuccessMessage(null, 'The bookmark has been shared with <b>' + sharePayload.name + '</b> (' + sharePayload.email + ')')
                        .then(() => {
                            that.$router.go(-1);
                        });
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });
        },

        validateSubmission() {
            const errorList = [];

            if (!this.selectedFriend && !this.newName) {
                errorList.push('Please select or add a new friend');
            }

            if (!this.selectedFriend && !this.newEmail) {
                errorList.push('Please select a friend or specify a name and email address');
            }

            if (this.selectedFriend) {
                if (!validator.validateEmailAddress(this.selectedFriend.email)) {
                    errorList.push(`Invalid email address "${this.selectedFriend.email}"`);
                }
            } else {
                if (!validator.validateEmailAddress(this.newEmail)) {
                    errorList.push(`Invalid email address "${this.newEmail}"`);
                }
            }

            return errorList;
        },

        cancel() {
            this.$router.go(-1);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .control-buttons {
        display: flex;
        justify-content: space-between;
    }

    .back-link {
        margin-top: 5px;
    }

    .preview-image {
        box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
        border-radius: 5px 5px 5px 5px;
        margin: 20px 0 10px 0;
        width: 100%;
    }
</style>

<template>
    <div>
        <div class="collections-section">
            <page-title>Collections</page-title>

            <div class="button-bar">
                <router-link :to="newCollectionLink" class="button is-info">
                    <span class="icon is-right">
                        <i class="fas fa-plus"></i>
                    </span>
                    &nbsp; Add Collection
                </router-link>

                <a class="button is-default" @click="loadData">
                    <span class="icon is-right">
                        <i class="fas fa-sync-alt"></i>
                    </span>
                    &nbsp; Refresh
                </a>

                <a class="button is-default" :class="managing ? 'manage-mode' : ''" @click="manage">
                    <span class="icon is-right">
                        <i class="fas fa-wrench"></i>
                    </span>
                    &nbsp; Manage
                </a>

                <span class="field has-text-left">
                    <span class="select">
                        <select v-model="sortOrder" @change="sortChanged">
                            <option :value="sortConstants.ALPHABETIC.code">{{ sortConstants.ALPHABETIC.title }}</option>
                            <option :value="sortConstants.DATE_CREATED.code">{{ sortConstants.DATE_CREATED.title }}</option>
                        </select>
                    </span>
                </span>

            </div>
        </div>

        <div v-if="managing === true" class="management-button-group-wrapper">

            <div class="management-description">
                Manage your collections with bulk merge and delete functions
            </div>

            <div class="button-group">
                <a class="button is-primary" @click="doneManagingButtonClick">
                    <span class="icon is-right">
                        <i class="fas fa-external-link-alt"></i>
                    </span>
                    &nbsp; Done
                </a>

                <a class="button is-default" @click="selectAllButtonClick">
                    <span class="icon is-right">
                        <i class="far fa-check-square"></i>
                    </span>
                    &nbsp; Select All
                </a>

                <a class="button is-default" @click="unselectAllButtonClick">
                    <span class="icon is-right">
                        <i class="far fa-square"></i>
                    </span>
                    &nbsp; Unselect All
                </a>

                <a class="button is-default" @click="mergeButtonClick">
                    <span class="icon is-right">
                        <i class="far fa-object-group"></i>
                    </span>
                    &nbsp; Merge
                </a>


                <a class="button is-default" @click="editButtonClick">
                <span class="icon is-right">
                    <i class="far fa-edit"></i>
                </span>
                    &nbsp; Edit
                </a>


                <a class="button is-danger" @click="deleteCollectionsButtonClick">
                    <span class="icon is-right">
                        <i class="fas fa-sync-alt"></i>
                    </span>
                    &nbsp; Delete
                </a>
            </div>

            <div class="management-status">
                {{ selectedCollectionIds.length }} of {{ sortedCollections.length }} selected
            </div>

        </div>

        <div class="collections-column-view">
            <collection-card v-for="collection in sortedCollections"
                             :key="collection.id"
                             :collection="collection"
                             :managing="managing"
                             @collectionSelected="collectionSelected"
                             @collectionUnselected="collectionUnselected">
            </collection-card>
        </div>


        <!-- --------------------------------------------------------------- -->
        <!-- Update Modal                                                    -->
        <!-- --------------------------------------------------------------- -->

        <div id="bulk-collection-edit-modal" class="modal">
            <div class="modal-background" @click="hideEditModal"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Edit Collections</p>
                    <button class="delete" aria-label="close" @click="hideEditModal"></button>
                </header>
                <section class="modal-card-body">

                    <div class="field">
                        <label class="label">Parent Collection</label>
                        <div class="control" @keydown.enter.prevent.self="">
                            <v-select v-model="selectedCollection" :options="allCollections" class="thing-selector" label="name" taggable @search="asyncFindCollections"></v-select>
                        </div>
                    </div>

                    <div class="field">
                        <label class="checkbox">
                            <input id="removeParent" type="checkbox" v-model="removeParent">
                            Remove existing parent
                        </label>
                    </div>

                    <span class="field has-text-left">
                        <label class="label">Favourites</label>
                        <span class="select">
                            <select v-model="favouriteBooleanAction">
                                <option value="TRUE">Mark all as favourites</option>
                                <option value="FALSE">Remove favourites from all</option>
                                <option value="NONE">Don't change the current values</option>
                            </select>
                        </span>
                    </span>

                    <div style="height: 100px;"></div>

                </section>

                <footer class="modal-card-foot">
                    <button :class="{ 'is-loading': isSaving }" class="button is-info" @click="saveCollections">Save</button>
                    <button class="button" @click="hideEditModal">Cancel</button>
                </footer>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import PageTitle from '@/components/PageTitle';
import CollectionCard from '@/components/CollectionCard';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';
import logger from '@/assets/js/logger';

export default {
    name: 'CollectionsPage',

    components: {
        PageTitle,
        CollectionCard,
        'v-select': vSelect
    },

    data() {
        return {
            selectedCollectionIds: [],
            collectionSource: [],
            sortedCollections: [],
            sortConstants: constants.SORT_ORDER,
            sortOrder: this.$store.getters.userSettings[constants.USER_SETTINGS.COLLECTIONS_PAGE_SORT_ORDER] || constants.SORT_ORDER.ALPHABETIC.code,
            managing: false,

            // model variables
            isSaving: false,
            allCollections: [],
            selectedCollection: null,
            removeParent: false,
            favouriteBooleanAction: 'NONE'
        };
    },

    computed: {
        newCollectionLink() {
            return `${constants.ROUTES.COLLECTIONS}/new`;
        }
    },

    created: function () {
        this.loadData();

        window.EventBus.$on(constants.EVENTS.DONE_MANAGING_COLLECTIONS, () => {
            logger.debug(this.$options.name, 'Caught event ' + constants.EVENTS.DONE_MANAGING_COLLECTIONS);
            this.managing = false;
            this.selectedCollectionIds = [];
            window.EventBus.$emit(constants.EVENTS.CLEAR_COLLECTION_SELECTION);
        });
    },

    beforeDestroy() {
        logger.debug(this.$options.name, 'Unsubscribing from event ' + constants.EVENTS.DONE_MANAGING_COLLECTIONS);
        window.EventBus.$off(constants.EVENTS.DONE_MANAGING_COLLECTIONS);
    },

    mounted() {
        window.EventBus.$emit('pathChanged', constants.ROUTES.COLLECTIONS);
    },

    methods: {
        loadData() {
            const that = this;

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.COLLECTIONS
            })
                .then((response) => {
                    that.collectionSource = response.data.payload;
                    that.sortChanged();
                })
                .catch((e) => {
                    console.log(e);
                    notifier.showErrorMessage(null, e);
                });
        },

        sortChanged() {
            if (this.collectionSource && this.collectionSource.length > 0) {
                this.sortedCollections = this.collectionSource.sort((a, b) => {
                    if (this.sortOrder === constants.SORT_ORDER.ALPHABETIC.code) {
                        return (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
                    } else if (this.sortOrder === constants.SORT_ORDER.DATE_CREATED.code) {
                        return (a.dateCreated < b.dateCreated ? -1 : 1);
                    }

                    return 0;
                });

                if (this.$store.getters.userSettings[constants.USER_SETTINGS.COLLECTIONS_PAGE_SORT_ORDER] !== this.sortOrder) {
                    const userSettings = this.$store.getters.userSettings;
                    userSettings[constants.USER_SETTINGS.COLLECTIONS_PAGE_SORT_ORDER] = this.sortOrder;
                    this.$store.commit('userSettings', userSettings);
                }
            } else {
                this.sortedCollections = [];
            }
        },

        manage() {
            this.managing = !this.managing;

            if (!this.managing) {
                this.doneManaging();
            }
        },

        doneManaging() {
            logger.debug(this.$options.name, 'Done managing');
            this.managing = false;
            window.EventBus.$emit(constants.EVENTS.DONE_MANAGING_COLLECTIONS);
        },

        collectionSelected(id) {
            logger.debug(this.$options.name, `Collection ID ${id} selected`);
            this.selectedCollectionIds.push(id);
            logger.debug(this.$options.name, '  Selected collection IDs: ' + JSON.stringify(this.selectedCollectionIds));
        },

        collectionUnselected(id) {
            logger.debug(this.$options.name, `Collection ID ${id} unselected`);
            const index = this.selectedCollectionIds.indexOf(id);
            this.selectedCollectionIds.splice(index, 1);
            logger.debug(this.$options.name, '  Selected collection IDs: ' + JSON.stringify(this.selectedCollectionIds));
        },

        doneManagingButtonClick() {
            logger.debug(this.$options.name, 'triggerDoneManaging');
            window.EventBus.$emit(constants.EVENTS.DONE_MANAGING_COLLECTIONS);
        },

        selectAllButtonClick() {
            logger.debug(this.$options.name, 'triggerSelectAll');
            window.EventBus.$emit(constants.EVENTS.SELECT_ALL_COLLECTIONS);
        },

        unselectAllButtonClick() {
            logger.debug(this.$options.name, 'triggerUnselectAll');
            window.EventBus.$emit(constants.EVENTS.UNSELECT_ALL_COLLECTIONS);
        },

        mergeButtonClick() {
            const that = this;

            if (this.selectedCollectionIds.length === 0) {
                logger.debug(this.$options.name, 'No collections selected');
                return;
            }

            notifier
                .prompt('Merge Collections', 'What is the name of the new collection?', true, 'Please specify a new collection name')
                .then(newCollectionName => {
                    logger.debug(that.$options.name, 'New collection name: ' + JSON.stringify(newCollectionName));

                    if (newCollectionName) {
                        this.mergeCollectionsForReal(newCollectionName);
                    } else {
                        logger.debug(that.$options.name, 'Collection merge aborted by user');
                    }
                })
                .catch(err => logger.warn(that.$options.name, JSON.stringify(err)));
        },

        mergeCollectionsForReal(newCollectionName) {
            const that = this;
            const payload = {
                ids: this.selectedCollectionIds,
                name: newCollectionName
            };

            axios({
                method: 'POST',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BULK_COLLECTIONS_MERGE,
                data: payload
            })
                .then(() => {
                    logger.info(this.$options.name, `Collection merge of ${that.selectedCollectionIds.length} collection(s) successful`)
                    that.doneManagingButtonClick();
                    that.loadData();
                    window.EventBus.$emit(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS); // Set the collections in the top part
                })
                .catch((err) => {
                    logger.warn(this.$options.name, 'Error on collection merge API call: ' + JSON.stringify(err));
                    notifier.showErrorMessage(null, err);
                });
        },

        editButtonClick() {
            logger.debug(this.$options.name, 'edit button clicked');

            if (this.selectedCollectionIds.length > 0) {
                this.showEditModal();
            } else {
                logger.debug(this.$options.name, 'No collections selected');
            }
        },

        deleteCollectionsButtonClick() {
            if (this.selectedCollectionIds.length > 0) {
                logger.info(this.$options.name, 'Deleting collection IDs: ' + JSON.stringify(this.selectedCollectionIds));
                this.confirmDeletion();
            } else {
                logger.debug(this.$options.name, 'No collections selected');
            }
        },

        confirmDeletion() {
            const that = this;

            notifier.showDeleteConfirmation(null, `Delete ${this.selectedCollectionIds.length} collections?`)
                .then((confirmed) => {
                    logger.debug(this.$options.name, 'Delete confirmation result: ' + JSON.stringify(confirmed));

                    if (confirmed) {
                        logger.debug(this.$options.name, 'Deletion confirmed');
                        that.deleteCollectionsForReal();
                    } else {
                        logger.debug(this.$options.name, 'Deletion cancelled');
                    }
                })
                .catch((err) => {
                    logger.warn(this.$options.name, `Exception on delete confirmation: ${JSON.stringify(err)}`);
                });
        },

        deleteCollectionsForReal() {
            const that = this;
            const payload = { ids: this.selectedCollectionIds };

            axios({
                method: 'DELETE',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BULK_COLLECTIONS,
                data: payload
            })
                .then(() => {
                    logger.info(this.$options.name, `Bulk deletion of ${that.selectedCollectionIds.length} collection(s) successful`)
                    that.doneManagingButtonClick();
                    that.loadData();
                    window.EventBus.$emit(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS); // Set the collections in the top part
                })
                .catch((err) => {
                    logger.warn(this.$options.name, 'Error on collection deletion API call: ' + JSON.stringify(err));
                    notifier.showErrorMessage(null, err);
                });
        },


        // ---------------------------------------------------------------------
        // Modal methods
        // ---------------------------------------------------------------------

        showEditModal() {
            const element = document.getElementById('bulk-collection-edit-modal');

            if (element) {
                // Reset model values
                this.selectedCollection = null;
                this.removeParent = false;
                this.favouriteBooleanAction = 'NONE';

                // Show the modal
                element.classList.add('is-active');

                // Populate the collections selection box
                this.asyncFindCollections('', () => {});
            }
        },

        hideEditModal() {
            const element = document.getElementById('bulk-collection-edit-modal');

            if (element) {
                element.classList.remove('is-active');
            }
        },

        asyncFindCollections(query, loading) {
            const that = this;
            loading(true);

            axios({
                method: 'GET',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.COLLECTIONS + `?search=${query}`
            })
                .then((response) => {
                    that.allCollections = [];
                    loading(false);

                    if (response.data && response.data.payload.length > 0) {
                        that.allCollections = response.data.payload.sort((a, b) => {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        });
                    }
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                    loading(false);
                });
        },

        saveCollections() {
            let that = this;
            this.isSaving = true;
            let parent = null;

            if (this.selectedCollection) {
                if (this.selectedCollection.name) {
                    parent = this.selectedCollection;
                } else {
                    parent = { id: 0, name: this.selectedCollection };
                }
            }

            const payload = {
                ids: this.selectedCollectionIds,
                parent: parent,
                removeParent: this.removeParent,
                favouriteBooleanAction: this.favouriteBooleanAction
            };

            logger.debug(this.$options.name, `payload: ${JSON.stringify(payload)}`);

            axios({
                method: 'PUT',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BULK_COLLECTIONS,
                data: payload
            })
                .then(() => {
                    that.isSaving = false;
                    logger.info(this.$options.name, `Bulk update of ${that.selectedCollectionIds.length} collection(s) successful`)
                    that.hideEditModal();
                    that.doneManagingButtonClick();
                    that.loadData();
                    window.EventBus.$emit(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS); // Set the collections in the top part
                })
                .catch((err) => {
                    that.isSaving = false;
                    logger.warn(this.$options.name, 'Error on bulk update API call: ' + JSON.stringify(err));
                    notifier.showErrorMessage(null, err);
                });
        }

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .collections-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .button-bar {
        display: flex;
        flex-wrap: wrap;
        padding-top: 22px;
    }

    .management-button-group-wrapper {
        background-color: whitesmoke;
        border: 1px solid lightgray;
        border-radius: 5px;
        /*margin: 10px 0;*/
        padding: 6px 12px;
    }

    .management-description {
        display: flex;
        justify-content: center;
        margin-bottom: 7px;
    }

    .management-status {
        display: flex;
        justify-content: center;
        margin-top: 7px;
    }

    .button-group {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .button-group a:not(:last-child) {
        margin-right: 10px;
    }

    .collections-column-view {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        margin-top: 20px;
    }

    .manage-mode {
        background-color: #E8F5E9;
    }

    /* The >>> thing helps this work in a scoped environment */
    .thing-selector >>> .vs__selected {
        background: lightskyblue;
    }

    @media (max-width: 700px) {
        .button-bar {
            padding-top: 0;
        }
    }

</style>

<template>
    <div id="app">
        <top-part/>

        <div class="body-section">
            <left-part/>

            <!-- TODO: Create "body-part" -->
            <div class="body-container">
                <router-view/>
            </div>
        </div>

        <bottom-part @showFeedbackModal="showFeedbackModal"/>

        <!-- --------------------------------------------------------------- -->
        <!-- Modals                                                          -->
        <!-- --------------------------------------------------------------- -->

        <div id="feedback-modal" class="modal">
            <div class="modal-background" @click="hideFeedbackModal"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Beta Program Feedback</p>
                    <button class="delete" aria-label="close" @click="hideFeedbackModal"></button>
                </header>
                <section class="modal-card-body">

                    <div class="field">
                        <label class="label">Type of Feedback</label>
                        <span class="select">
                            <select ref="feedbackType" v-model="feedbackType">
                                <option value="comment">General comment</option>
                                <option value="problem">I found a problem</option>
                            </select>
                        </span>
                    </div>

                    <div class="field">
                        <label for="feedbackBody" class="label">Message</label>
                        <div class="control">
                            <textarea id="feedbackBody" v-model="feedbackBody" class="textarea"></textarea>
                        </div>
                    </div>

                </section>
                <footer class="modal-card-foot">
                    <button :class="{ 'is-loading': isSendingFeedback }" class="button is-info" @click="sendFeedback">Send Feedback</button>
                    <button class="button" @click="hideFeedbackModal">Cancel</button>
                </footer>
            </div>
        </div>

        <div id="shortcut-modal" class="modal">
            <div class="modal-background" @click="hideShortcutModal"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Keyboard Shortcuts</p>
                    <button class="delete" aria-label="close" @click="hideShortcutModal"></button>
                </header>
                <section class="modal-card-body grid">
                    <div class="grid-column">
                        <h1>Navigation</h1>
                        <div><span class="shortcut">h</span> - home</div>
                        <div><span class="shortcut">s</span> - search</div>
                        <div><span class="shortcut">b</span> - bookmarks</div>
                        <div><span class="shortcut">c</span> - collections</div>
                        <div><span class="shortcut">t</span> - tags</div>
                        <div><span class="shortcut">r</span> - friends</div>
                        <div><span class="shortcut">m</span> - templates</div>
                    </div>
                    <div class="grid-column">
                        <h1>Creation</h1>
                        <div><span class="shortcut">nb</span> - bookmark</div>
                        <div><span class="shortcut">nc</span> - collection</div>
                        <div><span class="shortcut">nt</span> - tag</div>
                        <div><span class="shortcut">nf</span> - friend</div>
                        <div><span class="shortcut">nm</span> - template</div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" @click="hideShortcutModal">Close</button>
                </footer>
            </div>
        </div>

    </div>
</template>

<script>

import axios from 'axios';
import TopPart from '@/components/TopPart';
import LeftPart from '@/components/LeftPart';
import BottomPart from '@/components/BottomPart';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';

export default {
    name: 'App',

    components: {
        TopPart,
        LeftPart,
        BottomPart
    },

    data() {
        return {
            feedbackType: 'comment',
            feedbackBody: null,
            isSendingFeedback: false
        };
    },

    methods: {
        sendFeedback() {
            const that = this;
            const element = document.getElementById('feedback-modal');

            this.isSendingFeedback = true;

            const feedbackData = {
                title: (this.feedbackType === 'comment' ? 'General Feedback' : 'I Found a Problem'),
                body: this.feedbackBody,
                userId: this.$store.getters.user.id,
                userEmail: this.$store.getters.user.email,
                route: this.$route.fullPath
            };

            axios({
                method: constants.HTTP_ACTION.POST,
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.FEEDBACK,
                headers: { token: this.$store.getters.token },
                data: feedbackData
            })
                .then(() => {
                    that.isSendingFeedback = false;
                    notifier.showSuccessToaster(null, 'Thank you. Your feedback has been submitted.');

                    // Hide the modal box
                    if (element) {
                        element.classList.remove('is-active');
                    }

                    // Reset the subject and body
                    that.resetFeedbackForm();
                })
                .catch((e) => {
                    that.isSendingFeedback = false;
                    console.log(e);
                    notifier.showErrorMessage(null, e);
                });
        },

        showFeedbackModal() {
            const element = document.getElementById('feedback-modal');

            if (element) {
                element.classList.add('is-active');
            }

            this.$refs.feedbackType.focus();
        },

        hideFeedbackModal() {
            const element = document.getElementById('feedback-modal');

            if (element) {
                element.classList.remove('is-active');
            }

            this.resetFeedbackForm();
        },

        resetFeedbackForm() {
            this.feedbackType = 'comment';
            this.feedbackBody = null;
        },

        hideShortcutModal() {
            window.EventBus.$emit('hideShortcutModal');
        }
    }
};

</script>

<style>
    html, body {
        height: 100%;
        margin: 0;
        padding: 0;
    }

    a {
        color: #3273DC;
    }

    a:hover {
        color: #1333DC;
    }

    #app {
        /* font-family: 'Avenir', sans-serif, Helvetica, Arial; */
        /* font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        padding: 0;

        /* Do the sticky footer at the bottom dance */
        min-height: 100%;
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 100%;
    }

    .body-section {
        display: flex;
        flex-wrap: nowrap;
    }

    .body-container {
        margin: 0 20px 20px 10px;
        width: 100%;
    }

    .grid {
        display: flex;
        flex-wrap: wrap;
    }

    .grid-column {
        margin-right: 20px;
    }

    .grid-column h1 {
        font-size: 110%;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .button .icon:first-child:last-child {
        margin-right: 5px !important;
    }

    .shortcut {
        background-color: lightgray;
        border-radius: 5px;
        font-family: monospace;
        padding: 0 5px 0 5px;
    }

    @media (max-width: 700px) {
        body {
            margin: 0 !important;
            padding: 0 !important;
        }

        .body-section {
            display: block;
            margin-right: 20px;
        }
    }

</style>

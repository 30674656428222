<template>
    <div/>
</template>

<script>

import constants from '@/assets/js/constants';

export default {
    name: 'LogoutPage',

    created() {
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('token');

        this.$store.commit('user', null);
        this.$store.commit('token', null);
        this.$store.commit('favoriteGroups', []);

        // TODO: This needs testing
        this.$router.push(constants.ROUTES.DEFAULT);
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
    <div class="list-item" :class="(managing && selected ? 'selected-bookmark-card' : '')" @click="selectBookmark(bookmark)">
        <div class="item-container">
            <div>
                <a v-if="!managing" :href="bookmark.address" target="_blank" @click="clickCounter(bookmark)">
                    <div class="item-title">{{ bookmark.title }}</div>
                    <div class="item-address">{{ bookmark.address }}</div>
                </a>
                <div v-if="managing">
                    <div class="item-title">{{ bookmark.title }}</div>
                    <div class="item-address">{{ bookmark.address }}</div>
                </div>

                <div class="item-footer">
                    <div class="item-actions">
                        <router-link :to="shareBookmark">share</router-link>
                        |
                        <router-link :to="editBookmark">edit</router-link>
                        |
                        <a @click="deleteBookmark">delete</a>
                    </div>
                    <!--
                                            <div class="item-tags">
                                                <i class="fas fa-bookmark"></i>
                                                tag 1
                                                |
                                                tag 2
                                            </div>
                                            <div class="item-collections">
                                                <i class="fas fa-shopping-bag"></i>
                                                collection 1
                                                |
                                                collection 2
                                            </div>
                    -->
                    <div class="item-meta">
                        created on {{ formatCreationDate }}
                    </div>
                </div>
            </div>
            <div v-if="showPreview" class="item-preview">
                <a v-if="!managing" :href="bookmark.address" target="_blank" @click="clickCounter(bookmark)">
                    <img :src="previewPath" alt="Bookmark preview">
                </a>

                <div v-if="managing">
                    <img :src="previewPath" alt="Bookmark preview">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import notifier from '@/assets/js/notifier';
import logger from '@/assets/js/logger';
import constants from '@/assets/js/constants';
import restClient from '@/assets/js/rest-client';

const unavailImg = require('@/assets/images/unavailable.png');

export default {
    name: 'BookmarkCard',
    props: ['bookmark', 'previewWidth', 'managing', 'viewMode'],

    data() {
        return {
            selected: false
        };
    },

    computed: {
        formatCreationDate() {
            return moment(this.bookmark.dateCreated).format('YYYY-MM-DD HH:mm:ss');
        },

        previewPath() {
            if (this.bookmark.preview) {
                return window.bookmarkPivotConfig.previewsUrl + '/' + this.bookmark.preview;
            }

            return unavailImg;
        },

        shareBookmark() {
            return `${constants.ROUTES.SHARE}/${this.bookmark.id}`;
        },

        editBookmark() {
            return `${constants.ROUTES.BOOKMARKS}/${this.bookmark.id}`;
        },

        showPreview() {
            return this.viewMode !== constants.VIEW_MODE.LIST_NO_PREVIEW.code;
        }
    },

    created() {
        window.EventBus.$on(constants.EVENTS.CLEAR_BOOKMARK_SELECTION, () => {
            logger.debug(this.$options.name, `Clearing bookmark selection for bookmark ID ${this.bookmark.id}`);
            this.selected = false;
        });

        window.EventBus.$on(constants.EVENTS.SELECT_ALL_BOOKMARKS, () => {
            if (!this.selected) {
                logger.debug(this.$options.name, `Selecting bookmark ID ${this.bookmark.id} through SELECT_ALL_BOOKMARKS event`);
                this.selected = true;
                this.$emit(constants.EVENTS.BOOKMARK_SELECTED, this.bookmark.id);
            }
        });

        window.EventBus.$on(constants.EVENTS.UNSELECT_ALL_BOOKMARKS, () => {
            if (this.selected) {
                logger.debug(this.$options.name, `Unselecting bookmark ID ${this.bookmark.id} through UNSELECT_ALL_BOOKMARKS event`);
                this.selected = false;
                this.$emit(constants.EVENTS.BOOKMARK_UNSELECTED, this.bookmark.id);
            }
        });
    },

    beforeDestroy() {
        logger.debug(this.$options.name, 'Unsubscribing from events: CLEAR_BOOKMARK_SELECTION, SELECT_ALL_BOOKMARKS, UNSELECT_ALL_BOOKMARKS');
        window.EventBus.$off(constants.EVENTS.CLEAR_BOOKMARK_SELECTION);
        window.EventBus.$off(constants.EVENTS.SELECT_ALL_BOOKMARKS);
        window.EventBus.$off(constants.EVENTS.UNSELECT_ALL_BOOKMARKS);
    },

    methods: {
        clickCounter(bookmark) {
            restClient.countBookmarkClick(bookmark.id);
        },

        selectBookmark(bookmark) {
            if (!this.managing) {
                return;
            }

            logger.debug(this.$options.name, 'Bookmark ' + bookmark.id + ' (un)selected');

            this.selected = !this.selected;
            this.$emit((this.selected ? constants.EVENTS.BOOKMARK_SELECTED : constants.EVENTS.BOOKMARK_UNSELECTED), bookmark.id);
        },

        deleteBookmark(event) {
            const that = this;
            event.preventDefault();
            logger.debug(this.$options.name, '"deleteBookmark" triggered');

            notifier.showDeleteConfirmation(null, this.bookmark.title)
                .then((confirmed) => {
                    logger.debug(this.$options.name, 'Delete confirmation result: ' + JSON.stringify(confirmed));

                    if (confirmed) {
                        logger.debug(this.$options.name, 'Deletion confirmed');
                        that.deleteBookmarkForReal();
                    } else {
                        logger.debug(this.$options.name, 'Deletion cancelled');
                    }
                })
                .catch((err) => {
                    logger.warn(this.$options.name, `Exception on delete confirmation: ${JSON.stringify(err)}`);
                });
        },

        deleteBookmarkForReal() {
            const that = this;

            axios({
                method: 'DELETE',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARKS + `/${this.bookmark.id}`
            })
                .then(() => {
                    logger.info(this.$options.name, 'Bookmark deleted')
                    that.$emit(constants.EVENTS.REFRESH);
                    window.EventBus.$emit(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS);
                })
                .catch((err) => {
                    logger.warn(this.$options.name, JSON.stringify(err));
                    notifier.showErrorMessage(null, err);
                });
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .list-item {
        box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
        border-radius: 5px 5px 5px 5px;
        margin: 20px 10px 0 10px;
        background-color: whitesmoke;
        padding: 5px;
        /*cursor: pointer;*/
    }

    .list-item a {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

    .list-item a:hover {
        color: #3273dc;
        transition: color 500ms;
    }

    .item-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .item-title {
        font-size: 1.2rem;
        font-weight: bold;
    }

    .item-preview img {
        height: 75px;
    }

    .item-actions a {
        color: #1349a3;
    }

    .item-actions a:hover {
        color: #5b91ea;
        transition: color 500ms;
    }

    .item-footer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 5px;
    }

    .item-tags {
        background-color: #e4eee4;
        border: 1px solid green;
        border-radius: 5px;
        padding: 0 5px;
    }

    .item-tags .fas {
        margin-right: 7px;
    }

    .item-collections {
        background-color: #d5e5ea;
        border: 1px solid blue;
        border-radius: 5px;
        padding: 0 5px;
    }

    .item-collections .fas {
        margin-right: 7px;
    }

    .selected-bookmark-card {
        border: 2px solid #05a;
    }


    @media (max-width: 1280px) {
        .item-preview img {
            display: none;
        }
    }

</style>

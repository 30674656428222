<template>
    <div>
        <page-title>Invite a Friend</page-title>

        <div class="page-help">
            <h1>How it works</h1>

            <p>
                If you have referral codes, then invite friends by providing their email addresses below.
                They will then receive an email from Bookmark Pivot with an option to sign up using the
                code associated with their email address.
            </p>

            <p>
                In the event where the email got lost or was blocked by SPAM filters, you can copy
                the code, using the copy icon, and forward that to your friend. They can then
                redeem the code on the sign-up page to receive the appropriate benefit.
            </p>
        </div>

        <form @submit.prevent>

            <table class="table is-fullwidth">
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Details</th>
                        <th>Invitation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="code in codes" :key="code.id">
                        <td>
                            <span :class="{ 'code-redeemed': code.dateRedeemed }" class="code">{{ code.code }}</span>
                            &nbsp;
                            <a v-if="!code.dateRedeemed" title="Copy link address" class="link-copy-icon" @click="copyCode(code.code, code.title)">
                                <i class="fas fa-clipboard"></i>
                            </a>
                            <br/>
                            issued on {{ formatDate(code.dateCreated) }}
                            <br/>
                            expires on {{ formatDate(code.validUntil) }}
                        </td>
                        <td>
                            <b>{{ code.title }}</b>
                            <div>receiver gets {{ code.subscriptionLength }} months free on <i>{{ code.subPlanName }}</i> plan</div>
                            <div v-if="code.kickbackSubscriptionLength">
                                you get {{ code.kickbackSubscriptionLength }} month{{ code.kickbackSubscriptionLength === 1 ? '' : 's' }} extended subscription
                            </div>
                        </td>
                        <td>
                            <div class="field" style="margin: 0;">
                                <div class="control">
                                    <input v-model="code.toEmail" :disabled="code.dateSent" type="text" class="input" placeholder="email address">
                                </div>
                            </div>

                            <div :class="{ invited: code.toEmail, joined: code.dateRedeemed } ">
                                {{ code.dateRedeemed ? 'joined on ' + formatDateTime(code.dateRedeemed) : (formatDateTime(code.dateSent) ? 'invited on ' + formatDateTime(code.dateSent) : '') }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="control-buttons">
                <div class="field is-grouped">
                    <div class="control">
                        <button v-if="codes.length > 0" class="button is-info" type="submit" @click="sendInvites">Send Invitations</button>
                    </div>
                </div>
            </div>

            <!-- Hide this little guy in the right bottom corner. You should never see it. -->
            <input v-show="false" id="copier" type="text" style="display: none; position: fixed; bottom: 0; right: 0; width: 1em;">

        </form>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import PageTitle from '@/components/PageTitle';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';
import validator from '@/assets/js/validation-helper';

export default {
    name: 'InvitationCodesPage',

    components: {
        PageTitle
    },

    data() {
        return {
            codes: []
        };
    },

    mounted() {
        window.EventBus.$emit('pathChanged', constants.ROUTES.INVITE_FRIEND);
        this.loadVouchers();
    },

    methods: {
        formatDateTime(theDate) {
            if (theDate) {
                return moment(theDate).format(constants.DATE_TIME_FORMAT);
            }

            return '';
        },

        formatDate(theDate) {
            if (theDate) {
                return moment(theDate).format(constants.DATE_FORMAT);
            }

            return '';
        },

        loadVouchers() {
            const that = this;

            axios({
                method: constants.HTTP_ACTION.GET,
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.VOUCHERS_FOR_USER.replace('{userId}', this.$store.getters.user.id),
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    that.codes = response.data;
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });
        },

        copyCode(theCode, title) {
            const copier = document.getElementById('copier');

            if (copier) {
                copier.style.display = 'block';
                copier.value = process.env.VUE_APP_APP_ROUTE_BASE + constants.ROUTES.SIGN_UP + '?plan=paid&code=' + theCode;
                copier.select();
                const success = document.execCommand('Copy');
                copier.style.display = 'none';

                if (success) {
                    notifier.showInfoToaster('Copied', 'Link to <b><i>' + title + '</i></b> code copied to clipboard');
                } else {
                    notifier.showErrorToaster('Oops', 'Link copy failed');
                }
            } else {
                console.log('Unable to find "copier"');
            }
        },

        sendInvites() {
            const that = this;
            const errors = this.validateInvites();

            if (errors && errors.length > 0) {
                notifier.showErrorMessage(null, errors);
                return;
            }

            axios({
                method: constants.HTTP_ACTION.PUT,
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.SEND_INVITATIONS_FOR_USER.replace('{userId}', this.$store.getters.user.id),
                headers: { token: this.$store.getters.token },
                data: that.codes
            })
                .then(() => {
                    that.loadVouchers();
                    notifier.showSuccessMessage('Success', 'Invitations have been sent');
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });
        },

        validateInvites() {
            const errors = [];
            let atLeastOneInvitation = false;
            let numInvited = 0;

            if (this.codes && this.codes.length > 0) {
                for (let i = 0; i < this.codes.length; i++) {
                    if (this.codes[i].toEmail && !this.codes[i].dateSent) {
                        atLeastOneInvitation = true;

                        if (!validator.validateEmailAddress(this.codes[i].toEmail)) {
                            errors.push('Invalid email address "' + this.codes[i].toEmail + '"');
                        }
                    } else if (this.codes[i].toEmail) {
                        numInvited++;
                    }
                }

                if (numInvited === this.codes.length) {
                    errors.push('You have used all your invitations');
                } else if (!atLeastOneInvitation) {
                    errors.push('Give us at least one email address');
                }
            }

            return errors;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .page-help {
        border: 1px solid #fffb68;
        background-color: #fffffa;
        padding: 10px;
        margin-bottom: 10px;
    }

    .page-help h1 {
        font-size: 1.5em;
    }

    .page-help p {
        margin-top: 10px;
    }

    .joined {
        color: #057731 !important;
    }

    .invited {
        color: rgb(26, 63, 184);
    }

    .code {
        font-weight: bold;
    }

    .code-redeemed {
        color: gray;
    }

    .link-copy-icon {
        color: black;
        cursor: hand;
    }
</style>

<template>
    <div style="padding-top: 22px;">Oops! We seem to have misplaced that one.</div>
</template>

<script>

export default {
    name: 'Sys404Page'
};

</script>

<style scoped>
</style>

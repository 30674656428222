<template>
    <section class="hero">
        <div class="hero-body">
            <div class="container has-text-centered">
                <div class="form-container">
                    <h3 class="title">Sign Up</h3>
                    <p class="subtitle has-text-grey">Welcome aboard</p>
                    <div class="box">
                        <figure class="avatar">
                            <img :src="logo" alt="">
                        </figure>
                        <form @submit.prevent @keydown="form.errorWrapper.clear($event.target.name)">
                            <div class="field">
                                <label class="label has-text-left">Email</label>
                                <div class="control">
                                    <input id="email" ref="email" v-model="form.email" name="email" class="input is-large" type="text" maxlength="255">
                                </div>
                                <form-error :field="'email'" :form="form" class="has-text-left"/>
                            </div>

                            <div class="field">
                                <label class="label has-text-left">Password</label>
                                <div class="control">
                                    <input id="password" v-model="form.password" name="password" class="input is-large" type="password" maxlength="255">
                                </div>
                                <form-error :field="'password'" :form="form" class="has-text-left"/>
                            </div>

                            <div class="field">
                                <label class="label has-text-left">Confirm Password</label>
                                <div class="control">
                                    <input id="password2" v-model="form.password2" class="input is-large" type="password" maxlength="255">
                                </div>
                                <!-- <p class="help is-danger is-size-6-desktop is-size-7-mobile has-text-left" v-if="passwordMismatch">Passwords do not match</p> -->
                            </div>

                            <button type="submit" class="button is-block is-info is-large" style="width: 100%;" @click="signUp">Continue</button>
                        </form>
                    </div>

                    <p>
                        <a @click="forgotPassword">Forgot Password</a>
                        &nbsp;·&nbsp;
                        <a href="#/login">Log In</a>
                    </p>

                    <p>
                        <a @click="resend">Resend activation link</a>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FormError from '@/components/FormError';
import PageTitle from '@/components/PageTitle';
import notifier from '@/assets/js/notifier';
import constants from '@/assets/js/constants';
import Form from '@/assets/js/form';

const profileImage = require('@/assets/images/profile-01.png');

export default {

    name: 'SignUpPage',

    components: {
        FormError,
        PageTitle
    },

    data() {
        return {
            subPlans: [],
            voucher: null,

            form: new Form({
                email: '',
                password: '',
                password2: ''
                // referralCode: ''
            })
        };
    },

    computed: {
        invalidEmail() {
            return !this.form.email || this.form.email.length === 0;
        },
        invalidPassword() {
            return !this.form.password || this.form.password.length === 0;
        },
        logo() {
            return profileImage;
        }
    },

    mounted() {
        if (this.$store.getters.authenticated) {
            this.$router.push(constants.ROUTES.DEFAULT);
            return;
        }

        // This will typically happen when we come from the sign up confirmation page. Maybe the server raised an error?
        if (this.$store.getters.signUp) {
            const signUp = this.$store.getters.signUp;
            this.form.email = signUp.email;
            this.form.password = signUp.password;
            this.form.password2 = signUp.password;
        }

        // Give focus to this element
        this.$refs.email.focus();

        // Clear the select menu item
        window.EventBus.$emit('pathChanged', constants.ROUTES.NONE);
    },

    methods: {
        signUp() {
            const that = this;
            const errors = this.validateUser();

            console.log('After validating user. errors: %j', errors);

            if (errors && Object.keys(errors).length > 0) {
                this.form.recordErrors(errors);
                notifier.showErrorMessage(null, errors);
                return;
            }

            const payload = this.form.getData();
            payload.password = this.form.password;
            // payload.password = hashPassword(this.form.password);
            // payload.password2 = hashPassword(this.form.password2); // What will Death Star do with this?

            delete payload.password2;
            that.$store.commit('signUp', payload);
            that.$router.push(constants.ROUTES.SIGN_UP_CONFIRMATION);
        },

        validateUser() {
            const errors = {};

            if (!this.form.email) {
                errors.email = ['Email address is required'];
            }

            if (!this.form.password) {
                errors.password = ['Password is required'];
            }

            if (this.form.password !== this.form.password2) {
                console.log('Validating passwords now');

                if (errors.password) {
                    errors.password.push('Passwords do not match');
                } else {
                    errors.password = ['Passwords do not match'];
                }
            }

            return errors;
        },

        forgotPassword() {
            this.$router.push(constants.ROUTES.FORGOT_PASSWORD + '?p=' + this.form.email);
        },

        resend() {
            this.$router.push(constants.ROUTES.SIGN_UP_RESEND + '?p=' + this.form.email);
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    html, body {
        font-family: 'Open Sans', serif;
        font-size: 14px;
        font-weight: 300;
    }

    .box {
        margin-top: 5rem;
    }

    .avatar {
        margin-top: -70px;
        padding-bottom: 20px;
    }

    .avatar img {
        padding: 5px;
        background: #fff;
        border-radius: 50%;
        -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
        box-shadow: 0 2px 3px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
        width: 128px;
    }

    .form-container {
        min-width: 250px;
        max-width: 375px;
        margin: auto;
    }

    input {
        font-weight: 300;
    }

    p {
        font-weight: 700;
    }

    p.subtitle {
        padding-top: 1rem;
    }

    a {
        color: #7d7d7d;
    }

    a:hover {
        color: #7d7d7d;
    }
</style>

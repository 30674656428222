<template>
    <div>
        <page-title>{{ form.name }}</page-title>

        <form @submit.prevent @keydown="form.errorWrapper.clear($event.target.name)">

            <div class="field">
                <label for="name" class="label">Name</label>
                <div class="control">
                    <input id="name" ref="nameRef" v-model="form.name" name="name" class="input" type="text">
                </div>
                <form-error :field="'name'" :form="form"/>
            </div>

            <div class="field">
                <label for="email" class="label">Email</label>
                <div class="control">
                    <input id="email" ref="emailRef" v-model="form.email" name="email" class="input" type="text">
                </div>
                <form-error :field="'email'" :form="form"/>
            </div>

            <!-- TODO: Maybe create a component for this -->
            <div class="control-buttons">
                <div class="field is-grouped">
                    <div class="control">
                        <button id="save-button" class="button is-info" type="submit" @click="saveFriend">Save</button>
                    </div>

                    <div class="control back-link">
                        <a @click="cancel">Go back</a>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button id="delete-button" class="button is-danger" @click="deleteFriend">Delete</button>
                    </div>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
import axios from 'axios';
import 'vue-select/dist/vue-select.css';
import PageTitle from '@/components/PageTitle';
import FormError from '@/components/FormError';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';
import logger from '@/assets/js/logger';
import Form from '@/assets/js/form';

export default {
    name: 'FriendPage',

    components: {
        FormError,
        PageTitle
    },

    data() {
        return {
            form: new Form({
                id: null,
                name: '',
                email: ''
            })
        };
    },

    watch: {
        $route: 'loadData'
    },

    created: function () {
        this.loadData();
    },

    mounted() {
        window.EventBus.$emit('pathChanged', constants.ROUTES.FRIENDS);
        this.$refs.nameRef.focus();
    },

    methods: {
        loadData() {
            const that = this;

            if (this.$route.params.id && this.$route.params.id !== 'new') {
                axios({
                    method: constants.HTTP_ACTION.GET,
                    url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.FRIENDS + '/' + this.$route.params.id
                })
                    .then((response) => {
                        that.form = new Form(response.data.payload);
                    })
                    .catch((err) => {
                        notifier.showSimpleErrorMessage(err);
                    });
            } else {
                this.form.id = null;
                this.form.name = '';
                this.form.email = '';
            }
        },

        saveFriend() {
            const that = this;
            const thingToSave = this.form.getData();
            thingToSave.userId = this.$store.getters.user.id;

            axios({
                method: (this.form.id ? constants.HTTP_ACTION.PUT : constants.HTTP_ACTION.POST),
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.FRIENDS,
                data: thingToSave
            })
                .then(() => {
                    console.log(`Pushing ${constants.ROUTES.FRIENDS} to $router`);
                    that.$router.push(constants.ROUTES.FRIENDS);
                })
                .catch((err) => {
                    that.form.recordErrors(err.response.data.payload);
                    notifier.showErrorMessage(null, err);
                });
        },

        deleteFriend() {
            notifier.showDeleteConfirmation(null, this.form.name)
                .then((confirmed) => {

                    if (confirmed) {
                        logger.debug(this.$options.name, 'Deletion confirmed');
                        this.deleteFriendForReal();
                    } else {
                        logger.debug(this.$options.name, 'Deletion cancelled');
                    }
                })
                .catch(() => {
                    logger.warn(this.$options.name, `Exception on delete confirmation: ${JSON.stringify(err)}`);
                });
        },

        deleteFriendForReal() {
            const that = this;

            axios({
                method: 'DELETE',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.FRIENDS + `/${this.form.id}`
            })
                .then(() => {
                    that.$router.push(constants.ROUTES.FRIENDS);
                })
                .catch((err) => {
                    notifier.showSimpleErrorMessage(err);
                });
        },

        cancel() {
            this.$router.go(-1);
            return false;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .control-buttons {
        display: flex;
        justify-content: space-between;
    }

    .back-link {
        margin-top: 5px;
    }
</style>

<template>
    <section class="hero">
        <div class="hero-body">
            <div class="container has-text-centered">
                <div class="column is-4 is-offset-4">
                    <h3 class="title">Change Your Password</h3>
                    <p v-if="getScreenName" class="subtitle has-text-grey">Welcome back, {{ getScreenName }}</p>
                    <div class="box">
                        <figure class="avatar">
                            <img :src="logo" alt="">
                        </figure>
                        <form @submit.prevent="changePassword">
                            <input type="submit" hidden/>

                            <div class="field">
                                <label class="label has-text-left">Enter your new password:</label>
                                <div class="control">
                                    <input ref="password" v-model="password" class="input is-large" type="password" placeholder="password">
                                </div>
                            </div>

                            <div class="field">
                                <label class="label has-text-left">Confirm your new password:</label>
                                <div class="control">
                                    <input v-model="password2" class="input is-large" type="password" placeholder="password">
                                </div>
                            </div>

                            <a class="button is-block is-info is-large" @click="changePassword">Change Password</a>
                        </form>
                    </div>

                    <p>
                        <a href="/#subscription-section-anchor">Sign Up</a>
                        &nbsp;·&nbsp;
                        <a href="#/login">Log In</a>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import JSSHA from 'jssha';
import notifier from '@/assets/js/notifier';
import constants from '@/assets/js/constants';

const profileImage = require('@/assets/images/profile-01.png');

function hashPassword(pwd) {
    const shaObj = new JSSHA('SHA-512', 'TEXT');
    shaObj.update(pwd);
    return shaObj.getHash('HEX');
}

export default {

    name: 'RecoverPasswordPage',

    data: () => ({
        password: null,
        password2: null,
        screenName: null
    }),

    computed: {
        getScreenName() {
            return this.screenName;
        },

        logo() {
            return profileImage;
        }
    },

    mounted() {
        const that = this;

        if (this.$route.params.token) {
            console.log('Submitted token: ' + this.$route.params.token);

            axios({
                method: 'POST',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.VALIDATE_PASSWORD_TOKEN,
                data: { token: this.$route.params.token }
            })
                .then((response) => {
                    console.log('Token checks out');
                    that.screenName = response.data.screenName;
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });
        } else {
            notifier.showErrorMessage(null, 'Invalid password recovery link');
        }

        // Give focus to this element
        this.$refs.password.focus();
    },

    methods: {
        changePassword() {
            this.validatePasswords()
                .then(() => {
                    return axios({
                        method: 'POST',
                        url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.RECOVER_PASSWORD,
                        data: { token: this.$route.params.token, password: hashPassword(this.password) }
                    });
                })
                .then(() => {
                    notifier.showSuccessMessage('Password Changed', '');
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });
        },

        validatePasswords() {
            return new Promise((resolve, reject) => {
                if (!this.password) {
                    return reject(new Error('Password is required'));
                }

                if (this.password !== this.password2) {
                    return reject(new Error('Passwords do not match'));
                }

                return resolve();
            });
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    html, body {
        font-family: 'Open Sans', serif;
        font-size: 14px;
        font-weight: 300;
    }

    .hero .nav, .hero.is-success .nav {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .box {
        margin-top: 5rem;
    }

    .avatar {
        margin-top: -70px;
        padding-bottom: 20px;
    }

    .avatar img {
        padding: 5px;
        background: #fff;
        border-radius: 50%;
        -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
        box-shadow: 0 2px 3px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
        width: 128px;
    }

    input {
        font-weight: 300;
    }

    p {
        font-weight: 700;
    }

    p.subtitle {
        padding-top: 1rem;
    }

    a {
        color: #7d7d7d;
    }

    a:hover {
        color: #7d7d7d;
    }

</style>

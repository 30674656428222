<template>
    <div>
        <page-title>{{ form.name }}</page-title>

        <form @submit.prevent @keydown="form.errorWrapper.clear($event.target.name)">

            <div class="field">
                <label for="tagName" class="label">Name</label>
                <div class="control">
                    <input id="tagName" ref="name" v-model="form.name" name="name" class="input" type="text">
                </div>
                <form-error :field="'name'" :form="form"/>
            </div>

            <div class="field">
                <label for="specialTagName" class="label">Special behavior</label>
                <div class="field has-text-left">
                    <span id="specialTagName" class="select">
                        <select v-model="form.specialTagName">
                            <option value="null">No special behavior</option>
                            <option value="browser-ext">Tag new bookmarks created from browser extensions with this tag</option>
                        </select>
                    </span>
                </div>
                <div class="help">Assign special behavior to this tag</div>
            </div>

            <!-- TODO: Maybe create a component for this -->
            <div class="control-buttons">
                <div class="field is-grouped">
                    <div class="control">
                        <button id="save-button" class="button is-info" type="submit" @click="saveTag">Save</button>
                    </div>

                    <div class="control back-link">
                        <a @click="cancel">Go back</a>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button id="delete-button" class="button is-danger" @click="deleteTag">Delete</button>
                    </div>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
import axios from 'axios';
import PageTitle from '@/components/PageTitle';
import FormError from '@/components/FormError';
import constants from '@/assets/js/constants';
import notifier from '@/assets/js/notifier';
import logger from '@/assets/js/logger';
import Form from '@/assets/js/form';

export default {
    name: 'TagPage',

    components: {
        FormError,
        PageTitle
    },

    data() {
        return {
            form: new Form({
                id: null,
                name: null,
                specialTagName: null,
                userId: null
            })
        };
    },

    watch: {
        $route: 'loadData'
    },

    created: function () {
        this.loadData();
    },

    mounted() {
        window.EventBus.$emit('pathChanged', constants.ROUTES.TAGS);
        this.$refs.name.focus();
    },

    methods: {
        loadData() {
            const that = this;

            if (this.$route.params.id && this.$route.params.id !== 'new') {
                axios({
                    method: constants.HTTP_ACTION.GET,
                    url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.TAGS + `/${this.$route.params.id}`,
                    headers: { token: this.$store.getters.token }
                })
                    .then((response) => {
                        that.form = new Form(response.data.payload);
                    })
                    .catch((err) => {
                        notifier.showErrorMessage(null, err);
                    });
            } else {
                this.form.id = null;
                this.form.userId = this.$store.getters.user.id;
            }
        },

        saveTag() {
            const that = this;
            const tagToSave = this.form.getData();
            tagToSave.userId = this.$store.getters.user.id;
            const url = window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.TAGS;

            axios({
                method: (this.form.id ? constants.HTTP_ACTION.PUT : constants.HTTP_ACTION.POST),
                url: url,
                data: tagToSave
            })
                .then(() => {
                    that.$router.push(constants.ROUTES.TAGS);
                    return false;
                })
                .catch((err) => {
                    // that.form.recordErrors(err.response.data);
                    notifier.showSimpleErrorMessage(err);
                });
        },

        cancel() {
            this.$router.go(-1);
            return false;
        },

        deleteTag() {
            notifier.showDeleteConfirmation(null, `Delete tag <b>${this.form.name}</b>?`)
                .then((confirmed) => {

                    if (confirmed) {
                        logger.debug(this.$options.name, 'Deletion confirmed');
                        this.deleteTagForReal();
                    } else {
                        logger.debug(this.$options.name, 'Deletion cancelled');
                    }
                })
                .catch(() => {
                    logger.warn(this.$options.name, `Exception on delete confirmation: ${JSON.stringify(err)}`);
                });
        },

        deleteTagForReal() {
            const that = this;

            axios({
                method: constants.HTTP_ACTION.DELETE,
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.TAGS + '/' + this.form.id
                // data: { id: this.form.id }
                // headers: { token: this.$store.getters.token }
            })
                .then(() => {
                    that.$router.push(constants.ROUTES.TAGS);
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });

            return false;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .control-buttons {
        display: flex;
        justify-content: space-between;
    }

    .back-link {
        margin-top: 5px;
    }
</style>

<template>
    <div :style="{ width: previewWidth + 'px' }" :class="(managing && selected ? 'selected-bookmark-card' : '')" class="card bookmark-card" @click="selectBookmark(bookmark)">
        <div class="card-image">
            <figure class="image preview">
                <div v-if="managing === false">
                    <a :href="bookmark.address" target="_blank" @click="clickCounter(bookmark)">
                        <img v-lazy="previewPath" alt="">
                    </a>
                </div>
                <div v-if="managing === true">
                    <img v-lazy="previewPath" alt="">
                </div>
            </figure>
        </div>
        <div class="card-content custom-card-content">
            <div class="media">
                <div class="media-content">
                    <p class="title is-6">
                        <div v-if="managing === false">
                            <a :href="bookmark.address" target="_blank" @click="clickCounter(bookmark)">{{ bookmark.title }}</a>
                        </div>
                        <div v-if="managing === true">
                            <span>{{ bookmark.title }}</span>
                        </div>
                    </p>
                </div>
            </div>

            <div class="content">
                <!-- description can go here -->
                <div class="date-created">{{ formatCreationDate }}</div>
            </div>

            <div :style="{ width: previewWidth + 'px' }" class="card-actions">
                <router-link :to="shareBookmark" class="card-footer-item">Share</router-link>
                <router-link :to="editBookmark" class="card-footer-item">Edit</router-link>
                <a class="card-footer-item" @click="deleteBookmark">Delete</a>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import notifier from '@/assets/js/notifier';
import logger from '@/assets/js/logger';
import constants from '@/assets/js/constants';
import restClient from '@/assets/js/rest-client';

const unavailImg = require('@/assets/images/unavailable.png');

export default {
    name: 'BookmarkCard',
    props: ['bookmark', 'previewWidth', 'managing'],

    data() {
        return {
            selected: false
        };
    },

    computed: {
        formatCreationDate() {
            return moment(this.bookmark.dateCreated).format('YYYY-MM-DD HH:mm:ss');
        },

        previewPath() {
            if (this.bookmark.preview) {
                return window.bookmarkPivotConfig.previewsUrl + '/' + this.bookmark.preview;
            }

            return unavailImg;
        },

        shareBookmark() {
            return `${constants.ROUTES.SHARE}/${this.bookmark.id}`;
        },

        editBookmark() {
            return `${constants.ROUTES.BOOKMARKS}/${this.bookmark.id}`;
        }
    },

    created() {
        window.EventBus.$on(constants.EVENTS.CLEAR_BOOKMARK_SELECTION, () => {
            logger.debug(this.$options.name, `Clearing bookmark selection for bookmark ID ${this.bookmark.id}`);
            this.selected = false;
        });

        window.EventBus.$on(constants.EVENTS.SELECT_ALL_BOOKMARKS, () => {
            if (!this.selected) {
                logger.debug(this.$options.name, `Selecting bookmark ID ${this.bookmark.id} through SELECT_ALL_BOOKMARKS event`);
                this.selected = true;
                this.$emit(constants.EVENTS.BOOKMARK_SELECTED, this.bookmark.id);
            }
        });

        window.EventBus.$on(constants.EVENTS.UNSELECT_ALL_BOOKMARKS, () => {
            if (this.selected) {
                logger.debug(this.$options.name, `Unselecting bookmark ID ${this.bookmark.id} through UNSELECT_ALL_BOOKMARKS event`);
                this.selected = false;
                this.$emit(constants.EVENTS.BOOKMARK_UNSELECTED, this.bookmark.id);
            }
        });
    },

    beforeDestroy() {
        logger.debug(this.$options.name, 'Unsubscribing from events: CLEAR_BOOKMARK_SELECTION, SELECT_ALL_BOOKMARKS, UNSELECT_ALL_BOOKMARKS');
        window.EventBus.$off(constants.EVENTS.CLEAR_BOOKMARK_SELECTION);
        window.EventBus.$off(constants.EVENTS.SELECT_ALL_BOOKMARKS);
        window.EventBus.$off(constants.EVENTS.UNSELECT_ALL_BOOKMARKS);
    },

    methods: {
        clickCounter(bookmark) {
            restClient.countBookmarkClick(bookmark.id);
        },

        selectBookmark(bookmark) {
            if (!this.managing) {
                return;
            }

            logger.debug(this.$options.name, 'Bookmark ' + bookmark.id + ' (un)selected');

            this.selected = !this.selected;
            this.$emit((this.selected ? constants.EVENTS.BOOKMARK_SELECTED : constants.EVENTS.BOOKMARK_UNSELECTED), bookmark.id);
        },

        deleteBookmark() {
            const that = this;

            notifier.showDeleteConfirmation(null, this.bookmark.title)
                .then((confirmed) => {
                    logger.debug(this.$options.name, 'Delete confirmation result: ' + JSON.stringify(confirmed));

                    if (confirmed) {
                        logger.debug(this.$options.name, 'Deletion confirmed');
                        that.deleteBookmarkForReal();
                    } else {
                        logger.debug(this.$options.name, 'Deletion cancelled');
                    }
                })
                .catch((err) => {
                    logger.warn(this.$options.name, `Exception on delete confirmation: ${JSON.stringify(err)}`);
                });
        },

        deleteBookmarkForReal() {
            const that = this;

            axios({
                method: 'DELETE',
                url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARKS + `/${this.bookmark.id}`
            })
                .then(() => {
                    logger.info(this.$options.name, 'Bookmark deleted')
                    that.$emit(constants.EVENTS.REFRESH);
                    window.EventBus.$emit(constants.EVENTS.REFRESH_FAVOURITE_COLLECTIONS);
                })
                .catch((err) => {
                    logger.warn(this.$options.name, JSON.stringify(err));
                    notifier.showErrorMessage(null, err);
                });
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .bookmark-card {
        box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
        border-radius: 5px 5px 5px 5px;
        margin: 20px 10px 0 10px;
        background-color: whitesmoke;
    }

    .selected-bookmark-card {
        border: 2px solid #05a;
    }

    .preview {
        margin: 5px;
    }

    .preview img {
        border-radius: 5px 5px 5px 5px;
    }

    .image img {
        width: auto;
    }

    .title a {
        color: black;
    }

    .custom-card-content {
        margin-bottom: 27px;
        padding: 10px 10px 24px 10px;
    }

    .date-created {
        font-size: 0.8em;

        position: absolute;
        right: 5px;
        bottom: 42px;
    }

    .card-actions {
        border-top: 1px solid #dbdbdb;
        display: flex;
        align-items: stretch;
        justify-content: center;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        margin-top: 10px;
        height: 36px;
    }

    .card-footer-item {
        color: #0798E7;
        padding: 0;
    }
</style>

import axios from 'axios';
import constants from './constants';
import logger from './logger';

function countBookmarkClick(bookmarkId) {

    axios({
        method: 'POST',
        url: window.bookmarkPivotConfig.apiUrl + constants.ENDPOINTS.BOOKMARK_CLICKED,
        data: { bookmarkId: bookmarkId }
    })
        .catch((error) => {
            logger.warn('rest-client', 'Error: ' + JSON.stringify(error, null, 4));
        });
}

export default {
    countBookmarkClick
};

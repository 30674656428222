<template>
    <div style="margin: 0 7px;">
        <div class="ultimatedropdown">
            <ul>
                <li v-for="node1 in collections" :key="node1.id">
                    <a href="javascript:void(0)">{{ node1.name }}</a>

                    <ul v-if="(node1.children && node1.children.length > 0) || (node1.bookmarks && node1.bookmarks.length > 0)">
                        <li v-for="node2 in node1.children" :key="node2.id">
                            <a href="javascript:void(0)">{{ node2.name }}</a>

                            <ul v-if="(node2.children && node2.children.length > 0) || (node2.bookmarks && node2.bookmarks.length > 0)">
                                <li v-for="node3 in node2.children" :key="node3.id">
                                    <a href="javascript:void(0)">{{ node3.name }}</a>

                                    <ul v-if="(node3.children && node3.children.length > 0) || (node3.bookmarks && node3.bookmarks.length > 0)">
                                        <li v-for="node4 in node3.children" :key="node4.id">
                                            <a href="javascript:void(0)">{{ node4.name }}</a>

                                            <ul v-if="(node4.children && node4.children.length > 0) || (node4.bookmarks && node4.bookmarks.length > 0)">
                                                <li v-for="node5 in node4.children" :key="node5.id">
                                                    <a href="javascript:void(0)">{{ node5.name }}</a>

                                                    <ul v-if="(node5.children && node5.children.length > 0) || (node5.bookmarks && node5.bookmarks.length > 0)">
                                                        <li v-for="node6 in node5.children" :key="node6.id">
                                                            <a href="javascript:void(0)">{{ node6.name }}</a>

                                                            <ul v-if="node6.children && node6.children.length > 0 || (node6.bookmarks && node6.bookmarks.length > 0)">
                                                                <li v-for="node7 in node6.children" :key="node7.id">
                                                                    <a href="javascript:void(0)">{{ node7.name }}</a>

                                                                    <ul v-if="node7.bookmarks && node7.bookmarks.length > 0">
                                                                        <li v-for="b8 in node7.bookmarks" :key="b8.id">
                                                                            <a :href="b8.address" @click="clickCounter(b8)" target="_blank">{{ b8.title }}</a>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                                <li v-for="b7 in node6.bookmarks" :key="b7.id">
                                                                    <a :href="b7.address" @click="clickCounter(b7)" target="_blank">{{ b7.title }}</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li v-for="b6 in node5.bookmarks" :key="b6.id">
                                                            <a :href="b6.address" @click="clickCounter(b6)" target="_blank">{{ b6.title }}</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li v-for="b5 in node4.bookmarks" :key="b5.id">
                                                    <a :href="b5.address" @click="clickCounter(b5)" target="_blank">{{ b5.title }}</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li v-for="b4 in node3.bookmarks" :key="b4.id">
                                            <a :href="b4.address" @click="clickCounter(b4)" target="_blank">{{ b4.title }}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li v-for="b3 in node2.bookmarks" :key="b3.id">
                                    <a :href="b3.address" @click="clickCounter(b3)" target="_blank">{{ b3.title }}</a>
                                </li>
                            </ul>
                        </li>
                        <li v-for="b2 in node1.bookmarks" :key="b2.id">
                            <a :href="b2.address" @click="clickCounter(b2)" target="_blank">{{ b2.title }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import restClient from '@/assets/js/rest-client';

export default {
    name: 'CollectionMenu',

    props: ['collections'],

    methods: {
        clickCounter(bookmark) {
            restClient.countBookmarkClick(bookmark.id);
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    /*
    Ultimate Drop Down v1.0 - http://www.dynamicdrive.com/style/csslibrary/item/ultimate_css_drop_down_menu/
    Project URL: http://www.dynamicdrive.com/style/csslibrary/item/ultimate_drop_down/
    */

    .ultimatedropdown {
        /*font: normal 14px 'Bitter', sans-serif;*/
        /*background: #BC1818;*/
        position: relative;
        width: 100%;
    }


    /* Top Level Menu */
    .ultimatedropdown ul {
        /*z-index: 100;*/
        z-index: 2;
        margin: 0;
        padding: 0;
        position: relative;
        list-style: none;
    }

    /* Top level list items */
    .ultimatedropdown ul li {
        position: relative;
        display: inline;
        float: left;
    }

    /* Top level menu items link style */
    .ultimatedropdown ul li a, .ultimatedropdown ul li span {
        display: block;
        position: relative;
        /* background of menu items (default state) */
        background: white;
        color: black;
        padding: 5px 10px;
        /*border-right: 1px solid red;*/
        /*color: #2d2b2b;*/
        text-decoration: none;
    }


    .ultimatedropdown ul li a:link, .ultimatedropdown ul li a:visited {
        color: black;
    }

    /* Top level menu items link style on hover and when active */
    .ultimatedropdown ul li:hover > a {
        background: lightgrey;
    }

    /* Sub ULs style */
    .ultimatedropdown ul li ul {
        position: absolute;
        left: -5000px;
        top: auto;
        opacity: 0;
        width: 250px;
        visibility: hidden;
        box-shadow: 2px 2px 5px gray;
        -webkit-transition: opacity .3s, visibility 0s .3s, left 0s .3s;
        transition: opacity .3s, visibility 0s .3s, left 0s .3s;
    }

    /* First Sub Levels UL style on hover */
    .ultimatedropdown ul li:hover > ul {
        visibility: visible;
        left: 0;
        opacity: 1;
        -webkit-transition: opacity .5s;
        transition: opacity .5s;
    }

    /* Sub level Menu list items (alters style from Top level List Items) */
    .ultimatedropdown ul li ul li {
        display: list-item;
        float: none;
    }

    /* 2nd and beyond Sub Levels vertical offset after 1st level sub menu */
    .ultimatedropdown ul li ul li ul {
        top: 0;
        left: 100%;
    }

    /* Sub Levels link style on hover and when active */
    .ultimatedropdown ul ul li:hover > a {
        background: #e3e2e2;
    }

    /* Sub Levels UL style on hover */
    .ultimatedropdown ul ul li:hover > ul {
        left: 100%;
    }

    /* Sub level menu links style */
    .ultimatedropdown ul li ul li a {
        font: normal 14px 'Bitter', sans-serif;
        padding: 10px;
        margin: 0;
        background: lightgrey;
        border-right: none;
        border-top-width: 0;
        border-bottom: 1px solid lightgray;
    }

    /* LIs with a sub UL style */
    .ultimatedropdown ul li > a {
        /* add padding to accommodate arrow inside LIs */
        padding-right: 25px;
    }

    /* LIs with NO sub UL style */
    .ultimatedropdown ul li > a:only-child {
        /* undo padding for non submenu LIs */
        padding-right: 10px;
    }

    /* LIs with a sub UL pseudo class */
    .ultimatedropdown ul li > a:after {
        /* add arrow inside LIs */
        content: "";
        position: absolute;
        height: 0;
        width: 0;
        border: 5px solid transparent;
        border-top-color: black;
        top: 50%;
        right: 8px;
    }

    /* LIs with NO sub UL pseudo class */
    .ultimatedropdown ul li > a:only-child:after {
        /* undo arrow for non submenu LIs */
        display: none;
    }

    /* Sub ULs' LIs with a sub UL pseudo class */
    .ultimatedropdown ul li ul li > a:after {
        /* change arrow to right arrow inside sub UL LIs */
        border-top-color: transparent;
        border-left-color: black;
        top: 40%;
        right: 8px;
    }


    /* ####### responsive layout CSS ####### */

    @media (max-width: 800px) {
        /* FIRST breaking point
                Last two top menu items' sub ULs should drop to the left (instead of right)
                Change 2 to a different number to exclude/include more top menu items based on menu and max-width setting above
            */

        .ultimatedropdown ul li:nth-last-of-type(-n+2) ul li:hover > ul {
            left: -100%;
        }
    }

    @media (max-width: 500px) {
        /* SECOND breaking point
                For mobile and screen browser windows
                Get Sub ULs to expand entire width of document and drop down
                Changes menu reveal type from "visibility" to "display" to overcome bug. See comments below
            */

        .ultimatedropdown ul li {
            position: static;
        }

        .ultimatedropdown ul li ul {
            width: 100%;
            border-top: 2px solid #eee;
            /* change menu reveal type from "visibility" to "display". Former seems to cause browser to jump to top of page sometimes when menu header is tapped on */
            display: none;
        }

        .ultimatedropdown ul li:hover > ul {
            display: block;
            left: 0 !important;
            top: auto;
        }

        .ultimatedropdown ul ul li:hover > ul {
            left: 0 !important;
            top: auto;
        }
    }

</style>

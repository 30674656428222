<template>
    <div>
        <page-title>Import Your Bookmarks</page-title>

        <div class="tile is-ancestor">

            <div class="tile is-vertical">

                <div class="tile">
                    <div class="tile is-parent">
                        <article class="tile is-child box">
                            <p class="title">Let's Get Started</p>

                            <p style="margin-bottom: 10px;">
                                Importing your bookmarks is easy. We've provided a step-by-step guide to help you through the process.
                                First, move on to step 1 and then work your way through to step 4.
                            </p>

                            <p style="margin-bottom: 10px;">
                                Please note: Only <b>Firefox</b> and <b>Chrome</b> bookmarks are supported at the moment. More coming soon.
                            </p>
                        </article>
                    </div>

                    <div class="tile is-parent">
                        <article class="tile is-child box">
                            <p class="title">1. Select File</p>
                            <p class="subtitle">Select the bookmark file you wish to import</p>

                            <div class="file has-name is-centered" style="margin-top: 40px;">
                                <label class="file-label">
                                    <input class="file-input" type="file" name="bookmarks-file" @change="onFileChange">
                                    <span class="file-cta">
                                        <span class="file-icon">
                                            <i class="fas fa-upload"></i>
                                        </span>
                                        <span class="file-label">
                                            Select bookmark file...
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </article>
                    </div>

                    <div class="tile is-parent">
                        <article class="tile is-child box">
                            <p class="title">2. Options</p>
                            <p class="subtitle">Fine tune the import process</p>

                            <form @submit.prevent>
                                <div class="field">
                                    <div class="control">
                                        <label class="checkbox">
                                            <input v-model="ignoreDuplicates" type="checkbox"> <span class="custom-label">Ignore duplicates</span>
                                        </label>
                                    </div>
                                    <p class="help">
                                        When set (ticked), we'll import the first occurrence of a bookmark and ignore subsequent duplicates.
                                    </p>
                                </div>

                                <div class="field">
                                    <div class="control">
                                        <label class="checkbox">
                                            <input v-model="keepHierarchy" type="checkbox"> <span class="custom-label">Keep group hierarchy</span>
                                        </label>
                                    </div>
                                    <p class="help">
                                        When set (ticked), we'll keep the group structure as you see below.
                                        Otherwise, we'll only keep the group closest to the bookmark.
                                    </p>
                                </div>

                                <div class="field">
                                    <div class="control">
                                        <label class="label">Tag imported bookmarks with</label>
                                        <multi-select
                                            v-model="selectedTags"
                                            :options="tags"
                                            :multiple="true"
                                            :searchable="true"
                                            :loading="isLoadingTags"
                                            :internal-search="false"
                                            :clear-on-select="true"
                                            :close-on-select="true"
                                            :options-limit="300"
                                            :limit="8"
                                            :max-height="600"
                                            :show-no-results="false"
                                            :taggable="true"
                                            label="name"
                                            track-by="id"
                                            placeholder="Search or add a tag"
                                            open-direction="bottom"
                                            @search-change="asyncFindTags"
                                            @tag="addTag"/>
                                    </div>
                                </div>
                            </form>
                        </article>
                    </div>
                </div>

                <div class="tile is-parent">
                    <div class="tile is-child box">
                        <p class="title">3. Bookmarks</p>
                        <p class="subtitle">Select the bookmarks to import</p>

                        <ul>
                            <tree-node v-if="bookmarkData"
                                       :model="bookmarkData"
                                       :show-check-box="true"
                                       :parent-open-class="'fa fa-chevron-down'"
                                       :parent-close-class="'fa fa-chevron-right'"
                                       :item-class="'fa fa-bookmark-o'"
                                       class="item"
                                       @ticked="ticked">
                            </tree-node>
                        </ul>
                    </div>
                </div>

                <div class="tile is-parent">
                    <div class="tile is-child box">
                        <p class="title">4. Import</p>
                        <p>At this point you are ready to import your bookmarks. Let's go!</p>

                        <button
                            id="import-button"
                            :class="isImporting ? 'is-loading' : ''"
                            :disabled="!bookmarkData"
                            class="button is-info"
                            type="button"
                            style="margin-top: 20px;"
                            @click="importBookmarks">
                            Import Selected Bookmarks
                        </button>
                    </div>
                </div>

                <div v-if="errors.length > 0" class="tile is-parent">
                    <div class="tile is-child box">
                        <p class="title">5. Review Import Problems</p>

                        <p class="subtitle">
                            Some problems were encountered while importing your bookmarks. Here are the details:
                        </p>

                        <ul class="error-list">
                            <li v-for="(error, index) in errors" v-bind:key="index">{{ error.title }} - <span style="color: red;">{{ error.messages[0] }}</span></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>

import axios from 'axios';
import MultiSelect from 'vue-multiselect';
import notifier from '@/assets/js/notifier';
import PageTitle from '@/components/PageTitle';
import TreeNode from '@/components/TreeNode';
import constants from '@/assets/js/constants';

export default {
    name: 'ImportPage',

    components: {
        MultiSelect,
        PageTitle,
        TreeNode
    },

    data() {
        return {
            ignoreDuplicates: false,
            keepHierarchy: true,
            bookmarkData: null,
            tags: [],
            selectedTags: [],
            isLoadingTags: false,
            errors: [],
            isImporting: false
        };
    },

    created() {
        this.loadData();
    },

    mounted() {
        window.EventBus.$emit('pathChanged', constants.ROUTES.IMPORT);
    },

    methods: {
        async onFileChange(event) {
            const that = this;
            const files = event.target.files || event.dataTransfer.files;

            if (!files.length) {
                console.log('No file selected');
                return;
            }

            that.bookmarkData = null;
            that.selectedFileName = files[0];

            const content = await this.readFile(files[0]);

            axios({
                method: 'POST',
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.IMPORT_FIREFOX,
                headers: {
                    token: this.$store.getters.token,
                    'Content-Type': 'text/plain'
                },
                data: content
            })
                .then((response) => {
                    if (response && response.data) {
                        that.bookmarkData = response.data;
                    }
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });
        },

        readFile(file) {
            return new Promise((resolve) => {
                const reader = new FileReader();

                reader.onload = (e) => {
                    resolve(e.target.result);
                };

                reader.readAsText(file);
            });
        },

        ticked(element) {
            this.setChildren(element);
        },

        setChildren(parent) {
            const that = this;
            if (parent.children && parent.children.length > 0) {
                parent.children.forEach((child) => {
                    child.selected = parent.selected;
                    console.log('Set child selection to ' + child.selected);

                    if (child.children && child.children.length > 0) {
                        that.setChildren(child);
                    }
                });
            }
        },

        loadData() {
            const that = this;

            axios({
                method: 'GET',
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.TAGS_FOR_USER.replace('{userId}', this.$store.getters.user.id),
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    that.tags = response.data;
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                });
        },

        asyncFindTags(query) {
            const that = this;
            this.isLoadingTags = true;

            axios({
                method: 'GET',
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.TAGS_FOR_USER.replace('{userId}', this.$store.getters.user.id) + `?search=${query}`,
                headers: { token: this.$store.getters.token }
            })
                .then((response) => {
                    that.tags = response.data;
                    that.isLoadingTags = false;
                })
                .catch((err) => {
                    notifier.showErrorMessage(null, err);
                    that.isLoadingTags = false;
                });
        },

        addTag(newTag) {
            const tag = {
                name: newTag,
                id: null
            };
            this.tags.push(tag);
            this.selectedTags.push(tag);
        },

        importBookmarks() {
            console.log('Importing selected bookmarks...');
            const that = this;

            const payload = {
                ignoreDuplicates: this.ignoreDuplicates,
                keepHierarchy: this.keepHierarchy,
                tags: this.selectedTags,
                bookmarkData: this.bookmarkData
            };

            that.errors = [];
            that.isImporting = true;

            axios({
                method: 'POST',
                url: process.env.VUE_APP_API_BASE + constants.ENDPOINTS.IMPORT_BOOKMARKS,
                headers: {
                    token: this.$store.getters.token
                },
                data: payload
            })
                .then((response) => {
                    that.isImporting = false;

                    if (response.data.success) {
                        notifier.showSuccessMessage(null, 'Import successful');
                        that.bookmarkData = null;
                    } else {
                        that.errors = response.data.errors;
                        notifier.showErrorMessage(null, 'Some problems were encountered while importing your bookmarks. Please see section 5 below for more details.');

                        // Scroll to the bottom of the page after enough time for Vue.js
                        // to show the content
                        setTimeout(() => {
                            window.scrollTo(0, document.body.scrollHeight);
                        }, 400);
                    }
                })
                .catch((response) => {
                    notifier.showErrorMessage(null, response);
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .custom-label {
        font-weight: bold;
    }

    .error-list {
        list-style-type: disc;
    }

    .error-list li {
        margin-left: 15px;
    }

</style>

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        authenticated: false,
        user: null,
        signUp: null,
        token: null,
        favouriteCollections: [],
        userSettings: {},
        subscription: {},
        permissions: [],
        bookmarkTemplates: []
    },
    getters: {
        authenticated(state) {
            return state.authenticated;
        },
        user(state) {
            return state.user;
        },
        signUp(state) {
            return state.signUp;
        },
        token(state) {
            return state.token;
        },
        favouriteCollections(state) {
            return state.favouriteCollections;
        },
        userSettings(state) {
            return state.userSettings;
        },
        subscription(state) {
            return state.subscription;
        },
        permissions(state) {
            return state.permissions;
        },
        bookmarkTemplates(state) {
            return state.bookmarkTemplates;
        }
    },
    mutations: {
        authenticated(state, loggedIn) {
            state.authenticated = loggedIn;
        },
        user(state, user) {
            state.user = user;
            state.authenticated = (user && user.id);
        },
        signUp(state, signUp) {
            state.signUp = signUp;
        },
        token(state, token) {
            state.token = token;
        },
        favouriteCollections(state, favCollections) {
            state.favouriteCollections = favCollections;
        },
        userSettings(state, newSettings) {
            state.userSettings = newSettings;
            window.localStorage.setItem('userSettings', JSON.stringify(newSettings));
        },
        subscription(state, sub) {
            state.subscription = sub;
        },
        permissions(state, perms) {
            state.permissions = perms;
        },
        bookmarkTemplates(state, templates) {
            state.bookmarkTemplates = templates;
        }
    }
});

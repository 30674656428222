<template>
    <div class="top-part">
        <div class="title-wrapper">
            <!-- TODO: Bundle the image -->
            <a :href="brandLink" class="brand"><img :src="logo" alt="Bookmark Pivot"></a>

            <div v-if="$store.getters.authenticated" class="profile-wrapper">

                <div class="field has-addons profile-wrapper-dropdowns" style="margin-right: 10px;">
                    <div class="control">
                        <button class="button" type="button" style="background-color: #125d92; color: white;" @click="applyDefaultBookmarkTemplate">
                            <span class="icon is-small">
                                <i class="fas fa-plus"></i>
                            </span>
                            <span>Add Bookmark</span>
                        </button>
                    </div>
                    <div v-if="hasBookmarkTemplates" id="add-bookmark-dropdown" class="control dropdown is-large" @keyup.esc="hideDropdown('add-bookmark-dropdown')">
                        <div class="dropdown-trigger" @click="toggleDropdown('add-bookmark-dropdown')" @keyup.esc="hideDropdown('add-bookmark-dropdown')">
                            <button class="button" aria-haspopup="true" aria-controls="bookmark-template-dropdown-menu"
                                    style="background-color: #125d92; color: white;">
                                <span style="margin-top: -4px;">Bookmark Templates</span>
                                <span style="margin-top: -4px;" class="icon is-small">
                                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                                </span>
                            </button>
                        </div>
                        <div id="bookmark-template-dropdown-menu" class="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a v-for="template in bookmarkTemplates" v-bind:key="template.id" class="dropdown-item" @click.prevent="applyBookmarkTemplate(template.id)">{{ template.name }}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="profile-dropdown" class="dropdown is-right is-large profile-wrapper-dropdowns">
                    <div class="dropdown-trigger" @click="toggleDropdown('profile-dropdown')" @keyup.esc="hideDropdown('profile-dropdown')">
                        <button class="button" aria-haspopup="true" aria-controls="profile-dropdown-menu">
                            <img :src="emailHash" alt="" style="margin: -4px 7px 0 0; border-radius: 45px;">
                            <span style="margin-top: -4px;">{{ preferredName }}</span>
                            <span style="margin-top: -4px;" class="icon is-small">
                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <div id="profile-dropdown-menu" class="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <a class="dropdown-item" @click="logout"><i class="fas fa-sign-out-alt"></i> Sign Out</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="$store.getters.authenticated" class="content-wrapper">
            <div class="collection-wrapper">
                <collection-menu :collections="favouriteCollections"></collection-menu>
                <div v-if="favouriteCollections.length === 0" style="color: #b3b2b2; font-size: 0.8em; font-style: italic;">Flag a collection as a favorite to have it appear here</div>
            </div>
            <search-box id="search-box"/>
        </div>
    </div>
</template>

<script>

import CollectionMenu from '@/components/CollectionMenu';
import constants from '@/assets/js/constants';
import SearchBox from '@/components/search/SearchBox';

const logoImg = require('@/assets/images/logo-and-name.png');

export default {
    name: 'TopPart',

    components: {
        CollectionMenu,
        SearchBox
    },

    data() {
        return {
            msg: '',
            collectionSource: [],
            search: ''
        };
    },

    computed: {
        brandLink() {
            return window.bookmarkPivotConfig.homeUrl;
        },
        logo() {
            return logoImg;
        },
        preferredName() {
            return (this.$store.getters.user ? this.$store.getters.user.preferredName : '');
        },
        emailHash() {
            const hash = (this.$store.getters.user && this.$store.getters.user.emailHash ? this.$store.getters.user.emailHash : '');
            return 'https://www.gravatar.com/avatar/' + hash + '?s=32&d=mm';
        },
        favouriteCollections() {
            return (this.$store.getters.favouriteCollections ? this.$store.getters.favouriteCollections : []);
        },
        hasBookmarkTemplates() {
            return this.$store.getters.bookmarkTemplates && this.$store.getters.bookmarkTemplates.length > 0;
        },
        bookmarkTemplates() {
            return (this.$store.getters.bookmarkTemplates ? this.$store.getters.bookmarkTemplates : []);
        }
    },

    methods: {
        toggleDropdown(name) {
            const element = document.getElementById(name);
            if (element) {
                element.classList.toggle('is-active');
            }
        },
        hideDropdown(name) {
            const element = document.getElementById(name);
            if (element) {
                element.classList.remove('is-active');
            }
        },
        applyDefaultBookmarkTemplate() {
            this.hideDropdown('add-bookmark-dropdown');
            this.$router.push('/bookmarks/new');
        },
        applyBookmarkTemplate(id) {
            this.hideDropdown('add-bookmark-dropdown');
            this.$router.push({ path: `${constants.ROUTES.EXPRESS_BOOKMARK}/${id}` });
        },
        logout() {
            this.hideDropdown('profile-dropdown');
            this.$router.push(constants.ROUTES.LOGOUT);
        }

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .top-part {
        box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
    }

    .title-wrapper {
        display: flex;
        margin-right: 20px;
        justify-content: space-between;
    }

    .brand {
        color: #125d92;
        font-size: 36px;
        font-weight: bold;
        height: 44px;
        margin: 10px 0 0 5px;
        /* margin: 10px 10px 0 10px; */
        /* min-width: 190px; */
    }

    .brand > img {
        width: 300px;
    }

    .profile-wrapper {
        display: flex;
        flex-flow: row wrap;
        font-size: 16px;
        margin: 14px 0 0 10px;
    }

    .collection-wrapper {
        display: flex;
        flex-flow: row wrap;
        margin: 7px 0 0 5px;
    }

    .content-wrapper {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;
        margin-top: 5px;
        padding-bottom: 7px;
    }

    .field:not(:last-child) {
        margin-bottom: 0;
    }

@media (max-width: 700px) {
        .top-part {
            box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
        }

        .title-wrapper {
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;
            padding-top: 10px;
        }

        .brand {
            color: #125d92;
            font-size: 24px;
            font-weight: bold;
            margin: 0 0 0 5px;
        }

        .profile-wrapper {
            display: flex;
            flex-flow: row wrap;
            font-size: 14px;
            margin-top: 3px;
        }

        .profile-wrapper-dropdowns {
            margin-top: 0;
        }

        .collection-wrapper {
            display: flex;
            flex-flow: row wrap;
        }

        .content-wrapper {
            display: flex;
            flex-flow: row wrap;
        }
    }
</style>

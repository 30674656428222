<template>
    <footer v-if="$store.getters.authenticated">
        <div class="footer-container">
            <div class="left-bit">
                <!--<span class="right-padded" style="color: #f12628; font-family: 'American Typewriter', 'Avenir', sans-serif, Helvetica, Arial;">Exclusive Beta Edition</span>-->
                <a :href="updatesAddress" class="right-padded">{{ version }}</a>
                &nbsp;
                <a @click="showShortcutModal"><i class="fas fa-keyboard"></i></a>
            </div>

            <div class="right-bit">
                <a class="left-padded" @click="showFeedbackModal"><i class="far fa-envelope"></i> Feedback</a>
            </div>
        </div>
    </footer>
</template>

<script>
import constants from '@/assets/js/constants';

export default {
    name: 'BottomPart',

    data() {
        return {
            version: constants.VERSION,
            updatesAddress: window.bookmarkPivotConfig.updatesUrl
        };
    },

    methods: {
        showFeedbackModal() {
            this.$emit('showFeedbackModal');
        },

        showShortcutModal() {
            window.EventBus.$emit('showShortcutModal');
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    a {
        color: black;
    }

    a:hover {
        color: #3273dc;
    }

    footer {
        padding: 15px;
        background-color: #efefef;
    }

    .footer-container {
        display: flex;
        justify-content: space-between;
    }

    .right-padded {
        margin-right: 6px;
    }

    .left-padded {
        margin-left: 6px;
    }

    @media (max-width: 700px) {
        footer {
            padding: 7px;
            background-color: #efefef;
        }
    }

</style>

class ErrorWrapper {
    constructor(theErrors) {
        this.errors = theErrors || {};
    }

    has(field) {
        return Object.prototype.hasOwnProperty.call(this.errors, field);
    }

    any() {
        return Object.keys(this.errors).length > 0;
    }

    get(field) {
        if (this.errors[field]) {
            return this.errors[field];
        }

        return null;
    }

    clear(field) {
        this.errors[field] = null;
    }

    setErrors(errors) {
        this.errors = errors;
    }
}

class Form {
    constructor(data) {
        this.originalData = data;
        this.errorWrapper = new ErrorWrapper();

        for (const key in data) {
            this[key] = data[key];
        }
    }

    getData() {
        const keysToExclude = ['originalData', 'errorWrapper'];
        const returnData = {};

        for (const key in this) {
            if (!keysToExclude.includes(key)) {
                returnData[key] = this[key];
            }
        }

        return returnData;
    }

    recordErrors(errors) {
        this.errorWrapper.setErrors(errors);
    }

    reset() {
        Object.keys(this.originalData).forEach((key) => {
            this[key] = null;
        });
    }
}

export default Form;
